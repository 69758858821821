import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { THEME_MODES } from 'src/theming/theme-modes';

export const useStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.mode;
    return {
        button: {
            width: '100%',
            color: theme.palette.secondary.contrastText,
            '& .MuiButton-endIcon': {
                display: 'flex',
                alignItems: 'center',
            },
        },
        buttonText: {
            fontSize: '1.4rem',
        },
        icon: {
            display: 'flex',
            alignItems: 'center',
            width: 16,
            height: 16,
            color: isDarkMode ? theme.palette.secondary.contrastText : theme.palette.secondary.main,
            opacity: 0.6,
        },
        checkedIcon: {
            width: 16,
            height: 16,
            color: isDarkMode ? theme.palette.secondary.light : theme.palette.secondary.dark,
            opacity: 1,
        },
        itemsContainer: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
        filterIconButton: {
            marginRight: theme.spacing(1),
        },
        numberBadge: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 22,
            height: 22,
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.main,
            fontSize: '1.4rem!important',
            color: theme.palette.primary.contrastText,
            marginRight: theme.spacing(1),
        },
    };
});

export const useFormControlLabelStyle = makeStyles(() => {
    return {
        label: {
            fontSize: '1.4rem',
        },
    };
});
