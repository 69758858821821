import React, { ReactElement } from 'react';

import { AuthLayout } from 'src/components/layouts';
import { LoginForm } from 'src/components/forms';

export const LoginPage = (): ReactElement => {
    return (
        <AuthLayout>
            <LoginForm />
        </AuthLayout>
    );
};
