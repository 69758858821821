import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useSelectFieldStyles = makeStyles((theme: Theme) => {
    return {
        subTitle: {
            '& .MuiTypography-root': {
                paddingLeft: theme.spacing(2),
                fontSize: '1.2rem!important',
            },
        },
    };
});

export const useSelectStyles = makeStyles((theme: Theme) => {
    return {
        select: {
            width: '100%',
            paddingTop: 7,
            paddingBottom: 7,
            fontSize: '1.8rem',
        },
        icon: {
            width: 20,
            height: 20,
            top: 'calc(50% - 10px)',
            '&.Mui-disabled': {
                display: 'none',
            },
        },
        disabled: {
            color: theme.palette.primary.contrastText,
        },
    };
});

export const useMenuItemStyles = makeStyles(() => {
    return {
        root: {
            display: 'flex',

            alignItems: 'flex-start',
            '& .MuiTypography-root': {
                fontSize: '1.4rem',
            },
        },
    };
});

export const useCheckBoxStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: 20,
            height: 20,
            marginRight: theme.spacing(2),
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
            },
        },
        checked: {
            color: `${theme.palette.secondary.main}!important`,
        },
    };
});
