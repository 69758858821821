import React, { SyntheticEvent } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { useToggleButtonsGroupStyle, useToggleButtonStyle } from './toggle-buttons-style';

export interface ToggleButtonItem {
    title: string;
    value: string;
    style?: CSSProperties;
}

interface ToggleButtonsProps {
    buttonsList: ToggleButtonItem[];
    value: string;
    handleChange: (event: SyntheticEvent, newFilter: string) => void;
    injectedClassName?: string;
}

export const ToggleButtons = (props: ToggleButtonsProps): JSX.Element => {
    const toggleButtonsGroupClasses = useToggleButtonsGroupStyle();
    const toggleButtonClasses = useToggleButtonStyle();

    const { value, handleChange, buttonsList, injectedClassName } = props;

    return (
        <ToggleButtonGroup
            exclusive
            value={value}
            onChange={handleChange}
            classes={toggleButtonsGroupClasses}
            className={injectedClassName}
        >
            {buttonsList.map((button) => {
                return (
                    <ToggleButton
                        key={button.value}
                        value={button.value}
                        classes={toggleButtonClasses}
                        style={button.style}
                    >
                        {button.title}
                    </ToggleButton>
                );
            })}
        </ToggleButtonGroup>
    );
};
