export enum ChangeProcessResult {
    Unknown,
    Open,
    InProgress,
    ReadyToProcess,
    NoProcessing,
}

export interface ChangesItem {
    id: string;
    insuranceConfirmationNumber: string;
    internalInsuranceNotes: string;
    licenseNumber: string;
    owner: string;
    agencyNumber: string;
    processor: string;
}
