import { TFunction } from 'react-i18next';
import { CustomerData } from 'src/shared/types/data-preparing';
import { OverflowTooltip } from '../overflow-tooltip';
import { ProcessTableValue } from '../process-dialog-table';
import { EditableDataValueCell } from './editable-data-value-cell';
import { StringObjectPropertyIndex } from './stringObjectPropertyIndex';

interface KeyMappingParams {
    t: TFunction<'processing', undefined>;
    dataKeys: DataKeyProperties[];
    amsData: StringObjectPropertyIndex | undefined;
    frameworkData: StringObjectPropertyIndex | undefined;
    localData: StringObjectPropertyIndex | undefined;
    onValueChanging(
        dataKey: DataKeyProperties,
        data: StringObjectPropertyIndex,
        newValue: string
    ): void;
}

export interface DataKeyProperties {
    key: string;
    dataType: string;
    readonly: boolean;
}

export function mapKeysToValues({
    t,
    dataKeys,
    amsData,
    frameworkData,
    localData,
    onValueChanging,
}: KeyMappingParams): ProcessTableValue[] {
    return dataKeys.map((dataKey) => {
        const description = t(dataKey.key);
        let amsValue: string;
        let frameworkValue: string;

        if (!amsData) {
            amsValue = '';
        } else {
            if (dataKey.dataType === 'customer') {
                amsValue = (amsData[dataKey.key] as CustomerData)?.customer1 ?? '';
            } else if (dataKey.dataType === 'date') {
                const asDate: Date = new Date(amsData[dataKey.key]);
                amsValue = amsData[dataKey.key] ? asDate.toLocaleDateString('de-DE') : '';
            } else {
                amsValue = amsData[dataKey.key] ?? '';
            }
        }
        if (!frameworkData) {
            frameworkValue = '';
        } else {
            if (dataKey.dataType === 'customer') {
                frameworkValue = (frameworkData[dataKey.key] as CustomerData)?.customer1 ?? '';
            } else if (dataKey.dataType === 'date') {
                const asDate: Date = new Date(frameworkData[dataKey.key]);
                frameworkValue = frameworkData[dataKey.key]
                    ? asDate.toLocaleDateString('de-DE')
                    : '';
            } else {
                frameworkValue = frameworkData[dataKey.key] ?? '';
            }
        }

        return {
            description: (
                <OverflowTooltip label={'description'} dataForTooltip={description}>
                    <span
                        style={{
                            display: 'inline-block',
                            maxWidth: '100%',
                            verticalAlign: 'middle',
                        }}
                    >
                        {description}
                    </span>
                </OverflowTooltip>
            ),
            amsValue: (
                <OverflowTooltip label={'amsValue'} dataForTooltip={amsValue}>
                    <span
                        style={{
                            display: 'inline-block',
                            maxWidth: '100%',
                            verticalAlign: 'middle',
                        }}
                    >
                        {amsValue}
                    </span>
                </OverflowTooltip>
            ),
            frameworkValue: (
                <OverflowTooltip label={'frameworkValue'} dataForTooltip={frameworkValue}>
                    <span
                        style={{
                            display: 'inline-block',
                            maxWidth: '100%',
                            verticalAlign: 'middle',
                        }}
                    >
                        {frameworkValue}
                    </span>
                </OverflowTooltip>
            ),
            evbValue: (
                <OverflowTooltip label={'evbValue'} dataForTooltip={frameworkValue}>
                    <span
                        style={{
                            display: 'inline-block',
                            maxWidth: '100%',
                            verticalAlign: 'middle',
                        }}
                    >
                        {frameworkValue}
                    </span>
                </OverflowTooltip>
            ),
            localValue: (
                <EditableDataValueCell
                    data={localData}
                    dataKey={dataKey}
                    onValueChanging={onValueChanging}
                />
            ),
        };
    });
}

export function getContractDataKeys(dtoIsReadonly: boolean): DataKeyProperties[] {
    return [
        {
            key: 'wkz',
            readonly: true,
            dataType: 'string',
        },
        {
            key: 'startOfContract',
            readonly: dtoIsReadonly || false,
            dataType: 'date',
        },
        {
            key: 'endOfContract',
            readonly: dtoIsReadonly || false,
            dataType: 'date',
        },
        {
            key: 'customer1',
            readonly: dtoIsReadonly || false,
            dataType: 'customer',
        },
    ];
}

export function getCustomerDataKeys(dtoIsReadonly: boolean): DataKeyProperties[] {
    return [
        {
            key: 'customer1',
            readonly: true,
            dataType: 'string',
        },
        {
            key: 'customer2',
            readonly: true,
            dataType: 'string',
        },
        {
            key: 'customer3',
            readonly: true,
            dataType: 'string',
        },
        {
            key: 'street',
            readonly: dtoIsReadonly || false,
            dataType: 'string',
        },
        {
            key: 'postcode',
            readonly: dtoIsReadonly || false,
            dataType: 'number',
        },
        {
            key: 'place',
            readonly: dtoIsReadonly || false,
            dataType: 'string',
        },
    ];
}

export function getVehicleDataKeys(dtoIsReadonly: boolean): DataKeyProperties[] {
    return [
        {
            key: 'licenseNumber',
            readonly: dtoIsReadonly || false,
            dataType: 'string',
        },
        {
            key: 'fin',
            readonly: dtoIsReadonly || false,
            dataType: 'string',
        },
        {
            key: 'ratedCapacity',
            readonly: dtoIsReadonly || false,
            dataType: 'number',
        },
        {
            key: 'vehicleUsage',
            readonly: dtoIsReadonly || false,
            dataType: 'string',
        },
    ];
}
