import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useInputStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '100%',
            maxWidth: 382,
            height: 40,
            backgroundColor: theme.palette.primary.dark,
            borderColor: theme.palette.primary.main,
            marginBottom: 16,
            marginTop: 8,
            '& .MuiSvgIcon-root': {
                width: 25,
                height: 25,
            },
        },
        input: {
            '&::placeholder': {
                fontSize: '1.6rem',
            },
        },
        focused: {
            '& fieldSet': {
                borderColor: `${theme.palette.primary.main}!important`,
            },
        },
    };
});
