export enum AppRoutes {
    ROOT = '',
    LOGIN = '/login',
    FORGOT_PASSWORD = '/forgot-password',
    SIGNING_OFF = '/signing-off',
    CHANGE = '/change',
    NEW_REGISTRATION = '/new-registration',
    GDV_MESSAGES = '/gdv-messages',
    CREATE_PASSWORD = '/create-password',
    USER_MANAGEMENT = '/user-management',
}
