import { ReactElement, ReactNode } from 'react';
import TreeItem from '@mui/lab/TreeItem';
import { Typography } from '@mui/material';
import { XmlElement } from './xml-element';
import { defaultGuid } from '../../../lib/utility/guid';

interface XmlLabelProps {
    element: XmlElement;
}

const XmlNoValueLabel = ({ element }: XmlLabelProps): ReactNode => {
    return <Typography variant='body1'>{element.name}</Typography>;
};

const XmlValueLabel = ({ element }: XmlLabelProps): ReactNode => {
    return (
        <div>
            <Typography variant='body1'>{element.name}</Typography>
            <Typography variant='body2' marginLeft='24px' color='secondary'>
                {element.value}
            </Typography>
        </div>
    );
};

interface XmlTreeNodeProps {
    element: XmlElement;
    onNodeSelect?(id: string): void | null;
}

export function XmlTreeNode({ element, onNodeSelect }: XmlTreeNodeProps): ReactElement {
    const buildNode = (): JSX.Element => {
        if (element.value !== null) {
            return (
                <TreeItem
                    nodeId={element.id}
                    key={element.id}
                    label={XmlValueLabel({ element: element })}
                    onClick={() => (onNodeSelect ? onNodeSelect(element.id) : {})}
                />
            );
        } else {
            return (
                <TreeItem
                    nodeId={element.id}
                    key={element.id}
                    label={XmlNoValueLabel({ element: element })}
                    onClick={() => (onNodeSelect ? onNodeSelect(defaultGuid()) : {})}
                >
                    {buildChildNodes()}
                </TreeItem>
            );
        }
    };

    const buildChildNodes = (): JSX.Element[] => {
        const children: JSX.Element[] = [];
        for (let i = 0; i < element.children.length; i++) {
            const child = element.children.at(i);
            if (child) {
                const node = (
                    <XmlTreeNode key={child.id} element={child} onNodeSelect={onNodeSelect} />
                );
                children.push(node);
            }
        }
        return children;
    };

    return buildNode();
}
