import React, { BaseSyntheticEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { OutlinedInput, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { useInputStyles } from './SearchFieldStyles';

interface SearchFieldProps {
    value?: string;
    name?: string;
    onChange?: (name: string, value: string) => void;
    languageNamespaces?: string[];
    searchPlaceholder?: string;
}

export const SearchField = (props: SearchFieldProps): ReactElement => {
    const searchClasses = useInputStyles();

    const { value, name, onChange, languageNamespaces, searchPlaceholder, ...rest } = props;

    const { t } = useTranslation([
        'common',
        ...(Array.isArray(languageNamespaces) ? languageNamespaces : []),
    ]);

    const handleChange = (e: BaseSyntheticEvent): void => {
        if (name && onChange) {
            onChange(name, e.target.value);
        }
    };

    return (
        <OutlinedInput
            name='searchValue'
            autoComplete='off'
            placeholder={searchPlaceholder ? t(searchPlaceholder) : t('search')}
            classes={searchClasses}
            value={value}
            onChange={handleChange}
            startAdornment={
                <InputAdornment position='start'>
                    <SearchIcon />
                </InputAdornment>
            }
            {...rest}
        />
    );
};

SearchField.defaultProps = {
    searchName: '',
    searchField: '',
};
