import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { THEME_MODES } from 'src/theming/theme-modes';

export const useStyle = makeStyles(
    () => {
        return {
            label: {
                fontSize: '1.6rem',
            },
        };
    },
    { index: 1 }
);

export const useSwitcherStyle = makeStyles(
    (theme: Theme) => {
        const isDarkMode = THEME_MODES.DARK === theme.palette.mode;
        return {
            root: {
                width: 32,
                height: 18,
                padding: 0,
                display: 'flex',
                transform: 'rotate(180deg)',
            },
            switchBase: {
                padding: 2,
                color: isDarkMode
                    ? theme.palette.primary.contrastText
                    : theme.palette.secondary.dark,
                '&$checked': {
                    transform: 'translateX(14px)',
                    color: theme.palette.secondary.dark,
                    '& + $track': {
                        opacity: 1,
                        backgroundColor: theme.palette.common.white,
                    },
                },
            },
            thumb: {
                width: 14,
                height: 14,
                boxShadow: 'none',
            },
            track: {
                border: `1px solid ${
                    isDarkMode ? theme.palette.primary.light : theme.palette.secondary.dark
                }`,
                borderRadius: 9,
                opacity: 1,
                backgroundColor: 'rgba(0, 0, 0, 0)',
            },
            checked: {},
        };
    },
    { index: 1 }
);

export const useChipStyles = makeStyles(
    (theme: Theme) => {
        return {
            root: {
                color: theme.palette.info.contrastText,
                fontSize: '1.2rem',
                backgroundColor: ({ checked }: { checked: boolean }) =>
                    !checked ? theme.palette.info.light : theme.palette.grey['400'],
            },
        };
    },
    { index: 1 }
);
