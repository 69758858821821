import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePageStyles } from 'src/pages/pages-styles';
import { DropZone } from './drop-zone';
import { FileObject } from 'react-mui-dropzone';
import { useFieldArray, useForm } from 'react-hook-form';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { FileUploadFormDto } from 'src/shared/types/file-upload-form-dto';
import { LoadingButton } from '@mui/lab';

interface FileImportDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess?: () => void;
    documentFormats: string[];
}

type FileImportDocumentFormData = {
    files: FileObject[];
};

export const FileImportDialog = ({
    isOpen,
    onClose,
    onSuccess,
    documentFormats,
}: FileImportDialogProps): JSX.Element => {
    const [isSaveLoading, setSaveLoading] = useState(false);
    const { t } = useTranslation('common');
    const { enqueueSnackbar } = useSnackbar();
    const classes = usePageStyles();
    const httpClient = useHttpClient();

    const { setValue, control, trigger } = useForm<FileImportDocumentFormData>({
        mode: 'onChange',
        defaultValues: { files: [] },
    });

    const { fields, remove } = useFieldArray({ control, name: 'files' });

    const handleAddFile = (newFiles: FileObject[]): void => {
        setValue('files', [...fields, ...newFiles]);
        trigger();
    };

    const handleDeleteFile = (index: number): void => {
        remove(index);
        trigger();
    };

    const makeRequestForSavingForm = async (): Promise<void> => {
        setSaveLoading(true);
        const dto: FileUploadFormDto = { documents: [] };
        fields.forEach((item) => {
            dto.documents.push(item.file);
        });
        await httpClient.post<FileUploadFormDto>('importFileInsuranceConfirmations', dto);
    };

    const handleSave = async (): Promise<void> => {
        try {
            await makeRequestForSavingForm();

            onClose();
            if (onSuccess !== undefined) {
                onSuccess();
            }
            enqueueSnackbar(t('fileImport.uploadedSuccessfully'), {
                variant: 'success',
            });
        } catch {
            enqueueSnackbar(t('errors:unknownError'), {
                variant: 'error',
            });
        } finally {
            setSaveLoading(false);
        }
    };

    return (
        <Dialog disableScrollLock open={isOpen} onClose={onClose}>
            <DialogTitle>
                <Typography variant='h5'>{t('fileImport.title')}</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Typography variant='body2'>{t('fileImport.description')}</Typography>
                    <FormControl fullWidth variant='outlined'>
                        <FormControlLabel
                            control={
                                <DropZone
                                    onAdd={handleAddFile}
                                    onRemove={handleDeleteFile}
                                    fileObjects={fields}
                                    formats={documentFormats}
                                />
                            }
                            label={<span>{t('fileImport.dragAndDrop')}</span>}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    disableElevation
                    className={classes.processButton}
                    variant='contained'
                    loading={isSaveLoading}
                    onClick={handleSave}
                >
                    {t('import')}
                </LoadingButton>
                <Button
                    disabled={isSaveLoading}
                    variant='outlined'
                    className={classes.processButton}
                    onClick={onClose}
                >
                    {t('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
