import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { THEME_MODES } from 'src/theming/theme-modes';

export const useNavigationStyles = makeStyles(
    () => {
        return {
            root: {
                height: '100%',
            },
            indicator: {
                opacity: '0',
            },
            fixed: {
                display: 'flex',
                alignItems: 'center',
            },
        };
    },
    { index: 1 }
);

export const useNavigationItemStyles = makeStyles(
    (theme: Theme) => {
        return {
            root: {
                height: 50,
                minHeight: 50,
                border: `1px solid ${theme.palette.secondary.contrastText}`,
                borderRight: 'none',
                color: theme.palette.secondary.contrastText,
                fontSize: '1.8rem',
                fontWeight: theme.typography.fontWeightRegular,
                opacity: 1,
                '&:last-child': {
                    borderRight: `1px solid ${theme.palette.secondary.contrastText}`,
                },
            },
            selected: {
                color: `${theme.palette.secondary.dark}!important`,
                backgroundColor: theme.palette.secondary.contrastText,
            },
        };
    },
    { index: 1 }
);

export const useTabStyle = makeStyles(
    (theme: Theme) => {
        return {
            root: {
                height: 40,
                minHeight: 40,
                fontSize: '1.4rem',
                minWidth: 'auto',
                paddingTop: theme.spacing(0),
                paddingBottom: theme.spacing(0),
                textTransform: 'none',
                color: theme.palette.primary.contrastText,
                fontWeight: theme.typography.fontWeightRegular,
                opacity: 1,
                borderRight: `1px solid ${
                    theme.palette.mode === THEME_MODES.DARK
                        ? theme.palette.secondary.main
                        : theme.palette.grey['300']
                }`,
            },
            selected: {
                fontWeight: theme.typography.fontWeightBold,
            },
        };
    },
    { index: 1 }
);
