import { TFunction } from 'i18next';
import React, { ReactElement } from 'react';
import { CellProps, Column, UseTableCellProps } from 'react-table';
import {
    CellWithTooltip,
    CellWithCommands,
    OpenProcessDialogCell,
    Status,
    StatusCell,
} from 'src/components/base/custom-table/custom-table-elements';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { XmlElement, XmlTree } from 'src/components/base/xml-editor';
import RawXmlDialog from 'src/components/base/raw-xml-dialog';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { withDraggable } from 'src/shared/hocs';
import { useTranslation } from 'react-i18next';
import ProcessChangesDialog from 'src/components/base/process-changes-dialog';
import { ChangeProcessResult, ChangesItem } from 'src/shared/types';
import { ChangeType } from 'src/shared/types/data-preparing';
import { Typography } from '@mui/material';

export enum ChangeTableKeys {
    id = 'id',
    insuranceConfirmationNumber = 'insuranceConfirmationNumber',
    internalInsuranceNotes = 'internalInsuranceNotes',
    changeType = 'changeType',
    result = 'result',
    resultDescription = 'resultDescription',
    licenseNumber = 'licenseNumber',
    owner = 'personName',
    agencyNumber = 'agencyNumber',
    date = 'inProgressAt',
    startOfContract = 'insuranceStartingDate',
    postcode = 'postalCode',
    place = 'placeOfResidence',
    street = 'street',
    houseNumber = 'houseNumber',
    commands = 'commands',
}

interface GenerateChangeTableConfigParams {
    t: TFunction;
    startPosition: number | undefined;
    hoverPosition: number | undefined;
    columnPositions: { [key: string]: number };
    isDndLocked: boolean;
    onProcessDialogClose: () => void;
}

interface MyCellProps {
    id: string;
    insuranceConfirmationNumber: string;
}

interface MyProcessCellProps extends MyCellProps {
    type: ChangeType;
    onProcessDialogClose: () => void;
}

const MyProcessCell = ({
    id,
    insuranceConfirmationNumber,
    type,
    onProcessDialogClose,
}: MyProcessCellProps): ReactElement => {
    const [open, setOpen] = React.useState<boolean>(false);

    const openProcessDialog = () => {
        setOpen(true);
    };

    const closeProcessDialog = () => {
        setOpen(false);
        onProcessDialogClose();
    };

    if (
        type === ChangeType.Holder ||
        type === ChangeType.LicenseNumber ||
        type === ChangeType.Oldtimer ||
        type === ChangeType.Eletric
    ) {
        return (
            <Typography noWrap variant='body2'>
                {insuranceConfirmationNumber}
            </Typography>
        );
    } else {
        const dialog = (
            <ProcessChangesDialog insuranceId={id} open={open} onClose={closeProcessDialog} />
        );

        return (
            <OpenProcessDialogCell
                name={insuranceConfirmationNumber}
                dialog={dialog}
                onOpen={openProcessDialog}
            />
        );
    }
};

const MyCellWithCommands = ({ id, insuranceConfirmationNumber }: MyCellProps): ReactElement => {
    const [xml, setXml] = React.useState<XmlTree | undefined>(undefined);
    const [rawXml, setRawXml] = React.useState<string | undefined>(undefined);
    const [open, setOpen] = React.useState<boolean>(false);
    const [xmlIsLoading, setXmlIsLoading] = React.useState<boolean>(false);

    const httpClient = useHttpClient();
    const { t } = useTranslation(['common']);

    const handleOpen = async (insuranceId: string) => {
        setOpen(true);
        setXmlIsLoading(true);
        const xml = await httpClient.get<string>('insuranceConfirmationSource', {
            axiosConfig: { params: { insuranceConfirmationId: insuranceId } },
        });

        if (xml === undefined || xml === null || xml === '') {
            setRawXml(undefined);
            setXml(undefined);
        } else {
            setRawXml(xml);
            const tree = new XmlTree(xml);
            setXml(tree);
        }
        setXmlIsLoading(false);
    };

    const handleChanged = (element: XmlElement) => {
        if (xml === undefined || !xml.elements.has(element.id)) {
            return;
        }
        xml.elements.set(element.id, element);
        setXml(xml);
    };

    const handleUpload = async () => {
        if (xml === undefined) {
            return;
        }
        const rawXml = xml.toString();
        await httpClient.post('uploadInsuranceConfirmation', { xml: rawXml });
    };

    const handleClose = () => {
        setOpen(false);
        setXml(undefined);
        setRawXml(undefined);
    };
    return (
        <CellWithCommands
            insuranceId={id}
            commands={[
                {
                    name: 'source',
                    onOpen: async (e, insuranceId) => {
                        await handleOpen(insuranceId);
                    },
                    uiComponents: [
                        <RawXmlDialog
                            key={'rawXmlDialog'}
                            name={insuranceConfirmationNumber}
                            open={open}
                            xmlTree={xml}
                            xmlString={rawXml}
                            isLoading={xmlIsLoading}
                            onClose={handleClose}
                            onUpload={handleUpload}
                            onXmlElementChanged={handleChanged}
                        />,
                    ],
                    tooltip: t('rawXmlSource'),
                    icon: <InsertDriveFileOutlinedIcon fontSize='inherit' />,
                },
            ]}
        />
    );
};

interface ChangeTypeCellProps {
    value: ChangeProcessResult;
    column: Column;
}

const ChangeTypeCell = (props: ChangeTypeCellProps): ReactElement => {
    const { t } = useTranslation('change');

    return <CellWithTooltip {...props} value={t(ChangeType[props.value])} />;
};

const DraggableCell = withDraggable<{ value: string; column: Column }>((props) => (
    <CellWithTooltip {...props} />
));

export const generateChangeTableConfig = ({
    t,
    startPosition,
    hoverPosition,
    columnPositions,
    isDndLocked,
    onProcessDialogClose,
}: GenerateChangeTableConfigParams): Array<Column<object>> => {
    return [
        {
            id: ChangeTableKeys.insuranceConfirmationNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:evbNr')}
                    draggableId={ChangeTableKeys.insuranceConfirmationNumber}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.insuranceConfirmationNumber]}
                />
            ),
            accessor: ChangeTableKeys.insuranceConfirmationNumber,
            Cell: (props: UseTableCellProps<ChangesItem>) => {
                return (
                    <MyProcessCell
                        id={props.row.id}
                        insuranceConfirmationNumber={
                            props.row.values['insuranceConfirmationNumber']
                        }
                        type={props.row.values['changeType']}
                        onProcessDialogClose={onProcessDialogClose}
                    />
                );
            },
            width: 110,
            disableSortBy: !isDndLocked,
        },
        {
            id: ChangeTableKeys.licenseNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:licenseNumber')}
                    draggableId={ChangeTableKeys.licenseNumber}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.licenseNumber]}
                />
            ),
            accessor: ChangeTableKeys.licenseNumber,
            Cell: CellWithTooltip,
            disableSortBy: !isDndLocked,
            width: 150,
        },
        {
            id: ChangeTableKeys.internalInsuranceNotes,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:internalInsuranceNotes')}
                    draggableId={ChangeTableKeys.internalInsuranceNotes}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.internalInsuranceNotes]}
                />
            ),
            accessor: ChangeTableKeys.internalInsuranceNotes,
            Cell: CellWithTooltip,
            disableSortBy: !isDndLocked,
            width: 150,
        },
        {
            id: ChangeTableKeys.changeType,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:changeType')}
                    draggableId={ChangeTableKeys.changeType}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.changeType]}
                />
            ),
            accessor: ChangeTableKeys.changeType,
            Cell: ChangeTypeCell,
            disableSortBy: !isDndLocked,
            width: 150,
        },
        {
            id: ChangeTableKeys.result,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:result')}
                    draggableId={ChangeTableKeys.result}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.result]}
                />
            ),
            accessor: ChangeTableKeys.result,
            Cell: (props: UseTableCellProps<ChangesItem>) => {
                let statusText = t('change:' + ChangeProcessResult[props.value]);
                if (props.value === ChangeProcessResult.InProgress) {
                    statusText += ' ' + props.row.original.processor;
                }

                let status = Status.Failure;
                if (props.value === ChangeProcessResult.InProgress) {
                    status = Status.OnHold;
                } else if (
                    props.value === ChangeProcessResult.NoProcessing ||
                    props.value === ChangeProcessResult.ReadyToProcess
                ) {
                    status = Status.Success;
                }

                return (
                    <StatusCell
                        statusText={statusText}
                        status={status}
                        maxWidth={props.column.width}
                    />
                );
            },
            disableSortBy: !isDndLocked,
            width: 215,
        },
        {
            id: ChangeTableKeys.owner,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:owner')}
                    draggableId={ChangeTableKeys.owner}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.owner]}
                />
            ),
            accessor: ChangeTableKeys.owner,
            Cell: CellWithTooltip,
            disableSortBy: !isDndLocked,
            width: 140,
        },
        {
            id: ChangeTableKeys.agencyNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:agencyNumber')}
                    draggableId={ChangeTableKeys.agencyNumber}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.agencyNumber]}
                />
            ),
            accessor: ChangeTableKeys.agencyNumber,
            Cell: CellWithTooltip,
            disableSortBy: !isDndLocked,
            width: 100,
        },
        {
            id: ChangeTableKeys.date,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:date')}
                    draggableId={ChangeTableKeys.date}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.date]}
                />
            ),
            accessor: ChangeTableKeys.date,
            Cell: (props: React.PropsWithChildren<CellProps<object, string>>) => (
                <CellWithTooltip
                    {...props}
                    valueFormatter={(date) => {
                        return t('formatted-values:formattedDate', {
                            date,
                        })?.replaceAll('&#x2F;', '/');
                    }}
                />
            ),
            width: 100,
            disableSortBy: !isDndLocked,
        },
        {
            id: ChangeTableKeys.startOfContract,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:startOfContract')}
                    draggableId={ChangeTableKeys.startOfContract}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.startOfContract]}
                />
            ),
            accessor: ChangeTableKeys.startOfContract,
            Cell: (props) => (
                <CellWithTooltip
                    {...props}
                    valueFormatter={(date) => {
                        return t('formatted-values:formattedDate', {
                            date,
                        })?.replaceAll('&#x2F;', '/');
                    }}
                />
            ),
            width: 100,
            disableSortBy: !isDndLocked,
        },
        {
            id: ChangeTableKeys.postcode,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:postcode')}
                    draggableId={ChangeTableKeys.postcode}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.postcode]}
                />
            ),
            accessor: ChangeTableKeys.postcode,
            Cell: CellWithTooltip,
            width: 60,
            disableSortBy: !isDndLocked,
        },
        {
            id: ChangeTableKeys.place,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:place')}
                    draggableId={ChangeTableKeys.place}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.place]}
                />
            ),
            accessor: ChangeTableKeys.place,
            Cell: CellWithTooltip,
            width: 95,
            disableSortBy: !isDndLocked,
        },
        {
            id: ChangeTableKeys.street,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:street')}
                    draggableId={ChangeTableKeys.street}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.street]}
                />
            ),
            accessor: ChangeTableKeys.street,
            Cell: CellWithTooltip,
            width: 100,
            disableSortBy: true,
        },
        {
            id: ChangeTableKeys.houseNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:houseNumber')}
                    draggableId={ChangeTableKeys.houseNumber}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.houseNumber]}
                />
            ),
            accessor: ChangeTableKeys.houseNumber,
            Cell: CellWithTooltip,
            width: 50,
            disableSortBy: true,
        },
        {
            id: ChangeTableKeys.commands,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={''}
                    draggableId={ChangeTableKeys.commands}
                    isDragAndDropLocked={isDndLocked}
                    draggableIndex={columnPositions[ChangeTableKeys.commands]}
                />
            ),
            accessor: ChangeTableKeys.commands,
            Cell: (props: UseTableCellProps<ChangesItem>) => (
                <MyCellWithCommands
                    id={props.row.id}
                    insuranceConfirmationNumber={props.row.values['insuranceConfirmationNumber']}
                />
            ),
            width: 50,
            disableSortBy: true,
        },
    ];
};
