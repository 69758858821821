import React from 'react';
import { Navigate } from 'react-router-dom';

import { AppRoutes } from '../app-routes';

export const withAuthValidation = (isAuth: boolean) => (component: () => React.ReactElement) =>
    function WithAuthValidation() {
        if (!isAuth) {
            return <Navigate to={AppRoutes.LOGIN} />;
        }
        return component();
    };
