import React, { ReactElement } from 'react';
import { Box, Divider, TextField, Typography } from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import { XmlElement } from './xml-element';
import { XmlTree } from './xml-tree';
import { XmlTreeNode } from './xml-tree-node';
import { useTranslation } from 'react-i18next';

const CollapseIcon = (): JSX.Element => {
    return <Typography variant='body1'>-</Typography>;
};

const ExpandIcon = (): JSX.Element => {
    return <Typography variant='body1'>+</Typography>;
};

interface XmlEditorProps {
    xml: XmlTree;
    onElementChanged(element: XmlElement): void;
}

export function XmlEditor({ xml, onElementChanged }: XmlEditorProps): ReactElement {
    const [selected, setSelected] = React.useState<string | undefined>(undefined);
    const [editingValue, setEditingValue] = React.useState<string | undefined>(undefined);

    const { t } = useTranslation('common');

    const onSelect = (id: string) => {
        const element = xml.elements.get(id);
        if (!element || !element.value) {
            setSelected(undefined);
            return;
        }
        setSelected(element.id);
        setEditingValue(element.value);
    };

    const onUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!selected) {
            return;
        }

        const element = xml.elements.get(selected);
        if (element) {
            element.value = event.target.value;
            setEditingValue(element.value);
            onElementChanged(element);
        }
    };

    const getElementName = (): string => {
        if (!selected) {
            return '';
        }

        const element = xml.elements.get(selected);
        if (!element) {
            return '';
        }

        return element.name;
    };

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                color: 'text.secondary',
            }}
        >
            <Box sx={{ width: '50%', margin: '12px' }}>
                <TreeView defaultCollapseIcon={<CollapseIcon />} defaultExpandIcon={<ExpandIcon />}>
                    <XmlTreeNode element={xml.root} onNodeSelect={onSelect} />
                </TreeView>
            </Box>
            <Divider orientation='vertical' variant='middle' flexItem />
            {selected && (
                <Box sx={{ width: '50%', margin: '12px' }}>
                    <Typography variant='overline'>{getElementName()}</Typography>
                    <TextField
                        fullWidth
                        type='text'
                        variant='outlined'
                        label={t('value')}
                        inputProps={{ readOnly: false }}
                        value={editingValue}
                        onChange={onUpdate}
                    />
                </Box>
            )}
        </Box>
    );
}
