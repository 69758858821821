import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useCellWithFilterDropDownStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            height: '100%',
            width: '100%',
            alignItems: 'center',
            '& .MuiList-root': {
                padding: 0,
            },
        },
        certainFilterIndicator: {
            height: '10px',
            width: '10px',
            backgroundColor: theme.palette.primary.main,
            position: 'absolute',
            borderRadius: '5px',
            top: '-1px',
            right: '-2px',
        },
        title: {
            fontSize: '1.6rem',
            maxWidth: '80%',
        },
        filterIconButton: {
            paddingLeft: 0,
            paddingRight: 0,
            width: 20,
            height: 20,
            marginLeft: theme.spacing(2),
        },
        filterItem: {
            fontSize: '1.4rem',
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(3),
            '& .MuiCheckbox-root': {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
        applyButton: {
            width: '100%',
            minWidth: 180,
        },
        listTitle: {
            paddingLeft: theme.spacing(3),
            paddingBottom: theme.spacing(2),
            fontSize: '1.6rem',
            fontWeight: theme.typography.fontWeightMedium,
        },
        listContainer: {
            minHeight: 150,
            maxHeight: 400,
            overflowY: 'auto',
        },
    };
});
