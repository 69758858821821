import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAbilityCtx, AbilitySubjects, AbilityActions } from 'src/security/roleAccesses';

export const withRoleValidation =
    (page: AbilitySubjects, homePath: string) => (component: () => React.ReactElement) =>
        function WithRoleValidation() {
            const ability = useAbilityCtx();

            if (!ability.can(AbilityActions.see, page)) {
                return <Navigate to={homePath} />;
            }
            return component();
        };
