import { useState } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

interface UseDearchiveEntitiesReturn {
    dearchive: () => Promise<void>;
    isLoading: boolean;
}

interface UseDearchiveEntitiesParams {
    insuranceConfirmationIds: string[];
    url: string;
    onSuccess: () => Promise<void>;
}

interface EntitiesBodyDto {
    insuranceConfirmationIds: string[];
}

export const useDearchiveEntities = (
    params: UseDearchiveEntitiesParams
): UseDearchiveEntitiesReturn => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const { url, insuranceConfirmationIds, onSuccess } = params;
    const httpClient = useHttpClient();

    const dearchive = async (): Promise<void> => {
        try {
            const body: EntitiesBodyDto = {
                insuranceConfirmationIds,
            };
            setLoading(true);
            await httpClient.post(url, body);
        } finally {
            setLoading(false);
            await onSuccess();
        }
    };

    return {
        isLoading,
        dearchive,
    };
};
