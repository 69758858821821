import { ReactElement } from 'react';
import { TFunction } from 'i18next';
import { Column, Row } from 'react-table';
import {
    CellWithTooltip,
    BooleanCell,
    CellWithCommands,
} from 'src/components/base/custom-table/custom-table-elements';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { withDraggable } from 'src/shared/hocs';
import React from 'react';
import { XmlElement, XmlTree } from 'src/components/base/xml-editor';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useTranslation } from 'react-i18next';
import RawXmlDialog from 'src/components/base/raw-xml-dialog';

export enum SigningOffTableKeys {
    id = 'id',
    insuranceConfirmationNumber = 'insuranceConfirmationNumber',
    contractNumber = 'contractNumber',
    licenseNumber = 'licenseNumber',
    contractStatus = 'contractStatus',
    customer = 'customer',
    result = 'result',
    date = 'inProgressAt',
    customerRelation = 'customerRelation',
    startOfContract = 'insuranceStartingDate',
    postcode = 'postalCode',
    place = 'placeOfResidence',
    street = 'street',
    houseNumber = 'houseNumber',
    owner = 'personName',
    commands = 'commands',
}

interface GenerateSigningOffTableConfigParams {
    t: TFunction;
    columnPositions: { [key: string]: number };
    isDndLocked: boolean;
}

interface MyCellProps {
    row: Row<object>;
}

const MyCellWithCommands = ({ row }: MyCellProps): ReactElement => {
    const [xml, setXml] = React.useState<XmlTree | undefined>(undefined);
    const [rawXml, setRawXml] = React.useState<string | undefined>(undefined);
    const [open, setOpen] = React.useState<boolean>(false);
    const [xmlIsLoading, setXmlIsLoading] = React.useState<boolean>(false);

    const httpClient = useHttpClient();
    const { t } = useTranslation(['common']);

    const handleOpen = async (insuranceId: string) => {
        setOpen(true);
        setXmlIsLoading(true);
        const xml = await httpClient.get<string>('insuranceConfirmationSource', {
            axiosConfig: { params: { insuranceConfirmationId: insuranceId } },
        });

        if (xml === undefined || xml === null || xml === '') {
            setRawXml(undefined);
            setXml(undefined);
        } else {
            setRawXml(xml);
            const tree = new XmlTree(xml);
            setXml(tree);
        }
        setXmlIsLoading(false);
    };

    const handleChanged = (element: XmlElement) => {
        if (xml === undefined || !xml.elements.has(element.id)) {
            return;
        }
        xml.elements.set(element.id, element);
        setXml(xml);
    };

    const handleUpload = async () => {
        if (xml === undefined) {
            return;
        }
        const rawXml = xml.toString();
        await httpClient.post('uploadInsuranceConfirmation', { xml: rawXml });
    };

    const handleClose = () => {
        setOpen(false);
        setXml(undefined);
        setRawXml(undefined);
    };
    return (
        <CellWithCommands
            insuranceId={row.id}
            commands={[
                {
                    name: 'source',
                    onOpen: async (e, insuranceId) => {
                        await handleOpen(insuranceId);
                    },
                    uiComponents: [
                        <RawXmlDialog
                            key={'rawXmlDialog'}
                            name={row.values['insuranceConfirmationNumber']}
                            open={open}
                            xmlTree={xml}
                            xmlString={rawXml}
                            isLoading={xmlIsLoading}
                            onClose={handleClose}
                            onUpload={handleUpload}
                            onXmlElementChanged={handleChanged}
                        />,
                    ],
                    tooltip: t('rawXmlSource'),
                    icon: <InsertDriveFileOutlinedIcon fontSize='inherit' />,
                },
            ]}
        />
    );
};

interface ResultCellProps {
    value: number | boolean;
}

const ResultCell = (props: ResultCellProps): ReactElement => {
    return <BooleanCell {...props} value={!props.value} />;
};

const DraggableCell = withDraggable<{ value: string; column: Column }>((props) => (
    <CellWithTooltip {...props} />
));

export const generateSigningOffTableConfig = ({
    t,
    columnPositions,
    isDndLocked,
}: GenerateSigningOffTableConfigParams): Array<Column<object>> => {
    return [
        {
            id: SigningOffTableKeys.insuranceConfirmationNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('signing-off:evbNr')}
                    draggableId={SigningOffTableKeys.insuranceConfirmationNumber}
                    isDragAndDropLocked={isDndLocked}
                    draggableIndex={
                        columnPositions[SigningOffTableKeys.insuranceConfirmationNumber]
                    }
                />
            ),
            accessor: SigningOffTableKeys.insuranceConfirmationNumber,
            Cell: CellWithTooltip,
            width: 110,
            disableSortBy: !isDndLocked,
        },
        {
            id: SigningOffTableKeys.date,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('signing-off:date')}
                    draggableId={SigningOffTableKeys.date}
                    isDragAndDropLocked={isDndLocked}
                    draggableIndex={columnPositions[SigningOffTableKeys.date]}
                />
            ),
            accessor: SigningOffTableKeys.date,
            Cell: (props) => (
                <CellWithTooltip
                    {...props}
                    valueFormatter={(date) => {
                        return t('formatted-values:formattedDate', {
                            date,
                        })?.replaceAll('&#x2F;', '/');
                    }}
                />
            ),
            width: 100,
            disableSortBy: !isDndLocked,
        },
        {
            id: SigningOffTableKeys.licenseNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('signing-off:licenseNumber')}
                    draggableId={SigningOffTableKeys.licenseNumber}
                    isDragAndDropLocked={isDndLocked}
                    draggableIndex={columnPositions[SigningOffTableKeys.licenseNumber]}
                />
            ),
            accessor: SigningOffTableKeys.licenseNumber,
            Cell: CellWithTooltip,
            disableSortBy: !isDndLocked,
            width: 150,
        },
        {
            id: SigningOffTableKeys.customerRelation,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('signing-off:customerRelation')}
                    draggableId={SigningOffTableKeys.customerRelation}
                    isDragAndDropLocked={isDndLocked}
                    draggableIndex={columnPositions[SigningOffTableKeys.customerRelation]}
                />
            ),
            accessor: SigningOffTableKeys.customerRelation,
            Cell: CellWithTooltip,
            width: 170,
            disableSortBy: !isDndLocked,
        },
        {
            id: SigningOffTableKeys.customer,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('signing-off:customer')}
                    draggableId={SigningOffTableKeys.customer}
                    isDragAndDropLocked={isDndLocked}
                    draggableIndex={columnPositions[SigningOffTableKeys.customer]}
                />
            ),
            accessor: SigningOffTableKeys.customer,
            Cell: CellWithTooltip,
            width: 100,
            disableSortBy: !isDndLocked,
        },
        {
            id: SigningOffTableKeys.contractNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('signing-off:contractNumber')}
                    draggableId={SigningOffTableKeys.contractNumber}
                    isDragAndDropLocked={isDndLocked}
                    draggableIndex={columnPositions[SigningOffTableKeys.contractNumber]}
                />
            ),
            accessor: SigningOffTableKeys.contractNumber,
            Cell: CellWithTooltip,
            width: 100,
            disableSortBy: !isDndLocked,
        },
        {
            id: SigningOffTableKeys.contractStatus,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('signing-off:contractStatus')}
                    draggableId={SigningOffTableKeys.contractStatus}
                    isDragAndDropLocked={isDndLocked}
                    draggableIndex={columnPositions[SigningOffTableKeys.contractStatus]}
                />
            ),
            accessor: SigningOffTableKeys.contractStatus,
            Cell: CellWithTooltip,
            disableSortBy: !isDndLocked,
            width: 100,
        },
        {
            id: SigningOffTableKeys.startOfContract,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('signing-off:startOfContract')}
                    draggableId={SigningOffTableKeys.startOfContract}
                    isDragAndDropLocked={isDndLocked}
                    draggableIndex={columnPositions[SigningOffTableKeys.startOfContract]}
                />
            ),
            accessor: SigningOffTableKeys.startOfContract,
            Cell: (props) => (
                <CellWithTooltip
                    {...props}
                    valueFormatter={(date) => {
                        return t('formatted-values:formattedDate', {
                            date,
                        })?.replaceAll('&#x2F;', '/');
                    }}
                />
            ),
            width: 100,
            disableSortBy: !isDndLocked,
        },
        {
            id: SigningOffTableKeys.postcode,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('signing-off:postcode')}
                    draggableId={SigningOffTableKeys.postcode}
                    isDragAndDropLocked={isDndLocked}
                    draggableIndex={columnPositions[SigningOffTableKeys.postcode]}
                />
            ),
            accessor: SigningOffTableKeys.postcode,
            Cell: CellWithTooltip,
            width: 60,
            disableSortBy: !isDndLocked,
        },
        {
            id: SigningOffTableKeys.place,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('signing-off:place')}
                    draggableId={SigningOffTableKeys.place}
                    isDragAndDropLocked={isDndLocked}
                    draggableIndex={columnPositions[SigningOffTableKeys.place]}
                />
            ),
            accessor: SigningOffTableKeys.place,
            Cell: CellWithTooltip,
            width: 95,
            disableSortBy: !isDndLocked,
        },
        {
            id: SigningOffTableKeys.street,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('signing-off:street')}
                    draggableId={SigningOffTableKeys.street}
                    isDragAndDropLocked={isDndLocked}
                    draggableIndex={columnPositions[SigningOffTableKeys.street]}
                />
            ),
            accessor: SigningOffTableKeys.street,
            Cell: CellWithTooltip,
            width: 100,
            disableSortBy: true,
        },
        {
            id: SigningOffTableKeys.houseNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('signing-off:houseNumber')}
                    draggableId={SigningOffTableKeys.houseNumber}
                    isDragAndDropLocked={isDndLocked}
                    draggableIndex={columnPositions[SigningOffTableKeys.houseNumber]}
                />
            ),
            accessor: SigningOffTableKeys.houseNumber,
            Cell: CellWithTooltip,
            width: 50,
            disableSortBy: true,
        },
        {
            id: SigningOffTableKeys.owner,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('signing-off:owner')}
                    draggableId={SigningOffTableKeys.owner}
                    isDragAndDropLocked={isDndLocked}
                    draggableIndex={columnPositions[SigningOffTableKeys.owner]}
                />
            ),
            accessor: SigningOffTableKeys.owner,
            Cell: CellWithTooltip,
            width: 140,
            disableSortBy: !isDndLocked,
        },
        {
            id: SigningOffTableKeys.result,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('signing-off:result')}
                    draggableId={SigningOffTableKeys.result}
                    isDragAndDropLocked={isDndLocked}
                    draggableIndex={columnPositions[SigningOffTableKeys.result]}
                />
            ),
            accessor: SigningOffTableKeys.result,
            Cell: ResultCell,
            width: 60,
            disableSortBy: !isDndLocked,
        },
        {
            id: SigningOffTableKeys.commands,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={''}
                    draggableId={SigningOffTableKeys.commands}
                    isDragAndDropLocked={isDndLocked}
                    draggableIndex={columnPositions[SigningOffTableKeys.commands]}
                />
            ),
            accessor: SigningOffTableKeys.commands,
            Cell: MyCellWithCommands,
            width: 50,
            disableSortBy: true,
        },
    ];
};
