import { Autocomplete, TextField, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { CustomerData } from 'src/shared/types/data-preparing';
import { DataPreparingCustomersDto } from 'src/shared/types/data-preparing';
import { DataKeyProperties } from './category-data-keys';
import { StringObjectPropertyIndex } from './stringObjectPropertyIndex';

interface CustomerAutocompleteProps {
    dataKey: DataKeyProperties;
    data: StringObjectPropertyIndex;
    selected: CustomerData;
    onValueChanging(
        dataKey: DataKeyProperties,
        data: StringObjectPropertyIndex,
        newValue: any
    ): void;
}

export function CustomerAutocomplete({
    dataKey,
    data,
    selected,
    onValueChanging,
}: CustomerAutocompleteProps): ReactElement {
    const [searchResults, setSearchResults] = React.useState<readonly CustomerData[]>([selected]);
    const [current, setCurrent] = React.useState<string>('');
    const httpClient = useHttpClient();

    const updateSearchResults = async (value: string) => {
        setCurrent(value);

        if (value.length >= 3) {
            const customers = await httpClient.get<DataPreparingCustomersDto>(
                'getDataPreparingCustomers',
                {
                    axiosConfig: { params: { searchTerm: value } },
                }
            );

            setSearchResults(customers.items);
        } else {
            setSearchResults([]);
        }
    };

    return (
        <Autocomplete
            size='small'
            options={searchResults}
            getOptionLabel={(option: CustomerData) => option?.customer1 ?? ''}
            inputValue={current}
            onInputChange={(e, value) => updateSearchResults(value)}
            value={selected}
            style={{
                maxWidth: '100%',
                verticalAlign: 'middle',
            }}
            onChange={(e, value) => onValueChanging(dataKey, data, value)}
            renderInput={(params) => <TextField variant='outlined' size='small' {...params} />}
            renderOption={(props, option) => {
                if (!option) {
                    return null;
                }

                return (
                    <li {...props} key={option.id}>
                        <div>
                            <Typography>{option.customer1}</Typography>
                            <Typography variant='body2'>{option.customer2}</Typography>
                            <Typography variant='body2'>{option.customer3}</Typography>
                        </div>
                    </li>
                );
            }}
        />
    );
}
