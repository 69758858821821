import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { THEME_MODES } from 'src/theming/theme-modes';

export const useAuthLayoutStyles = makeStyles((theme: Theme) => {
    const isLightTheme = theme.palette.mode === THEME_MODES.LIGHT;
    return {
        pageContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            height: '100vh',
            backgroundColor: theme.palette.primary.main,
            overflow: 'hidden',
        },
        block: {
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            width: '100%',
        },
        logoAndSwitcherContainer: {
            height: 218,
            alignItems: 'center',
            [theme.breakpoints.down('xl')]: {
                height: 150,
            },
        },
        switcherContainer: {
            position: 'absolute',
            right: 100,
            top: 20,
        },
        logoContainer: {
            width: 398,
        },
        logo: {
            width: '100%',
        },
        previewContainer: {
            height: 260,
            backgroundColor: theme.palette.secondary.dark,
            [theme.breakpoints.down('xl')]: {
                height: 200,
            },
        },
        previewText: {
            position: 'absolute',
            top: -250,
            paddingTop: theme.spacing(8),
            color: theme.palette.secondary.contrastText,
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: '3.7rem',
            zIndex: 1000,
            [theme.breakpoints.down('xl')]: {
                top: -205,
                fontSize: '3rem',
            },
        },
        childrenContainer: {
            position: 'relative',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        formContainer: {
            width: 650,
            padding: `${theme.spacing(8)} ${theme.spacing(10)}`,
            boxSizing: 'border-box',
            marginTop: -130,
            zIndex: 1000,
            boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.08)',
            border: !isLightTheme ? `1px solid ${theme.palette.common.white}` : 'none',
            backgroundColor: !isLightTheme
                ? theme.palette.secondary.dark
                : theme.palette.primary.light,
            [theme.breakpoints.down('xl')]: {
                padding: `${theme.spacing(5)} ${theme.spacing(7)}`,
                marginTop: -100,
            },
        },
        footerContainer: {
            width: '100%',
            height: 60,
            backgroundColor: isLightTheme
                ? theme.palette.secondary.main
                : theme.palette.primary.dark,
            '& a,span': {
                color: theme.palette.secondary.contrastText,
            },
            [theme.breakpoints.down('xl')]: {
                height: 30,
            },
        },
    };
});
