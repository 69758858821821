import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { FileObject } from 'react-mui-dropzone';

export const useCheckTotalFilesSize = (): ((
    files: FileObject[],
    maxTotalSize: number
) => boolean) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation(['common']);

    const showNotificationError = (): void => {
        enqueueSnackbar(t('fileImport.totalFileSizeLimitError'), { variant: 'error' });
    };

    const checkTotalFilesSize = (files: FileObject[], maxTotalSize: number): boolean => {
        const totalSize = files.reduce((accum, item) => accum + item.file.size, 0);
        if (totalSize > maxTotalSize) {
            showNotificationError();
        }
        return totalSize < maxTotalSize;
    };

    return checkTotalFilesSize;
};
