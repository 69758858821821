import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { THEME_MODES } from 'src/theming/theme-modes';

export const usePhoneFieldStyles = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.mode;
    return {
        phoneNumber: {
            padding: 0,
            '&.react-tel-input .form-control': {
                fontSize: '1.6rem',
            },
            '& .country-list': {
                backgroundColor: theme.palette.primary.dark,
                '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                },

                '& .country': {
                    transition: '0.2s',
                    '& .country-name:hover': {
                        backgroundColor: theme.palette.primary.light,
                    },
                    '&.highlight': {
                        backgroundColor: theme.palette.primary.light,
                    },
                    '&:hover': {
                        backgroundColor: theme.palette.primary.light,
                    },
                    '& .dial-code': {
                        color: `${theme.palette.primary.contrastText}!important`,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.light,
                        },
                    },
                },
            },
            '& input': {
                height: '40px!important',
                width: '100%!important',
                border: 'none!important',
                background: 'rgba(0, 0, 0, 0)!important',
            },

            '& .special-label': {
                display: 'none',
            },
            '& .flag-dropdown ': {
                top: 5,
                left: 2,
                height: 30,
                zIndex: 1,
                border: 'none',
                backgroundColor: isDarkMode
                    ? theme.palette.primary.dark
                    : theme.palette.primary.main,
                transition: '0.2s',
                '& .selected-flag .arrow': {
                    borderTopColor: theme.palette.primary.contrastText,
                    '&.up': {
                        borderBottomColor: theme.palette.primary.contrastText,
                    },
                },
                '&:hover': {
                    backgroundColor: isDarkMode
                        ? theme.palette.primary.dark
                        : theme.palette.primary.main,
                },
            },
            '& .flag-dropdown.open': {
                borderRadius: 10,
                '& .selected-flag': {
                    backgroundColor: isDarkMode
                        ? theme.palette.primary.dark
                        : theme.palette.primary.main,
                },
            },
        },
    };
});
