import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { THEME_MODES } from 'src/theming/theme-modes';

export const getResponsiveSpacing = (theme: Theme): { [key: string]: { padding: string } } => {
    return {
        [`@media (min-width: 1920px)`]: {
            padding: `${theme.spacing(2)} 140px`,
        },
        [theme.breakpoints.down('xl')]: {
            padding: `${theme.spacing(2)} 20px`,
        },
    };
};

export const useUserManagementStyles = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.mode;
    return {
        container: {
            overflowX: 'hidden',
            minHeight: '100vh',
            padding: 0,
            backgroundColor: theme.palette.primary.main,
        },
        header: {
            width: '100%',
            padding: `0 100px`,
            backgroundColor: !isDarkMode ? theme.palette.primary.light : theme.palette.primary.dark,
            ...getResponsiveSpacing(theme),
        },
        main: {
            width: '100%',
            display: 'flex',
            alignItems: 'stretch',
            flexWrap: 'nowrap',
            flexGrow: 1,
            gap: theme.spacing(5),
            padding: `${theme.spacing(2)} 100px`,
            backgroundColor: !isDarkMode ? theme.palette.primary.light : theme.palette.primary.dark,
            ...getResponsiveSpacing(theme),
        },
        navigationPanel: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            height: 110,
            alignItems: 'center',
            flexWrap: 'nowrap',
            padding: `0 100px`,
            backgroundColor: theme.palette.secondary.dark,
            ...getResponsiveSpacing(theme),
            [theme.breakpoints.down('xl')]: {
                ...getResponsiveSpacing(theme)[theme.breakpoints.down('xl')],
                height: 80,
            },
        },
        usersListContainer: {
            minWidth: 400,
        },
        userFormContainer: {
            flexGrow: 1,
        },
        usersHeaderContainer: {
            width: '100%',
            padding: theme.spacing(2),
            backgroundColor: isDarkMode ? theme.palette.primary.dark : theme.palette.primary.main,
            ...getResponsiveSpacing(theme),
        },
        footer: {
            width: '100%',
            minHeight: 60,
            maxHeight: 60,
            backgroundColor: !isDarkMode
                ? theme.palette.secondary.main
                : theme.palette.primary.main,
            '& a,span': {
                color: theme.palette.secondary.contrastText,
            },
            [theme.breakpoints.down('xl')]: {
                minHeight: 30,
                maxHeight: 30,
            },
        },
    };
});
