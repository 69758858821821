export enum RegistrationResult {
    Unknown = -1,
    AmsContractFound,
    ContractNew,
    NoWkzFound,
    NoFrameworkContractFound,
    MoreThanOneFrameworkContractFound,
    NoAmsContractFound,
    MoreThanOneAmsContractFound,
    FrameworkContractFound,
    ContractFound = FrameworkContractFound,
}

export interface RegistrationItem {
    id: string;
    insuranceConfirmationNumber: string;
    contractNumber: string;
    licenseNumber: string;
    risk: string;
    customer: string;
    result: RegistrationResult;
}
