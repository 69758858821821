import React, {
    SyntheticEvent,
    ReactElement,
    useState,
    useMemo,
    MouseEvent,
    useCallback,
    useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SortingRule, Column } from 'react-table';

import { Grid, IconButton } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import { CustomTable, CircularProgress, ThemeCheckboxesDropdown } from 'src/components/base';
import { getSortingPositions } from 'src/shared/utils';
import { TableNames, GlobalStoreActions } from 'src/shared/constants';
import { useGlobalStore } from 'src/shared/contexts';
import { useGetTableSettings, useUpdateTableSettings } from 'src/shared/hooks';
import { TableColumnSettings } from 'src/shared/types';
import { useDebounce } from 'src/lib/custom-hooks/use-debounce';
import { usePageStyles } from '../pages-styles';
import { useColumnVisibilityState } from './hooks/use-column-visibility-state';
import { VeelaMessagesDefaultOrderColumn } from './raw-veela-messages-default-columns-order';
import { generateVeelaMessagesTableConfig } from './raw-veela-messages-table-config';
import { useGetVeelaMessages } from './hooks/use-get-veela-messages';
import { VeelaMessage } from './veela-message';
import { DynamicPropertyMultiFilter } from 'src/lib/utility/dynamic-property-multi-filter';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { ExportVeelaMessagesRequestDto } from 'src/pages/raw-veela-messages/interfaces/export-veela-messages-request-dto';
const languageNamespaces = ['common', 'veela-messages', 'formatted-values'];

export const VeelaMessagesPage = (): ReactElement => {
    const pageClasses = usePageStyles();
    const { t } = useTranslation(languageNamespaces);
    const { dispatch, store } = useGlobalStore();
    const [paginationState, setPaginationState] = useState<{ page: number; rowsPerPage: number }>({
        page: 0,
        rowsPerPage: 10,
    });
    const [exporting, setExporting] = useState<boolean>(false);
    const [sorting, setSorting] = useState<SortingRule<string> | null>(null);
    const [isSettingsDropdownChanged, setSettingsDropdownChanged] = useState<boolean>(false);
    const [propertyFilters, setPropertyFilters] = useState<DynamicPropertyMultiFilter[]>([]);
    const httpClient = useHttpClient();
    const debouncedSearchValue = useDebounce(store.insuranceConfirmationSearch.value);

    const { isLoading, data } = useGetVeelaMessages('veelaMessages', {
        searchTerm: debouncedSearchValue,
        skip: 10 * paginationState.page,
        take: paginationState.rowsPerPage,
        sortField: sorting?.id || null,
        isAscending: sorting?.desc === false,
        filters: propertyFilters,
    });

    const exportToExcel = async () => {
        setExporting(true);

        const dto: ExportVeelaMessagesRequestDto = {
            searchTerm: debouncedSearchValue,
            sortField: sorting?.id || null,
            isAscending: sorting?.desc === false,
            filters: propertyFilters,
        };

        await httpClient.downloadFile('veelaMessages/export', dto);
        setExporting(false);
    };

    const {
        isLoading: isGettingTableSettingsLoading,
        settings,
        requestTableSettings,
    } = useGetTableSettings({
        tableName: TableNames.VeelaMessages,
    });

    const { updateTableSettings } = useUpdateTableSettings({
        tableName: TableNames.VeelaMessages,
        onError: requestTableSettings,
    });

    const { visibilitySettingsList, hiddenColumns, handleColumnSettingsList } =
        useColumnVisibilityState({ settings, setSettingsDropdownChanged, t });

    useEffect(() => {
        if (typeof dispatch === 'function') {
            dispatch({
                type: GlobalStoreActions.updateConfirmationInsurance,
            });
        }
    }, [paginationState, debouncedSearchValue, sorting]);

    const onChangePage = (_: MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPaginationState({ ...paginationState, page });
    };

    const handleChangeRowsPerPage = (e: SyntheticEvent): void => {
        setPaginationState({
            rowsPerPage: parseInt((e.target as HTMLInputElement).value),
            page: 0,
        });
    };

    const handleSort = useCallback((sortBy?: SortingRule<string>): void => {
        setSorting(sortBy || null);
    }, []);

    const handleColumnSettingsListClose = (): void => {
        if (isSettingsDropdownChanged) {
            updateTableSettings(
                visibilitySettingsList
                    .map((item) => ({ name: item.value, isShown: item.checked }))
                    .sort(
                        (
                            a: Omit<TableColumnSettings, 'position'>,
                            b: Omit<TableColumnSettings, 'position'>
                        ) => {
                            const key = 'name';
                            const aPosition = getSortingPositions(
                                a,
                                VeelaMessagesDefaultOrderColumn,
                                key
                            );
                            const bPosition = getSortingPositions(
                                b,
                                VeelaMessagesDefaultOrderColumn,
                                key
                            );
                            return aPosition > bPosition ? 1 : -1;
                        }
                    )
            );
        }
    };

    const columns = useMemo(
        () =>
            generateVeelaMessagesTableConfig({
                t,
                onPropertyFilterChange: (propertyName, filters) => {
                    const newFilters = [
                        ...propertyFilters.filter((pf) => pf.propertyName !== propertyName),
                    ];
                    newFilters.push({ propertyName, filters });

                    setPropertyFilters(newFilters);
                },
                propertyFilters,
            }).sort((a: Column<VeelaMessage>, b: Column<VeelaMessage>) => {
                const key = 'id';
                const aPosition = getSortingPositions(a, VeelaMessagesDefaultOrderColumn, key);
                const bPosition = getSortingPositions(b, VeelaMessagesDefaultOrderColumn, key);
                return aPosition > bPosition ? 1 : -1;
            }),
        [t, propertyFilters]
    );

    return (
        <div className={pageClasses.pageContainer}>
            <div className={pageClasses.filterPanel}>
                <IconButton
                    disabled={exporting || isLoading || !data.items || data.items.length == 0}
                    className={pageClasses.settingButton}
                    onClick={exportToExcel}
                >
                    <SaveAltIcon />
                </IconButton>
                <ThemeCheckboxesDropdown<TableColumnSettings>
                    languageNamespaces={languageNamespaces}
                    itemsList={visibilitySettingsList}
                    buttonIcon={
                        isGettingTableSettingsLoading ? (
                            <CircularProgress isLoading={isGettingTableSettingsLoading} />
                        ) : (
                            <SettingsOutlinedIcon />
                        )
                    }
                    iconButtonClass={pageClasses.settingButton}
                    setItemsList={handleColumnSettingsList}
                    isButtonDisabled={isGettingTableSettingsLoading}
                    onCloseHandler={handleColumnSettingsListClose}
                />
            </div>
            {!isGettingTableSettingsLoading && (
                <CustomTable
                    withPagination
                    hiddenColumns={hiddenColumns}
                    columns={columns as any}
                    data={data.items}
                    isLoading={isLoading}
                    noDataMessage='veela-messages:noData'
                    languageNamespaces={languageNamespaces}
                    page={paginationState.page}
                    rowsPerPage={paginationState.rowsPerPage}
                    onChangePage={onChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    getRowId={(msg: VeelaMessage) => msg.header?.messageIds?.messageIds[0]}
                    count={data.total}
                    handleSort={handleSort}
                    sortField={sorting?.id}
                    sortDesc={sorting?.desc}
                    forcedMinWidth={10000}
                />
            )}
            {isGettingTableSettingsLoading && (
                <Grid container alignItems='center' justifyContent='center'>
                    <CircularProgress isLoading={isGettingTableSettingsLoading} />
                </Grid>
            )}
        </div>
    );
};
