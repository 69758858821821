import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { THEME_MODES } from 'src/theming/theme-modes';

export const useStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.mode;

    return {
        listMenuContainer: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            height: '100%',
        },
        menuTitle: {
            padding: theme.spacing(2),
            backgroundColor: isDarkMode
                ? theme.palette.secondary.main
                : theme.palette.secondary.light,
            color: theme.palette.primary.contrastText,
            borderBottom: `1px solid ${theme.palette.grey.A100}`,
        },
        activeListItem: {
            backgroundColor: isDarkMode
                ? `${theme.palette.primary.dark}!important`
                : `${theme.palette.secondary.main}!important`,
            '& .MuiTypography-root': {
                color: `${theme.palette.secondary.contrastText}!important`,
            },
            '& .MuiSvgIcon-root': {
                color: `${theme.palette.secondary.contrastText}!important`,
            },
        },
        pagination: {
            padding: `0!important`,
            '& .MuiTablePagination-toolbar': {
                padding: 0,
            },
        },
        noDataMessage: {
            fontSize: '1.4rem',
        },
        centeredContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            backgroundColor: theme.palette.primary.main,
        },
    };
});

export const useListStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            padding: 0,
            overflowY: 'auto',
            backgroundColor: theme.palette.primary.main,
        },
    };
});

export const useListItemStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.mode;
    return {
        root: {
            cursor: 'pointer',
            backgroundColor: theme.palette.primary.main,
            transition: '0.2s',
            padding: `${theme.spacing(2)}`,
            borderBottom: `1px solid ${theme.palette.grey.A100}`,
            '&:nth-child(even)': {
                backgroundColor: isDarkMode
                    ? theme.palette.primary.light
                    : theme.palette.secondary.light,
            },
            '&:hover': {
                opacity: '0.8',
                cursor: ({ isMenuListDisabled }: { isMenuListDisabled?: boolean }) =>
                    isMenuListDisabled ? 'cursor' : 'pointer',
                backgroundColor: isDarkMode
                    ? `${theme.palette.primary.dark}`
                    : `${theme.palette.secondary.light}`,
            },
        },
    };
});

export const useListItemTextStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            '& .MuiTypography-root': {
                fontSize: '1.2rem',
                color: theme.palette.primary.contrastText,
                textDecoration: 'underline',
            },
        },
    };
});

export const useListItemIconStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
                color: theme.palette.grey.A200,
            },
        },
    };
});
