import { SigningOffTableKeys } from './signing-off-table-config';

export const signingOffDefaultOrderColumn = {
    [SigningOffTableKeys.insuranceConfirmationNumber]: 0,
    [SigningOffTableKeys.contractNumber]: 1,
    [SigningOffTableKeys.customer]: 2,
    [SigningOffTableKeys.customerRelation]: 3,
    [SigningOffTableKeys.licenseNumber]: 4,
    [SigningOffTableKeys.contractStatus]: 5,
    [SigningOffTableKeys.result]: 6,
    [SigningOffTableKeys.date]: 7,
    [SigningOffTableKeys.startOfContract]: 8,
    [SigningOffTableKeys.owner]: 9,
    [SigningOffTableKeys.postcode]: 10,
    [SigningOffTableKeys.place]: 11,
    [SigningOffTableKeys.street]: 12,
    [SigningOffTableKeys.houseNumber]: 13,
};
