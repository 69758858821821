import { NewRegistrationTableKeys } from './new-registration-table-config';

export const newRegistrationDefaultOrderColumn = {
    [NewRegistrationTableKeys.insuranceConfirmationNumber]: 0,
    [NewRegistrationTableKeys.internalInsuranceNotes]: 1,
    [NewRegistrationTableKeys.contractNumber]: 2,
    [NewRegistrationTableKeys.customer]: 3,
    [NewRegistrationTableKeys.customerRelation]: 4,
    [NewRegistrationTableKeys.risk]: 5,
    [NewRegistrationTableKeys.result]: 6,
    [NewRegistrationTableKeys.date]: 7,
    [NewRegistrationTableKeys.licenseNumber]: 8,
    [NewRegistrationTableKeys.startOfContract]: 9,
    [NewRegistrationTableKeys.owner]: 10,
    [NewRegistrationTableKeys.postcode]: 11,
    [NewRegistrationTableKeys.street]: 12,
    [NewRegistrationTableKeys.place]: 13,
    [NewRegistrationTableKeys.houseNumber]: 14,
};
