import { useContext } from 'react';
import { ApiInstanceContext, ApiInstanceContextProps } from '.';

export const useApiInstance = (): ApiInstanceContextProps => {
    const context = useContext(ApiInstanceContext);
    if (context === null) {
        throw new Error(
            'ApiInstanceContext.Provider is not set in the React component tree. You should use ApiInstanceProvider as a parent component'
        );
    }

    return context;
};
