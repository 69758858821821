export enum ChangeType {
    Address = 0x001,
    Holder = 0x002,
    Renewal = 0x004,
    InsuranceNumber = 0x008,
    InsuranceNumberAndVu = 0x010,
    LicenseNumberExtended = 0x020,
    LicenseNumberSeason = 0x040,
    LicenseNumber = 0x080,
    VehicleClass = 0x100,
    Oldtimer = 0x200,
    Usage = 0x400,
    Eletric = 0x800,
}
