import { TextField } from '@mui/material';
import { OverflowTooltip } from '../overflow-tooltip';
import { DataKeyProperties } from './category-data-keys';
import { StringObjectPropertyIndex } from './stringObjectPropertyIndex';
import React, { ReactElement } from 'react';
import { CustomerAutocomplete } from './customer-autocomplete';
import { CustomerData } from 'src/shared/types/data-preparing';

interface EditableDataValueCellProps {
    dataKey: DataKeyProperties;
    data: StringObjectPropertyIndex | undefined;
    onValueChanging(
        dataKey: DataKeyProperties,
        data: StringObjectPropertyIndex,
        newValue: string
    ): void;
}

export function EditableDataValueCell({
    dataKey,
    data,
    onValueChanging,
}: EditableDataValueCellProps): ReactElement {
    if (!data) {
        return <React.Fragment />;
    }

    const localValue = data[dataKey.key];

    if (dataKey.readonly) {
        if (dataKey.dataType === 'customer') {
            return (
                <OverflowTooltip
                    label={'localValue'}
                    dataForTooltip={(localValue as CustomerData).customer1}
                >
                    <span
                        style={{
                            display: 'inline-block',
                            maxWidth: '100%',
                            verticalAlign: 'middle',
                        }}
                    >
                        {(localValue as CustomerData).customer1}
                    </span>
                </OverflowTooltip>
            );
        } else {
            return (
                <OverflowTooltip label={'localValue'} dataForTooltip={localValue}>
                    <span
                        style={{
                            display: 'inline-block',
                            maxWidth: '100%',
                            verticalAlign: 'middle',
                        }}
                    >
                        {localValue}
                    </span>
                </OverflowTooltip>
            );
        }
    }

    if (dataKey.dataType === 'string') {
        return (
            <TextField
                variant='outlined'
                size='small'
                id={dataKey.key}
                value={localValue}
                style={{
                    maxWidth: '100%',
                    verticalAlign: 'middle',
                }}
                onChange={(e) => onValueChanging(dataKey, data, e.currentTarget.value)}
            />
        );
    }
    if (dataKey.dataType === 'date') {
        return (
            <TextField
                variant='outlined'
                size='small'
                type='date'
                id={dataKey.key}
                value={localValue}
                style={{
                    maxWidth: '100%',
                    verticalAlign: 'middle',
                }}
                onChange={(e) => onValueChanging(dataKey, data, e.currentTarget.value)}
            />
        );
    }
    if (dataKey.dataType === 'customer') {
        return (
            <CustomerAutocomplete
                dataKey={dataKey}
                data={data}
                selected={localValue as CustomerData}
                onValueChanging={onValueChanging}
            />
        );
    }
    if (dataKey.dataType === 'number') {
        return (
            <TextField
                variant='outlined'
                size='small'
                id={dataKey.key}
                value={localValue}
                style={{
                    maxWidth: '100%',
                    verticalAlign: 'middle',
                }}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*[.,][0-9]*' }}
                onChange={(e) => onValueChanging(dataKey, data, e.currentTarget.value)}
            />
        );
    }

    return <React.Fragment />;
}
