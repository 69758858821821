import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { THEME_MODES } from 'src/theming/theme-modes';

type PropertyType = {
    isRowClickable?: boolean;
};

export const useCustomTableStyles = makeStyles((theme: Theme) => {
    return {
        noDataRow: {
            width: '100%',
            height: 100,
            display: 'flex',
            '& td': {
                width: '100%',
                justifyContent: 'center',
                userSelect: 'none',
            },
        },
        activeRow: {
            backgroundColor: `${theme.palette.info.main}!important`,
        },
    };
});

export const useTableContainerStyles = makeStyles((theme: Theme) => {
    const isDarkMode = theme.palette.mode === THEME_MODES.DARK;
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            border: !isDarkMode
                ? `1px solid ${theme.palette.secondary.light}`
                : `1px solid ${theme.palette.secondary.main}`,
            '&::-webkit-scrollbar': {
                height: '20px',
            },
        },
    };
});

export const useTableRootContainerStyle = makeStyles(() => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
        },
    };
});

export const useTableStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            minWidth: '1920px',
            flexGrow: 1,

            '& .MuiTableCell-root': {
                paddingTop: 12,
                paddingBottom: 12,
                paddingLeft: theme.spacing(2),
                paddingRight: 0,
                display: 'flex',
                alignItems: 'center',
                '& .MuiTableSortLabel-icon': {
                    margin: 0,
                },
                [theme.breakpoints.down('xl')]: {
                    fontSize: '1.4rem',
                },
            },
            '& .MuiTableCell-head': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.info.contrastText,
                fontWeight: theme.typography.fontWeightMedium,

                '& .MuiTableSortLabel-root': {
                    width: '100%',
                    display: 'flex',
                    color: theme.palette.secondary.contrastText,
                    '&>div': {
                        width: 'calc(100% - 15px)',
                        maxWidth: 'fit-content',
                    },
                    '&>svg': {
                        '&::after': {
                            content: '""',
                            flexGrow: 1,
                        },
                    },
                    '& .MuiSvgIcon-root': {
                        color: theme.palette.secondary.contrastText,
                    },
                    '&:hover': {
                        color: theme.palette.secondary.contrastText,
                        opacity: 0.8,
                    },
                    '&.Mui-active': {
                        color: theme.palette.secondary.contrastText,
                    },
                },
                '& .MuiTypography-root': {
                    fontWeight: theme.typography.fontWeightBold,
                },
                '&:hover div': {
                    color: theme.palette.secondary.contrastText,
                },
            },
        },
    };
});

export const useTableHeaderStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            height: 45,
            width: '100%',
            '& .MuiTableCell-root': {
                '& .MuiCheckbox-root .MuiSvgIcon-root': {
                    color: theme.palette.primary.main,
                },
                '&:first-child': {},
            },
            '& .MuiTableRow-root': {
                height: 46,
            },
        },
    };
});

export const useTableBodyStyle = makeStyles((theme: Theme) => {
    const isDarkMode = theme.palette.mode === THEME_MODES.DARK;
    return {
        root: {
            display: 'block',
            width: '100%',
            maxHeight: '60vh',
            overflowX: 'auto',
            '& .MuiTableRow-root': {
                transition: '0.2s',
                whiteSpace: 'nowrap',
                backgroundColor: theme.palette.primary.main,
                cursor: ({ isRowClickable }: PropertyType) =>
                    isRowClickable ? 'pointer' : 'default',
                '&:nth-child(even)': {
                    backgroundColor: isDarkMode
                        ? theme.palette.info.main
                        : theme.palette.secondary.light,
                },
                '&:hover': {
                    opacity: ({ isRowClickable }: PropertyType) => {
                        return isRowClickable ? 0.8 : 1;
                    },
                    backgroundColor: ({ isRowClickable }: PropertyType) =>
                        isRowClickable ? `${theme.palette.secondary.light}!important` : 'default',
                },
            },
            '& .MuiTableCell-root': {
                paddingTop: 7,
                paddingBottom: 7,
                '& .MuiCheckbox-root .MuiSvgIcon-root': {
                    color: isDarkMode
                        ? theme.palette.secondary.contrastText
                        : theme.palette.secondary.main,
                },
            },
        },
    };
});

export const useTableRowStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            backgroundColor: theme.palette.primary.dark,
            height: 32,
        },
    };
});

export const useClasses = makeStyles((theme: Theme) => {
    return {
        root: {
            color: theme.palette.info.contrastText,
            width: 20,
            height: 20,
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
            },
        },
        checked: {
            color: `${theme.palette.secondary.main}!important`,
        },
    };
});
