import { useEffect, useState } from 'react';
import { HttpClient } from 'src/lib/http-client/http-client';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { SelectableItem } from 'src/shared/types';
import { CellWithFilterDropDown } from '../cell-with-filter-drop-down/CellWithFilterDropDown';

interface CellWithBackendFilterDropDownProps {
    makeRequest: (
        httpClient: HttpClient,
        url: string,
        searchTerm: string
    ) => Promise<SelectableItem[]>;
    url: string;
    name: string;
    onChange: (name: string, checkedValues: string[]) => void;
    title?: string;
    namespaces?: string[];
    listLoading?: boolean;
    initialCheckedValues?: string[];
    titleFormatter?: (value: string | string[]) => string;
    valueFormatter?: (value: string | string[]) => string;
}

export const CellWithBackendFilterDropDown = (props: CellWithBackendFilterDropDownProps) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dropdownList, setDropdownList] = useState<SelectableItem[]>([]);
    const [checkedValues, setCheckedValues] = useState<string[]>();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const httpClient = useHttpClient();

    useEffect(() => {
        const load = async () => {
            const newItems = await props.makeRequest(httpClient, props.url, searchTerm);
            setDropdownList(newItems);
        };

        if (isDialogOpen) {
            load();
        }
    }, [props.url, searchTerm, httpClient, isDialogOpen]);

    useEffect(() => {
        if (checkedValues != null) {
            return;
        }

        setCheckedValues(props.initialCheckedValues);
    }, [checkedValues, props.initialCheckedValues]);

    return (
        <CellWithFilterDropDown
            name={props.name}
            shouldShowAll={true}
            onChange={(_, checkedValues) => setCheckedValues(checkedValues)}
            title={props.title}
            namespaces={props.namespaces}
            listLoading={props.listLoading}
            afterCloseCallback={() => props.onChange(props.name, checkedValues ?? [])}
            dropdownList={dropdownList}
            checkedValues={checkedValues ?? []}
            applyFilters={() => props.onChange(props.name, checkedValues ?? [])}
            withSearch
            onChangeSearch={(_, value) => setSearchTerm(value)}
            searchName={props.name + '_search'}
            searchValue={searchTerm}
            onVisibilityChange={setIsDialogOpen}
            valueFormatter={props.valueFormatter}
        />
    );
};
