import React, { MouseEvent } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { List, ListItem, ListItemText, ListItemIcon, Typography } from '@mui/material';
import { CSSProperties } from '@mui/styles';

import { Pagination, Loader } from '..';

import {
    useStyle,
    useListStyle,
    useListItemStyle,
    useListItemTextStyle,
    useListItemIconStyle,
} from './ListMenuStyles';

export interface ListMenuItem {
    id: string;
    text: string;
    icon?: JSX.Element;
    style?: CSSProperties;
}

interface ListMenuProps {
    height: number;
    list: ListMenuItem[];
    setActiveItem: (activeItem: string) => void;
    page: number;
    handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    handleChangePage: (event: MouseEvent<HTMLButtonElement> | null, page: number) => void;
    noDataMessage: string;
    count: number;
    rowsPerPage: number;
    menuTitle?: string;
    activeItem?: string;
    isMenuListDisabled?: boolean;
    langNs?: string[];
    isLoading?: boolean;
}

const rowsPerPageOptions = [
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
];

export const ListMenu = (props: ListMenuProps): JSX.Element => {
    const {
        langNs,
        list,
        menuTitle,
        activeItem,
        setActiveItem,
        page,
        rowsPerPage,
        count,
        handleChangeRowsPerPage,
        handleChangePage,
        noDataMessage,
        isMenuListDisabled,
        height,
        isLoading,
    } = props;
    const { t } = useTranslation(langNs);
    const classes = useStyle();
    const listClasses = useListStyle();
    const listItemClasses = useListItemStyle({ isMenuListDisabled });
    const listItemTextClasses = useListItemTextStyle();
    const listItemIconClasses = useListItemIconStyle();

    return (
        <div className={classes.listMenuContainer}>
            {menuTitle && (
                <Typography variant='h6' className={classes.menuTitle}>
                    {t(menuTitle)}
                </Typography>
            )}
            {(!list || list.length === 0) && !isLoading && (
                <div className={classes.centeredContainer}>
                    <Typography align='center' variant='body1' className={classes.noDataMessage}>
                        {t(noDataMessage)}
                    </Typography>
                </div>
            )}
            {isLoading && (
                <div className={classes.centeredContainer}>
                    <Loader />
                </div>
            )}
            {Array.isArray(list) && list.length && !isLoading ? (
                <List dense classes={listClasses} style={{ height }}>
                    {list.map((item: ListMenuItem) => {
                        return (
                            <ListItem
                                disabled={isMenuListDisabled}
                                key={item.id}
                                classes={listItemClasses}
                                className={clsx({
                                    [classes.activeListItem]: activeItem === item.id,
                                })}
                                onClick={
                                    !isMenuListDisabled ? () => setActiveItem(item.id) : undefined
                                }
                            >
                                {item.icon && (
                                    <ListItemIcon classes={listItemIconClasses}>
                                        {item.icon}
                                    </ListItemIcon>
                                )}
                                <ListItemText classes={listItemTextClasses} style={item.style}>
                                    {item.text}
                                </ListItemText>
                            </ListItem>
                        );
                    })}
                </List>
            ) : null}
            <Pagination
                withoutMinMaxButtons
                paginationClassName={classes.pagination}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPageOptions={rowsPerPageOptions}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    );
};
