import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useHeaderStyle = makeStyles((theme: Theme) => {
    return {
        header: {
            position: 'relative',
            display: 'flex',
            flexWrap: 'nowrap',
            width: '100%',
            height: 100,
            alignItems: 'center',
            flexDirection: 'row',
            overflow: 'hidden',
            [theme.breakpoints.down('xl')]: {
                height: 70,
            },
        },
        logoContainer: {
            display: 'flex',
            alignItems: 'center',
            width: 250,
        },
        switcherContainer: {
            width: 'fit-content',
            margin: `0 ${theme.spacing(5)}`,
        },
        logo: {
            cursor: 'pointer',
        },
    };
});
