import { AnyAbility, AbilityBuilder, createMongoAbility } from '@casl/ability';
import { Roles } from 'src/shared/constants';
import { AbilityActions, AbilitySubjects } from './constants';

export const updateAbilityInstance = (ability: AnyAbility, roles: string[]): void => {
    if (!Array.isArray(roles) || !roles.length) {
        return;
    }

    const { can, rules } = new AbilityBuilder(createMongoAbility);

    can(AbilityActions.use, AbilitySubjects.abilities);

    if (roles.includes(Roles.administrator)) {
        can([AbilityActions.use, AbilityActions.see], AbilitySubjects.userManagement);
    }

    if (roles.includes(Roles.clerk)) {
        can([AbilityActions.use, AbilityActions.see], AbilitySubjects.mainPortal);
    }

    ability.update(rules);
};
