import React, { MouseEvent, useEffect, useMemo } from 'react';
import {
    Column,
    Row,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
    useFlexLayout,
    SortingRule,
} from 'react-table';
import { useTranslation } from 'react-i18next';

import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    TableContainer,
} from '@mui/material';

import { CircularProgress, Pagination } from 'src/components/base';

import {
    useClasses,
    useTableBodyStyle,
    useTableRowStyle,
    useTableStyle,
    useCustomTableStyles,
    useTableContainerStyles,
    useTableHeaderStyle,
    useTableRootContainerStyle,
} from './custom-table-styles';

interface CustomTableProps {
    columns: ReadonlyArray<Column<object>>;
    data: object[];
    isLoading: boolean;
    tableBodyClassName?: string;
    noDataMessage?: string;
    onRowClick?: (e: MouseEvent<HTMLTableRowElement>, value: Row<object>) => void;
    tableHeaderClassName?: string;
    languageNamespaces?: string[];
    handleSort?: (sortBy?: SortingRule<string>) => void;
    sortField?: string | null;
    sortDesc?: boolean | null;
    selectionEnabled?: boolean;
    handleRemoveAllRows?: () => void;
    removeRowById?: (id: string) => void;
    activeRow?: string;
    onlyOneRowSelection?: boolean;
    handleRowSelection?: (value: (Row<object> | undefined)[]) => void;
    getRowId?: (row: object) => string;
    withPagination?: boolean;
    onChangePage?: (event: MouseEvent<HTMLButtonElement> | null, page: number) => void;
    handleChangeRowsPerPage?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    count?: number;
    page?: number;
    rowsPerPage?: number;
    hiddenColumns?: string[];
    forcedMinWidth?: number;
}

export function CustomTable({
    columns,
    data,
    isLoading,
    tableHeaderClassName,
    tableBodyClassName,
    noDataMessage,
    onRowClick,
    languageNamespaces,
    handleSort,
    activeRow,
    handleRowSelection,
    getRowId,
    withPagination,
    onChangePage,
    handleChangeRowsPerPage,
    count,
    page,
    rowsPerPage,
    selectionEnabled,
    sortField,
    sortDesc,
    hiddenColumns,
    forcedMinWidth,
}: CustomTableProps): JSX.Element {
    const classes = useCustomTableStyles();
    const checkClasses = useClasses();

    const tableHeaderClasses = useTableHeaderStyle();

    const tableClasses = useTableStyle();
    const tableContainerClasses = useTableContainerStyles();
    const rootContainerClasses = useTableRootContainerStyle();
    const tableBodyClasses = useTableBodyStyle({
        isRowClickable: Boolean(onRowClick && Array.isArray(data) && data.length > 0) && !isLoading,
    });
    const tableRowClasses = useTableRowStyle();

    const { t } = useTranslation(languageNamespaces);
    const [selectedIds, setSelectedIds] = React.useState<readonly string[]>([]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state: { sortBy },
    } = useTable(
        {
            columns,
            data,
            getRowId,
            manualSortBy: true,
            useControlledState: (state) => {
                return useMemo(() => {
                    return {
                        ...state,
                        hiddenColumns: hiddenColumns || state.hiddenColumns,
                        pageIndex: page || state.pageIndex,
                        pageSize: rowsPerPage || state.pageSize,
                    };
                }, [state, page, rowsPerPage, hiddenColumns]);
            },
            initialState: {
                hiddenColumns,
                pageIndex: page,
                pageSize: rowsPerPage,
                sortBy:
                    sortField && typeof sortDesc === 'boolean'
                        ? [{ id: sortField, desc: sortDesc }]
                        : [],
            },
        },
        useSortBy,
        usePagination,
        useRowSelect,
        useFlexLayout
    );

    useEffect(() => {
        if (Array.isArray(sortBy) && typeof handleSort === 'function') {
            handleSort(sortBy[0]);
        }
    }, [sortBy, handleSort]);

    useEffect(() => {
        const selectedRows = rows.filter((x) => selectedIds.includes(x.id));
        console.log(selectedRows);
        if (Array.isArray(selectedRows) && typeof handleRowSelection === 'function') {
            handleRowSelection(selectedRows);
        }
    }, [selectedIds]);

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((row) => row.id);
            setSelectedIds(newSelected);
            return;
        }
        setSelectedIds([]);
    };

    const handleSelectSingleClick = (id: string) => {
        const selectedIndex = selectedIds.indexOf(id);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedIds, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedIds.slice(1));
        } else if (selectedIndex === selectedIds.length - 1) {
            newSelected = newSelected.concat(selectedIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedIds.slice(0, selectedIndex),
                selectedIds.slice(selectedIndex + 1)
            );
        }

        setSelectedIds(newSelected);
    };

    const isSelected = (id: string) => selectedIds.indexOf(id) !== -1;

    return (
        <div className={rootContainerClasses.root}>
            <TableContainer classes={tableContainerClasses}>
                <Table
                    classes={tableClasses}
                    {...getTableProps()}
                    style={forcedMinWidth ? { minWidth: forcedMinWidth } : undefined}
                >
                    <TableHead classes={tableHeaderClasses} className={tableHeaderClassName}>
                        {headerGroups.map((headerGroup) => {
                            const headerRowProps = headerGroup.getHeaderGroupProps();
                            return (
                                <TableRow
                                    {...headerRowProps}
                                    key={headerRowProps.key}
                                    classes={tableRowClasses}
                                >
                                    {selectionEnabled && (
                                        <TableCell padding='checkbox'>
                                            <Checkbox
                                                color='primary'
                                                classes={checkClasses}
                                                indeterminate={
                                                    selectedIds.length > 0 &&
                                                    selectedIds.length < rows.length
                                                }
                                                checked={
                                                    rows.length > 0 &&
                                                    selectedIds.length === rows.length
                                                }
                                                onChange={handleSelectAllClick}
                                                inputProps={{
                                                    'aria-label': 'select all',
                                                }}
                                            />
                                        </TableCell>
                                    )}
                                    {headerGroup.headers.map((column) => {
                                        if (column.disableSortBy) {
                                            return (
                                                <TableCell
                                                    {...column.getHeaderProps(
                                                        column.getSortByToggleProps({
                                                            title: t('common:sorting'),
                                                        })
                                                    )}
                                                    key={column.id}
                                                >
                                                    {column.render('Header')}
                                                </TableCell>
                                            );
                                        }
                                        return (
                                            <TableCell
                                                {...column.getHeaderProps(
                                                    column.getSortByToggleProps({
                                                        title: t('common:sorting'),
                                                    })
                                                )}
                                                key={column.id}
                                            >
                                                <TableSortLabel
                                                    style={{ height: '100%', display: 'flex' }}
                                                    active={column.isSorted}
                                                    direction={
                                                        column.isSorted
                                                            ? column.isSortedDesc
                                                                ? 'desc'
                                                                : 'asc'
                                                            : 'asc'
                                                    }
                                                >
                                                    {column.render('Header')}
                                                </TableSortLabel>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableHead>
                    <TableBody
                        classes={tableBodyClasses}
                        className={tableBodyClassName}
                        {...getTableBodyProps()}
                    >
                        {!isLoading &&
                            (!Array.isArray(data) || data.length === 0) &&
                            noDataMessage && (
                                <TableRow className={classes.noDataRow}>
                                    <TableCell align='center'>
                                        <Typography variant='body2'>{t(noDataMessage)}</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        {isLoading && (
                            <TableRow className={classes.noDataRow}>
                                <TableCell>
                                    <CircularProgress isLoading={isLoading} />
                                </TableCell>
                            </TableRow>
                        )}
                        {!isLoading &&
                            rows.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                prepareRow(row);
                                const rowOriginal = row.original as {
                                    id: string;
                                    notSelectable: boolean;
                                };
                                return (
                                    <TableRow
                                        {...row.getRowProps()}
                                        classes={tableRowClasses}
                                        aria-checked={isItemSelected}
                                        className={
                                            activeRow != null &&
                                            getRowId(row.original) === activeRow
                                                ? classes.activeRow
                                                : ''
                                        }
                                        key={row.id}
                                        onClick={
                                            onRowClick && !rowOriginal.notSelectable
                                                ? (e) => onRowClick(e, row)
                                                : undefined
                                        }
                                    >
                                        {selectionEnabled && (
                                            <TableCell padding='checkbox'>
                                                <Checkbox
                                                    color='primary'
                                                    classes={checkClasses}
                                                    checked={isItemSelected}
                                                    onClick={() => handleSelectSingleClick(row.id)}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                        )}

                                        {row.cells.map((cell) => {
                                            return (
                                                <TableCell
                                                    {...cell.getCellProps()}
                                                    key={cell.column.id + cell.row.id}
                                                >
                                                    {cell.render('Cell')}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {withPagination && onChangePage && handleChangeRowsPerPage && (
                <Pagination
                    count={count || 0}
                    page={page || 0}
                    rowsPerPage={rowsPerPage || 25}
                    onChangePage={(x, y) => {
                        setSelectedIds([]);
                        onChangePage(x, y);
                    }}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
        </div>
    );
}

CustomTable.defaultProps = {
    selectionEnabled: false,
    hiddenColumns: [],
};
