import React from 'react';

import { AuthLayout } from 'src/components/layouts';
import { CreatePasswordForm } from 'src/components/forms';

export const CreatePasswordPage = (): JSX.Element => {
    return (
        <AuthLayout>
            <CreatePasswordForm />
        </AuthLayout>
    );
};
