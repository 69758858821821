import { createContext } from 'react';
import { LoggedInUserDto } from '.';

export interface LoginContextProps {
    login: (email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    user: LoggedInUserDto | null;
    fetchUserData: () => Promise<LoggedInUserDto>;
    storeUserData: (userData: LoggedInUserDto) => void;
}

export const LoginContext = createContext<LoginContextProps | null>(null);
