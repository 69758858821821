import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, FormHelperText, useTheme } from '@mui/material';

import { THEME_MODES } from 'src/theming/theme-modes';

import {
    useSeparateLabelInputWrapperFormControlStyles,
    useFormHelperText,
} from './SeparateLabelInputWrapperStyles';

interface SeparateLabelInputWrapperProps {
    id: string;
    children: ReactElement;
    required?: boolean;
    label?: string;
    color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    helperText?: string;
    languageNamespaces?: string[];
    error?: boolean;
    disabled?: boolean;
    placement?: 'top' | 'bottom' | 'right' | 'left';
}

export const SeparateLabelInputWrapper = ({
    id,
    children,
    color,
    label,
    required,
    helperText,
    languageNamespaces,
    error,
    disabled,
    placement,
}: SeparateLabelInputWrapperProps): ReactElement => {
    const theme = useTheme();
    const isDarkMode = THEME_MODES.DARK === theme.palette.mode;
    const helperTextClasses = useFormHelperText();
    const formControlClasses = useSeparateLabelInputWrapperFormControlStyles({
        placement,
    });

    const { t } = useTranslation(languageNamespaces);

    return (
        <FormControl disabled={disabled} color={color} classes={formControlClasses}>
            <label htmlFor={id}>
                {t(label)}
                {required ? (
                    <span
                        style={{
                            color: isDarkMode
                                ? theme.palette.primary.contrastText
                                : theme.palette.error.main,
                        }}
                    >
                        {' '}
                        *
                    </span>
                ) : null}
            </label>
            {children}
            {helperText && (
                <FormHelperText error={error} classes={helperTextClasses}>
                    {t(helperText)}
                </FormHelperText>
            )}
        </FormControl>
    );
};

SeparateLabelInputWrapper.defaultProps = {
    color: 'secondary',
    variant: 'outlined',
    required: false,
    error: false,
    disabled: false,
    placement: 'top',
};
