import { lazy } from 'react';
import { AppRoutes } from 'src/routing/app-routes';

import {
    LoginPage,
    ForgotPasswordPage,
    NewRegistrationPage,
    SigningOffPage,
    ChangePage,
    CreatePasswordPage,
} from 'src/pages';
import { AbilitySubjects } from 'src/security/roleAccesses';
import { MainLayout } from 'src/components/layouts';
import { RouteItem } from './route-item';
import { withAuthValidation, withRoleValidation } from './validators';
import { VeelaMessagesPage } from 'src/pages/raw-veela-messages/raw-veela-messages-page';

const UserManagement = lazy(() => import('src/pages/user-management'));

interface routingConfigParams {
    isAuth: boolean;
    homePath: string;
}

export const routingConfig = ({ isAuth, homePath }: routingConfigParams): RouteItem[] => {
    return [
        {
            component: LoginPage,
            path: AppRoutes.LOGIN,
        },
        {
            component: ForgotPasswordPage,
            path: AppRoutes.FORGOT_PASSWORD,
        },
        {
            component: CreatePasswordPage,
            path: AppRoutes.CREATE_PASSWORD,
        },
        {
            component: UserManagement,
            path: AppRoutes.USER_MANAGEMENT,
            validators: [
                withAuthValidation(isAuth),
                withRoleValidation(AbilitySubjects.userManagement, homePath),
            ],
        },
        {
            component: MainLayout,
            path: AppRoutes.ROOT,
            validators: [
                withAuthValidation(isAuth),
                withRoleValidation(AbilitySubjects.mainPortal, homePath),
            ],
            children: [
                {
                    component: ChangePage,
                    path: AppRoutes.CHANGE,
                },
                {
                    component: NewRegistrationPage,
                    path: AppRoutes.NEW_REGISTRATION,
                },
                {
                    component: SigningOffPage,
                    path: AppRoutes.SIGNING_OFF,
                },
                {
                    component: VeelaMessagesPage,
                    path: AppRoutes.GDV_MESSAGES,
                },
            ],
        },
    ];
};
