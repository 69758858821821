import React, {
    MouseEvent,
    ReactElement,
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Row, SortingRule } from 'react-table';
import clsx from 'clsx';

import { Box, Button, Grid, IconButton } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

import { withDndContext } from 'src/shared/hocs';
import {
    CircularProgress,
    CustomTable,
    ThemeCheckboxesDropdown,
    ToggleButtons,
} from 'src/components/base';
import { FilterValues, GlobalStoreActions, TableNames } from 'src/shared/constants';
import { generateButtonFilterValues, getRowId, getSortingPositions } from 'src/shared/utils';
import {
    useDearchiveEntities,
    useDownloadEntity,
    useGetEntityItems,
    useGetTableSettings,
    useProcessEntity,
    useUpdateTableSettings,
} from 'src/shared/hooks';
import { ChangeProcessResult, ChangesItem, TableColumnSettings } from 'src/shared/types';
import { useDebounce } from 'src/lib/custom-hooks/use-debounce';
import { usePageStyles } from '../pages-styles';
import { generateChangeTableConfig } from './change-table-config';
import { useColumnVisibilityState } from './hooks/use-column-visibility-state';
import { useColumnDnd } from '../signing-off/hooks/use-column-dnd';
import { changeTableDefaultOrderColumn } from './change-table-default-columns-order';
import { useGlobalStore } from 'src/shared/contexts';
import { useApiInstance } from 'src/security/api-instance';

const languageNamespaces = ['common', 'change', 'formatted-values'];

const TableWithColumnDnd = withDndContext(CustomTable);

export const ChangePage = (): ReactElement => {
    const pageClasses = usePageStyles();
    const apiInstance = useApiInstance();
    const { t } = useTranslation(languageNamespaces);
    const { dispatch, store } = useGlobalStore();
    const [filterValue, setFilterValue] = useState<string>(FilterValues.NEW);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [canProcess, setCanProcess] = useState<boolean>(false);
    const [paginationState, setPaginationState] = useState<{ page: number; rowsPerPage: number }>({
        page: 0,
        rowsPerPage: 10,
    });
    const [sorting, setSorting] = useState<SortingRule<string> | null>(null);
    const [isDndLocked, setDndLocked] = useState<boolean>(true);
    const [isSettingsDropdownChanged, setSettingsDropdownChanged] = useState<boolean>(false);

    const debouncedSearchValue = useDebounce(store.insuranceConfirmationSearch.value);

    const { isLoading, data, getEntities } = useGetEntityItems<ChangesItem>({
        searchTerm: debouncedSearchValue,
        page: paginationState.page,
        rowsPerPage: paginationState.rowsPerPage,
        sortField: sorting?.id || null,
        sortDesc: typeof sorting?.desc === 'boolean' ? sorting?.desc : null,
        processed: filterValue === FilterValues.ARCHIVED,
        url: 'insuranceConfirmationChanges',
    });

    const { isLoading: isDownloadLoading, download } = useDownloadEntity({
        insuranceConfirmationIds: selectedItems,
        url: 'downloadInsuranceConfirmationChanges',
    });

    const { isLoading: isDearchiveLoading, dearchive } = useDearchiveEntities({
        insuranceConfirmationIds: selectedItems,
        url: 'dearchiveInsuranceConfirmationChanges',
        onSuccess: getEntities,
    });

    const {
        isLoading: isGettingTableSettingsLoading,
        settings,
        requestTableSettings,
    } = useGetTableSettings({
        tableName: TableNames.Changes,
    });

    const { isLoading: isUpdateSettingsLoading, updateTableSettings } = useUpdateTableSettings({
        tableName: TableNames.Changes,
        onError: requestTableSettings,
    });

    const { visibilitySettingsList, hiddenColumns, handleColumnSettingsList } =
        useColumnVisibilityState({ settings, setSettingsDropdownChanged });

    useEffect(() => {
        if (typeof dispatch === 'function') {
            dispatch({
                type: GlobalStoreActions.updateConfirmationInsurance,
            });
        }
    }, [paginationState, debouncedSearchValue, sorting, filterValue]);

    useEffect(() => {
        if (paginationState.page * paginationState.rowsPerPage > data.total) {
            setPaginationState({
                page: 0,
                rowsPerPage: paginationState.rowsPerPage,
            });
        }
    }, [data]);

    const dragEndCallback = (columnPositions: { [key: string]: number }): void => {
        updateTableSettings(
            visibilitySettingsList
                .map((item) => ({ name: item.value, isShown: item.checked }))
                .sort(
                    (
                        a: Omit<TableColumnSettings, 'position'>,
                        b: Omit<TableColumnSettings, 'position'>
                    ) => {
                        const key = 'name';
                        const aPosition = getSortingPositions(a, columnPositions, key);
                        const bPosition = getSortingPositions(b, columnPositions, key);
                        return aPosition > bPosition ? 1 : -1;
                    }
                )
        );
    };

    const { onDragStart, onDragEnd, onDragUpdate, startPosition, hoverPosition, columnPositions } =
        useColumnDnd({
            settings,
            dragEndCallback,
            defaultState: changeTableDefaultOrderColumn,
        });

    const onProcessSuccess = (): void => {
        getEntities();
        setSelectedItems([]);
        setCanProcess(false);
    };

    const { isLoading: isProcessLoading, process } = useProcessEntity({
        insuranceConfirmationIds: selectedItems,
        onSuccess: onProcessSuccess,
        onInsuranceConfirmationChanged: getEntities,
        processUrl: 'processInsuranceConfirmationChanges',
        downloadUrl: 'downloadInsuranceConfirmationChanges',
    });

    const handleChange = (_: SyntheticEvent, newFilter: string): void => {
        if (newFilter) {
            setFilterValue(newFilter);
        }
    };

    const onChangePage = useCallback((_: MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPaginationState({ ...paginationState, page });
    }, []);

    const handleChangeRowsPerPage = useCallback((e: SyntheticEvent): void => {
        setPaginationState({
            rowsPerPage: parseInt((e.target as HTMLInputElement).value),
            page: 0,
        });
    }, []);

    const handleRowSelection = useCallback((value: (Row<object> | undefined)[]): void => {
        const validItems = value.filter((x) => x?.original != null);

        const processAllowed = validItems
            .map((x) => x.original as { result: ChangeProcessResult })
            .filter(
                (x) =>
                    x.result === ChangeProcessResult.ReadyToProcess ||
                    x.result === ChangeProcessResult.NoProcessing
            );

        const selectable = value
            .filter((x) => x.original != null)
            .map((x) => x.original as { id: string; result: ChangeProcessResult })
            .filter((x) => x.result !== ChangeProcessResult.Open)
            .map((x) => x.id);

        setSelectedItems(selectable);
        setCanProcess(processAllowed.length !== 0);
    }, []);

    const handleSort = useCallback((sortBy?: SortingRule<string>): void => {
        setSorting(sortBy || null);
    }, []);

    const onDownload = (): void => {
        download();
    };

    const onBackToOpen = (): void => {
        dearchive();
    };

    const handleColumnSettingsListClose = (): void => {
        if (isSettingsDropdownChanged) {
            updateTableSettings(
                visibilitySettingsList
                    .map((item) => ({ name: item.value, isShown: item.checked }))
                    .sort(
                        (
                            a: Omit<TableColumnSettings, 'position'>,
                            b: Omit<TableColumnSettings, 'position'>
                        ) => {
                            const key = 'name';
                            const aPosition = getSortingPositions(a, columnPositions, key);
                            const bPosition = getSortingPositions(b, columnPositions, key);
                            return aPosition > bPosition ? 1 : -1;
                        }
                    )
            );
        }
    };

    const handleResetSettings = (): void => {
        (async () => {
            await updateTableSettings([]);
            requestTableSettings();
        })();
    };

    const buttonsList = useMemo(() => {
        return generateButtonFilterValues(t);
    }, [t]);

    const columns = useMemo(
        () =>
            generateChangeTableConfig({
                t,
                startPosition,
                hoverPosition,
                columnPositions,
                isDndLocked,
                onProcessDialogClose: () => {
                    if (typeof dispatch === 'function') {
                        dispatch({
                            type: GlobalStoreActions.updateConfirmationInsurance,
                        });
                    }
                },
            }).sort((a: Column<object>, b: Column<object>) => {
                const key = 'id';
                const aPosition = getSortingPositions(a, columnPositions, key);
                const bPosition = getSortingPositions(b, columnPositions, key);
                return aPosition > bPosition ? 1 : -1;
            }),
        [t, startPosition, hoverPosition, columnPositions, isDndLocked]
    );

    return (
        <div className={pageClasses.pageContainer}>
            <div className={pageClasses.filterPanel}>
                {!isDndLocked && (
                    <Box mr={2}>
                        <Button
                            startIcon={
                                <CircularProgress
                                    isLoading={
                                        isGettingTableSettingsLoading || isUpdateSettingsLoading
                                    }
                                />
                            }
                            disabled={isGettingTableSettingsLoading || isUpdateSettingsLoading}
                            className={clsx(
                                pageClasses.settingButton,
                                pageClasses.resetSettingsButton
                            )}
                            onClick={handleResetSettings}
                        >
                            {t('common:resetSettings')}
                        </Button>
                    </Box>
                )}
                <IconButton
                    className={pageClasses.settingButton}
                    onClick={() => setDndLocked(!isDndLocked)}
                >
                    {isDndLocked ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
                </IconButton>
                <ThemeCheckboxesDropdown<TableColumnSettings>
                    languageNamespaces={languageNamespaces}
                    itemsList={visibilitySettingsList}
                    buttonIcon={
                        isGettingTableSettingsLoading ? (
                            <CircularProgress isLoading={isGettingTableSettingsLoading} />
                        ) : (
                            <SettingsOutlinedIcon />
                        )
                    }
                    iconButtonClass={pageClasses.settingButton}
                    setItemsList={handleColumnSettingsList}
                    isButtonDisabled={isGettingTableSettingsLoading}
                    onCloseHandler={handleColumnSettingsListClose}
                />
                <ToggleButtons
                    buttonsList={buttonsList}
                    value={filterValue}
                    handleChange={handleChange}
                />
            </div>
            {!isGettingTableSettingsLoading && (
                <TableWithColumnDnd
                    selectionEnabled
                    withPagination
                    columns={columns}
                    data={data.items}
                    isLoading={isLoading}
                    noDataMessage='change:noData'
                    languageNamespaces={languageNamespaces}
                    page={paginationState.page}
                    rowsPerPage={paginationState.rowsPerPage}
                    onChangePage={onChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    getRowId={getRowId}
                    handleRowSelection={handleRowSelection}
                    count={data.total}
                    handleSort={handleSort}
                    sortField={sorting?.id}
                    sortDesc={sorting?.desc}
                    hiddenColumns={hiddenColumns}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    onDragUpdate={onDragUpdate}
                    droppableId='change-table'
                    direction='horizontal'
                />
            )}
            {isGettingTableSettingsLoading && (
                <Grid container alignItems='center' justifyContent='center'>
                    <CircularProgress isLoading={isGettingTableSettingsLoading} />
                </Grid>
            )}
            {filterValue === FilterValues.NEW && (
                <Button
                    disabled={isProcessLoading || !selectedItems?.length || !canProcess}
                    color='secondary'
                    variant='contained'
                    className={pageClasses.processButton}
                    startIcon={<CircularProgress isLoading={isProcessLoading} />}
                    onClick={process}
                >
                    {t('change:process')}
                </Button>
            )}
            {filterValue === FilterValues.ARCHIVED && (
                <Box sx={{ textAlign: 'end' }}>
                    {apiInstance.settings?.canDearchiveInsuranceConfirmations && (
                        <Button
                            disabled={
                                !selectedItems?.length || isDownloadLoading || isDearchiveLoading
                            }
                            color='secondary'
                            variant='contained'
                            className={pageClasses.processButton}
                            sx={{ marginRight: '8px' }}
                            startIcon={<CircularProgress isLoading={isDearchiveLoading} />}
                            onClick={onBackToOpen}
                        >
                            {t('change:backToOpen')}
                        </Button>
                    )}
                    <Button
                        disabled={!selectedItems?.length || isDownloadLoading || isDearchiveLoading}
                        color='secondary'
                        variant='contained'
                        className={pageClasses.processButton}
                        startIcon={<CircularProgress isLoading={isDownloadLoading} />}
                        onClick={onDownload}
                    >
                        {t('change:download')}
                    </Button>
                </Box>
            )}
        </div>
    );
};
