import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { SelectableItem } from 'src/shared/types';

import {
    useSelectFieldStyles,
    useCheckBoxStyles,
    useMenuItemStyles,
    useSelectStyles,
} from './SelectFieldStyles';

export type SelectFieldProps = {
    id: string;
    languageNamespaces: string[];
    tLabelKey: string;
    tPlaceholderKey?: string;
    required?: boolean;
    name: string;
    value: string[];
    items?: SelectableItem[];
    multiple?: boolean;
    error?: boolean;
    columnAndDropdownsPerPage?: number;
    handleChange?: (name: string, values: string[] | string) => void;
    className?: string;
    disabled?: boolean;
    callbackAfterClose?: () => void;
    sortOptionsCb?: (a: SelectableItem, b: SelectableItem) => number;
};

export const SelectField = React.memo(function SelectField({
    id,
    languageNamespaces,
    items,
    name,
    value,
    handleChange,
    tLabelKey,
    tPlaceholderKey,
    multiple,
    disabled,
    callbackAfterClose,
    sortOptionsCb,
    ...rest
}: SelectFieldProps): JSX.Element {
    const classes = useSelectFieldStyles();
    const selectClasses = useSelectStyles();
    const menuClasses = useMenuItemStyles();
    const checkboxClasses = useCheckBoxStyles();

    const [open, setOpen] = useState(false);

    const { t } = useTranslation(['common', ...(languageNamespaces ? languageNamespaces : [])]);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
        if (typeof callbackAfterClose === 'function') {
            callbackAfterClose();
        }
    };

    const handleChangeValue = useCallback(
        (event: SelectChangeEvent<string[]>): void => {
            if (typeof handleChange === 'function') {
                handleChange(name, event.target.value as string[]);
            }
        },
        [handleChange, multiple, name]
    );

    const handleRenderValue = useCallback(
        (selected: string[]): string => {
            if (selected && multiple) {
                const selectedItems = selected
                    .map((item) => {
                        const option = items?.find((i) => i.value === item);
                        if (!option) {
                            return null;
                        }
                        return t(option.title, option.translationAdditionalInfo);
                    })
                    .filter((item) => Boolean(item))
                    .join(', ');
                return selectedItems;
            }
            return (
                t(
                    items?.find((i) => {
                        return value.includes(i.value);
                    })?.title
                ) ?? t(tPlaceholderKey ?? tLabelKey)
            );
        },
        [multiple, items, value, t]
    );

    return (
        <Select
            id={id}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            multiple={multiple}
            disabled={disabled}
            name={name}
            classes={selectClasses}
            placeholder={tPlaceholderKey && t(tPlaceholderKey)}
            onChange={handleChangeValue}
            value={value || []}
            IconComponent={KeyboardArrowDownIcon}
            renderValue={handleRenderValue}
            label={t(tLabelKey)}
            MenuProps={{ style: { zIndex: 11000 } }}
            SelectDisplayProps={{ id }}
            {...rest}
        >
            {!items?.length && (
                <MenuItem disabled value='' classes={menuClasses}>
                    <ListItemText>{t('common:noOptions')}</ListItemText>
                </MenuItem>
            )}
            {(!sortOptionsCb ? items : items?.sort(sortOptionsCb))?.map((item) => {
                if (item.hidden) {
                    return null;
                }
                return (
                    <MenuItem
                        disabled={item.disabled}
                        key={item.value}
                        value={item.value}
                        classes={menuClasses}
                    >
                        {multiple && (
                            <Checkbox
                                classes={checkboxClasses}
                                checked={Array.isArray(value) ? value?.includes(item.value) : false}
                            />
                        )}
                        <ListItemText>{t(item.title, item.translationAdditionalInfo)}</ListItemText>
                        {item.additionalTitles?.map((subTitle) => (
                            <ListItemText
                                className={classes.subTitle}
                                key={`${item.title} ${subTitle}`}
                            >
                                {subTitle}
                            </ListItemText>
                        ))}
                    </MenuItem>
                );
            })}
        </Select>
    );
});
