import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import useDarkMode from 'use-dark-mode';
import { useSnackbar } from 'notistack';

import {
    Button,
    FormControl,
    FormControlLabel,
    OutlinedInput,
    Typography,
    Link,
} from '@mui/material';

import { CircularProgress } from 'src/components/base';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { ResponseError } from 'src/shared/types';

import {
    useStyle,
    useFormControlLabelStyles,
    useFormControlStyles,
    useInputStyles,
} from '../login/login-form-styles';

import { forgotPasswordSchema } from './forgot-password-form-validation-schema';

type ForgotPasswordFormData = {
    email: string;
};

export const ForgotPasswordForm = (): JSX.Element => {
    const darkMode = useDarkMode();
    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();

    const { t } = useTranslation(['login', 'common', 'serverErrors']);

    const classes = useStyle({ isDarkMode: darkMode.value });
    const inputClasses = useInputStyles();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();

    const [isRequestLoading, setRequestLoading] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { isValid, errors },
    } = useForm<ForgotPasswordFormData>({
        resolver: yupResolver(forgotPasswordSchema),
        mode: 'onChange',
    });

    const makeRequest = async (vals: ForgotPasswordFormData): Promise<void> => {
        try {
            setRequestLoading(true);

            await httpClient.post('reset-password', { email: vals.email });

            enqueueSnackbar(
                t(`login:requestForResetSuccessfully`, {
                    email: vals.email,
                }),

                {
                    variant: 'success',
                }
            );

            reset();
        } catch (err) {
            const errorMessage = (err as ResponseError)?.error?.response?.data?.errors?.email[0];

            enqueueSnackbar(
                errorMessage
                    ? t(`${(err as ResponseError).error.response.data.errors.email[0]}`, {
                          ns: 'serverErrors',
                      })
                    : t('unknownError', { ns: 'serverErrors' }),

                {
                    variant: 'error',
                }
            );
        } finally {
            setRequestLoading(false);
        }
    };

    const onSubmit = async (vals: ForgotPasswordFormData): Promise<void> => {
        makeRequest(vals);
    };

    return (
        <form
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit(onSubmit)}
            className={classes.formContainer}
        >
            <Typography variant='h3' className={classes.formTitle}>
                {t('login:forgotPassword')}
            </Typography>
            <Typography variant='body1' className={classes.formMessage}>
                {t('login:forgotPasswordMessage')}
            </Typography>
            <FormControl
                error={Boolean(errors?.email)}
                fullWidth
                classes={formControlClasses}
                variant='outlined'
            >
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <OutlinedInput
                            type='email'
                            placeholder={t('login:placeholders.enterEmail')}
                            classes={inputClasses}
                            {...register('email')}
                        />
                    }
                    label={t<string>('fields.email')}
                    labelPlacement='top'
                />
            </FormControl>
            <Button
                disableElevation
                disabled={!isValid || isRequestLoading}
                className={classes.submitButton}
                variant='contained'
                type='submit'
                startIcon={<CircularProgress isLoading={isRequestLoading} />}
            >
                {t('common:send')}
            </Button>
            <Typography variant='body2' className={classes.goToLoginLink}>
                {t('common:backTo')} <Link href='login'>{t('login:login')}</Link>
            </Typography>
        </form>
    );
};
