import React, { ReactElement } from 'react';

import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

interface BooleanCellProps {
    value: boolean | number;
}

const useBooleanCellStyles = makeStyles(() => {
    return {
        icon: {
            width: 20,
            height: 20,
        },
    };
});

export const BooleanCell = ({ value }: BooleanCellProps): ReactElement => {
    const classes = useBooleanCellStyles();
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'center',
            }}
        >
            {value ? (
                <CheckIcon color='success' className={classes.icon} />
            ) : (
                <CloseIcon color='error' className={classes.icon} />
            )}
        </div>
    );
};
