import { useState } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

interface UseDownloadEntityReturn {
    download: () => Promise<void>;
    isLoading: boolean;
}

interface EntitiesBodyDto {
    insuranceConfirmationIds: string[];
}

interface UseDownloadEntityParams {
    insuranceConfirmationIds: string[];
    url: string;
}

export const useDownloadEntity = (params: UseDownloadEntityParams): UseDownloadEntityReturn => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const { url, insuranceConfirmationIds } = params;
    const httpClient = useHttpClient();

    const download = async (): Promise<void> => {
        try {
            const body: EntitiesBodyDto = {
                insuranceConfirmationIds,
            };
            setLoading(true);
            await httpClient.downloadFile(url, body);
        } finally {
            setLoading(false);
        }
    };

    return {
        isLoading,
        download,
    };
};
