import { AnyAbility } from '@casl/ability';
import { TFunction } from 'i18next';

import { MenuItem } from 'src/components/base';
import { AppRoutes } from 'src/routing/app-routes';
import { useApiInstance } from 'src/security/api-instance';
import { AbilityActions, AbilitySubjects } from 'src/security/roleAccesses';
import { Languages, AppModules } from 'src/shared/constants';

export const generateThemeHeaderProfileMenuConfig = (
    t: TFunction,
    currentModule: AppModules,
    handleGoTo: (path: string) => void,
    handleLogout: () => void,
    changeLanguage: (path: string) => void,
    manualImportAction: () => void,
    ability: AnyAbility,
    importFileEvbAction?: () => void
): MenuItem[] => {
    const apiInstance = useApiInstance();

    const profileMenu: MenuItem[] = [];
    if (apiInstance.settings?.instance === 'testing') {
        profileMenu.push({
            title: t('profileMenu.importFileEVBS'),
            action: importFileEvbAction,
        });
    }

    if (
        ability.can(AbilityActions.use, AbilitySubjects.mainPortal) &&
        ability.can(AbilityActions.use, AbilitySubjects.userManagement)
    ) {
        profileMenu.push({
            title:
                currentModule === AppModules.mainApp
                    ? t('profileMenu.userManagement')
                    : t('profileMenu.mainPortal'),
            action: () =>
                handleGoTo(
                    currentModule === AppModules.mainApp
                        ? AppRoutes.USER_MANAGEMENT
                        : `${AppRoutes.NEW_REGISTRATION}`
                ),
        });
    }

    profileMenu.push({
        title: t('profileMenu.manualImportEVBS'),
        action: manualImportAction,
    });
    profileMenu.push({
        title: t('profileMenu.language'),
        nestedMenu: [
            {
                title: t('profileMenu.german'),
                action: () => changeLanguage(Languages.DE),
            },
            {
                title: t('profileMenu.english'),
                action: () => changeLanguage(Languages.EN),
            },
        ],
    });
    profileMenu.push({ title: t('profileMenu.logout'), action: handleLogout });
    return profileMenu;
};
