import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageStyles } from 'src/pages/pages-styles';
import clsx from 'clsx';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProcessDialogTable from '../process-dialog-table';
import { DataKeyProperties, mapKeysToValues } from './category-data-keys';
import { StringObjectPropertyIndex } from './stringObjectPropertyIndex';
import { useAccordionStyle } from './accordion-style';

export interface ProcessChangesCategoryProps {
    name: string;
    headers: string[];
    amsData: StringObjectPropertyIndex | undefined;
    frameworkData: StringObjectPropertyIndex | undefined;
    localData: StringObjectPropertyIndex | undefined;
    dataKeys: DataKeyProperties[];
    readOnly: boolean;
    contractSelected: boolean;
    openContractSelector(): void;
    onValueChanging(
        dataKey: DataKeyProperties,
        data: StringObjectPropertyIndex,
        newValue: string
    ): void;
}

export function ProcessChangesCategory({
    name,
    headers,
    amsData,
    frameworkData,
    localData,
    dataKeys,
    readOnly,
    contractSelected,
    openContractSelector,
    onValueChanging,
}: ProcessChangesCategoryProps): ReactElement {
    const { t } = useTranslation(['processing']);
    const pageStyles = usePageStyles();
    const accordionStyles = useAccordionStyle();

    const values = mapKeysToValues({
        t,
        dataKeys,
        amsData,
        frameworkData,
        localData,
        onValueChanging,
    });

    return (
        <Accordion classes={accordionStyles}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{t(name)}</Typography>
            </AccordionSummary>
            {contractSelected && (
                <AccordionDetails>
                    <ProcessDialogTable headers={headers} values={values} />
                </AccordionDetails>
            )}
            {!contractSelected && (
                <AccordionDetails sx={{ textAlign: 'center' }}>
                    <Typography>{t('contractDataAmbiguous')}</Typography>
                    <Button
                        className={clsx(pageStyles.processButton)}
                        variant='contained'
                        disabled={readOnly}
                        onClick={openContractSelector}
                    >
                        {t('selectContract')}
                    </Button>
                </AccordionDetails>
            )}
        </Accordion>
    );
}
