import { TFunction } from 'i18next';
import { Column } from 'react-table';
import { CellWithTooltip } from 'src/components/base/custom-table/custom-table-elements';
import { CellWithBackendFilterDropDown } from 'src/components/base/custom-table/custom-table-elements/cell-with-backend-filter-drop-down/cell-with-backend-filter-drop-down';
import { VeelaMessage } from './veela-message';
import { DynamicPropertyFilter } from '../../lib/utility/dynamic-property-filter';
import { DynamicPropertyMultiFilter } from 'src/lib/utility/dynamic-property-multi-filter';

export enum VeelaMessagesTableKeys {
    messageType = 'Header_MessageType',
    messageId = 'messageId',
    operatingMode = 'Header_OperatingMode',
    techCommunicationPartnerRecognition = 'TechnicalSender_CommunicationPartnerRecognition',
    techSoftwareVersion = 'TechnicalSender_SoftwareVersion',
    techMessageGeneratedTimeStamp = 'TechnicalSender_MessageGeneratedTimeStamp',
    requestNr = 'RequestNr',
    insuranceConfirmationId = 'InsuranceConfirmationId',
    insurancePolicyNumber = 'Insurance_PolicyNumber',
    insuranceCompanyType = 'Insurance_CompanyType',
    insuranceInsuranceCompanyNumber = 'Insurance_InsuranceCompanyNumber',
    insuranceOfficeInsurance = 'Insurance_OfficeInsurance',
    insuranceAgencyInsurance = 'Insurance_AgencyInsurance',
    insuranceInsuranceStartingDate = 'Insurance_InsuranceStartingDate',
    insuranceInternInsuranceNotes = 'Insurance_InternInsuranceNotes',
    holderPolicyHolderDeviation = 'Holder_PolicyHolderDeviation',
    holderJrstPersonPersonName = 'Holder_JuristicPerson_PersonName',
    holderNtrlPersonSurName = 'Holder_NaturalPerson_Surname',
    holderNtrlPersonFirstName = 'Holder_NaturalPerson_FirstName',
    holderNtrlPersonGender = 'Holder_NaturalPerson_Gender',
    holderAddressPostalCode = 'Holder_PostalCode',
    holderAddressPlaceOfResidence = 'Holder_PlaceOfResidence',
    // holderAddressState = 'holderAddressState',
    holderAddressStreet = 'Holder_Street',
    holderAddressHouseNumber = 'Holder_HouseNumber',
    holderAddressAdditions = 'Holder_AddressAdditions',
    // holderAddressCommunityKey = 'holderAddressCommunityKey',
    vehicleKeyManufacturer = 'Vehicle_KeyManufacturer',
    vehicleManufacturer = 'Vehicle_Manufacturer',
    vehicleKeyType = 'Vehicle_KeyType',
    // vehicleKeyVariantVersion = 'vehicleKeyVariantVersion',
    vehicleVehicleType = 'Vehicle_VehicleType',
    vehicleKeyVehicleClass = 'Vehicle_KeyVehicleClass',
    vehicleKeyStructure = 'Vehicle_KeyStructure',
    vehicleVehicleTypeText = 'Vehicle_VehicleTypeText',
    vehicleTextStructure = 'Vehicle_TextStructure',
    vehicleVehicleIdentificationNumber = 'Vehicle_VehicleIdentificationNumber',
    vehicleDisplacement = 'Vehicle_Displacement',
    vehicleRatedCapacityKw = 'Vehicle_RatedCapacityKw',
    vehicleVehicleUsage = 'Vehicle_VehicleUsage',
    licenseLicenseNumber = 'License_LicenseNumber',
    licenseLicenseTypeFeatures = 'License_LicenseTypeFeatures',
    licenseVariableLicenseFeatures = 'License_VariableLicenseFeatures',
    licenseElectricLicenseFeatures = 'License_ElectricLicenseFeatures',
    licenseTimeOfAllocation = 'License_TimeOfAllocation',
    authCompetentAuthorityDistrictKey = 'Authority_CompetentAuthorityDistrictKey',
    authCompetentAuthorityAdditionalDigit = 'Authority_CompetentAuthorityAdditionalDigit',
    authInProgressAt = 'Authority_InProgressAt',
    vuMessageType = 'Operation_MessageType',
    vuCancellation = 'Operation_Cancellation',
    vuHolderChange = 'Operation_HolderChange',
    vuInsuranceChange = 'Operation_InsuranceChange',
    vuLicenseNumberChange = 'Operation_LicenseNumberChange',
    vuVehicleClassChange = 'Operation_VehicleClassChange',
    // vuVehicleUsageChange = 'vuVehicleUsageChange',
    vuOldtimerChange = 'Operation_OldtimerChange',
    vuElectricLicenseFeatureChange = 'Operation_ElectricLicenseFeatureChange',
    // vbRefusalReason = 'vbRefusalReason',
    // corrCorrectionOfficeInsurance = 'corrCorrectionOfficeInsurance',
    // corrCorrectionInsuranceCompanyNumber = 'corrCorrectionInsuranceCompanyNumber',
    // corrCorrectionVehicleIdentificationNumber = 'corrCorrectionVehicleIdentificationNumber',
    // correctionVehicleIdentificationNumber = 'correctionVehicleIdentificationNumber',
    timeStamp = 'TimeStamp',
}

interface GenerateVeelaMessagesTableConfigParams {
    t: TFunction;
    onPropertyFilterChange: (propertyName: string, filters: string[]) => void;
    propertyFilters: DynamicPropertyMultiFilter[];
}

interface GetPropertyFilterDataResponseDto {
    items: string[];
    total: number;
}

export const generateVeelaMessagesTableConfig = ({
    t,
    propertyFilters,
    onPropertyFilterChange,
}: GenerateVeelaMessagesTableConfigParams): Array<Column<VeelaMessage>> => {
    const generateColumn = (
        width: number,
        accessor: (msg: VeelaMessage) => string | number,
        key: string,
        valueFormatter?: (value: string | string[]) => string
    ): Column<VeelaMessage> => {
        const filterValues = propertyFilters.find((pf) => pf.propertyName === key)?.filters;

        return {
            id: key,
            Header: () => (
                <CellWithBackendFilterDropDown
                    url={`api/veelaMessages/filterData`}
                    name={key}
                    onChange={onPropertyFilterChange}
                    namespaces={['veela-messages']}
                    title={key}
                    makeRequest={async (httpClient, url, searchTerm) => {
                        const dto: DynamicPropertyFilter = {
                            propertyName: key,
                            searchTerm: searchTerm,
                            skip: 0,
                            take: 100,
                        };

                        const response = await httpClient.post<GetPropertyFilterDataResponseDto>(
                            url,
                            dto
                        );

                        return response.items.map((item) => {
                            return {
                                title: item,
                                value: item,
                            };
                        });
                    }}
                    initialCheckedValues={filterValues}
                    valueFormatter={valueFormatter}
                />
            ),
            accessor: accessor,
            Cell: (cellProps: any) => (
                <CellWithTooltip {...cellProps} valueFormatter={valueFormatter} />
            ),
            width: width,
        };
    };

    const dateFormatter = (date: string | string[]) => {
        if (typeof date !== 'string') {
            return '';
        }

        const parsedDate = Date.parse(date);
        if (isNaN(parsedDate)) {
            return '';
        }

        return t('formatted-values:formattedDate', {
            date,
        })?.replaceAll('&#x2F;', '/');
    };

    const dateTimeFormatted = (date: string | string[]): string => {
        if (typeof date !== 'string') {
            return '';
        }

        const parsedDate = Date.parse(date);
        if (isNaN(parsedDate)) {
            return '';
        }

        return `${t('formatted-values:formattedDate', {
            date,
        })?.replaceAll('&#x2F;', '/')} ${t('formatted-values:formattedTime', { time: date })}`;
    };

    return [
        generateColumn(100, (msg) => msg.header.messageType, VeelaMessagesTableKeys.messageType),
        generateColumn(
            100,
            (msg) => msg.header?.operatingMode,
            VeelaMessagesTableKeys.operatingMode
        ),
        generateColumn(
            100,
            (msg) =>
                msg.header?.technicalSenderInformation?.technicalSenderInformation
                    ?.communicationPartnerRecognition,
            VeelaMessagesTableKeys.techCommunicationPartnerRecognition
        ),
        generateColumn(
            100,
            (msg) =>
                msg.header?.technicalSenderInformation.technicalSenderInformation?.softwareVersion,
            VeelaMessagesTableKeys.techSoftwareVersion
        ),
        generateColumn(
            100,
            (msg) =>
                msg.header?.technicalSenderInformation?.technicalSenderInformation
                    ?.messageGeneratedTimeStamp,
            VeelaMessagesTableKeys.techMessageGeneratedTimeStamp,
            dateTimeFormatted
        ),
        generateColumn(100, (msg) => msg.requestNr, VeelaMessagesTableKeys.requestNr),
        generateColumn(
            100,
            (msg) => msg.insuranceConfirmationId,
            VeelaMessagesTableKeys.insuranceConfirmationId
        ),
        generateColumn(
            100,
            (msg) => msg.insurance?.agencyInsurance,
            VeelaMessagesTableKeys.insuranceAgencyInsurance
        ),
        generateColumn(
            100,
            (msg) => msg.insurance?.companyType,
            VeelaMessagesTableKeys.insuranceCompanyType
        ),
        generateColumn(
            100,
            (msg) => msg.insurance?.insuranceCompanyNumber,
            VeelaMessagesTableKeys.insuranceInsuranceCompanyNumber
        ),
        generateColumn(
            100,
            (msg) => msg.insurance?.insuranceStartingDate,
            VeelaMessagesTableKeys.insuranceInsuranceStartingDate,
            dateFormatter
        ),
        generateColumn(
            100,
            (msg) => msg.insurance?.policyNumber,
            VeelaMessagesTableKeys.insurancePolicyNumber
        ),
        generateColumn(
            100,
            (msg) => msg.insurance?.internInsuranceNotes,
            VeelaMessagesTableKeys.insuranceInternInsuranceNotes
        ),
        generateColumn(
            100,
            (msg) => msg.insurance?.officeInsurance,
            VeelaMessagesTableKeys.insuranceOfficeInsurance
        ),
        // generateColumn(
        //     100,
        //     (msg) => msg.holder?.address?.communityKey,
        //     VeelaMessagesTableKeys.holderAddressCommunityKey
        // ),
        generateColumn(
            100,
            (msg) => msg.holder?.address?.houseNumber,
            VeelaMessagesTableKeys.holderAddressHouseNumber
        ),
        generateColumn(
            100,
            (msg) => msg.holder?.address?.addressAdditions,
            VeelaMessagesTableKeys.holderAddressAdditions
        ),
        generateColumn(
            100,
            (msg) => msg.holder?.address?.placeOfResidence,
            VeelaMessagesTableKeys.holderAddressPlaceOfResidence
        ),
        generateColumn(
            100,
            (msg) => msg.holder?.address?.postalCode,
            VeelaMessagesTableKeys.holderAddressPostalCode
        ),
        // generateColumn(
        //     100,
        //     (msg) => msg.holder?.address?.state,
        //     VeelaMessagesTableKeys.holderAddressState
        // ),
        generateColumn(
            100,
            (msg) => msg.holder?.address?.street,
            VeelaMessagesTableKeys.holderAddressStreet
        ),
        generateColumn(
            100,
            (msg) => msg.holder?.juristicPerson?.personName,
            VeelaMessagesTableKeys.holderJrstPersonPersonName
        ),
        generateColumn(
            100,
            (msg) => msg.holder?.naturalPerson?.firstName,
            VeelaMessagesTableKeys.holderNtrlPersonFirstName
        ),
        generateColumn(
            100,
            (msg) => msg.holder?.naturalPerson?.surName,
            VeelaMessagesTableKeys.holderNtrlPersonSurName
        ),
        generateColumn(
            100,
            (msg) => msg.holder?.naturalPerson?.gender,
            VeelaMessagesTableKeys.holderNtrlPersonGender
        ),
        generateColumn(
            100,
            (msg) => msg.holder?.policyHolderDeviation,
            VeelaMessagesTableKeys.holderPolicyHolderDeviation
        ),
        generateColumn(
            100,
            (msg) => msg.vehicle?.displacement,
            VeelaMessagesTableKeys.vehicleDisplacement
        ),
        generateColumn(
            100,
            (msg) => msg.vehicle?.keyManufacturer,
            VeelaMessagesTableKeys.vehicleKeyManufacturer
        ),
        generateColumn(
            100,
            (msg) => msg.vehicle?.keyStructure,
            VeelaMessagesTableKeys.vehicleKeyStructure
        ),
        generateColumn(100, (msg) => msg.vehicle?.keyType, VeelaMessagesTableKeys.vehicleKeyType),
        // generateColumn(
        //     100,
        //     (msg) => msg.vehicle?.keyVariantVersion,
        //     VeelaMessagesTableKeys.vehicleKeyVariantVersion
        // ),
        generateColumn(
            100,
            (msg) => msg.vehicle?.keyVehicleClass,
            VeelaMessagesTableKeys.vehicleKeyVehicleClass
        ),
        generateColumn(
            100,
            (msg) => msg.vehicle?.manufacturer,
            VeelaMessagesTableKeys.vehicleManufacturer
        ),
        generateColumn(
            100,
            (msg) => msg.vehicle?.ratedCapacityKw,
            VeelaMessagesTableKeys.vehicleRatedCapacityKw
        ),
        generateColumn(
            100,
            (msg) => msg.vehicle?.textStructure,
            VeelaMessagesTableKeys.vehicleTextStructure
        ),
        generateColumn(
            100,
            (msg) => msg.vehicle?.vehicleIdentificationNumber,
            VeelaMessagesTableKeys.vehicleVehicleIdentificationNumber
        ),
        generateColumn(
            100,
            (msg) => msg.vehicle?.vehicleType,
            VeelaMessagesTableKeys.vehicleVehicleType
        ),
        generateColumn(
            100,
            (msg) => msg.vehicle?.vehicleTypeText,
            VeelaMessagesTableKeys.vehicleVehicleTypeText
        ),
        generateColumn(
            100,
            (msg) => msg.vehicle?.vehicleUsage,
            VeelaMessagesTableKeys.vehicleVehicleUsage
        ),
        generateColumn(
            100,
            (msg) => msg.vehicleLicense?.electricLicenseFeatures,
            VeelaMessagesTableKeys.licenseElectricLicenseFeatures
        ),
        generateColumn(
            100,
            (msg) => msg.vehicleLicense?.licenseNumber,
            VeelaMessagesTableKeys.licenseLicenseNumber
        ),
        generateColumn(
            100,
            (msg) => msg.vehicleLicense?.licenseTypeFeatures,
            VeelaMessagesTableKeys.licenseLicenseTypeFeatures
        ),
        generateColumn(
            100,
            (msg) => msg.vehicleLicense?.variableLicenseFeatures,
            VeelaMessagesTableKeys.licenseVariableLicenseFeatures
        ),
        generateColumn(
            100,
            (msg) => msg.vehicleLicense?.timeOfAllocation,
            VeelaMessagesTableKeys.licenseTimeOfAllocation
        ),
        generateColumn(
            100,
            (msg) => msg.authority?.competentAuthorityAdditionalDigit,
            VeelaMessagesTableKeys.authCompetentAuthorityAdditionalDigit
        ),
        generateColumn(
            100,
            (msg) => msg.authority?.competentAuthorityDistrictKey,
            VeelaMessagesTableKeys.authCompetentAuthorityDistrictKey
        ),
        generateColumn(
            100,
            (msg) => msg.authority?.inProgressAt,
            VeelaMessagesTableKeys.authInProgressAt,
            dateTimeFormatted
        ),
        generateColumn(
            100,
            (msg) => msg.vuOperation?.cancellation,
            VeelaMessagesTableKeys.vuCancellation
        ),
        generateColumn(
            100,
            (msg) => msg.vuOperation?.electricLicenseFeatureChange,
            VeelaMessagesTableKeys.vuElectricLicenseFeatureChange
        ),
        generateColumn(
            100,
            (msg) => msg.vuOperation?.holderChange,
            VeelaMessagesTableKeys.vuHolderChange
        ),
        generateColumn(
            100,
            (msg) => msg.vuOperation?.insuranceChange,
            VeelaMessagesTableKeys.vuInsuranceChange
        ),
        generateColumn(
            100,
            (msg) => msg.vuOperation?.licenseNumberChange,
            VeelaMessagesTableKeys.vuLicenseNumberChange
        ),
        generateColumn(
            100,
            (msg) => msg.vuOperation?.messageType,
            VeelaMessagesTableKeys.vuMessageType
        ),
        generateColumn(
            100,
            (msg) => msg.vuOperation?.oldtimerChange,
            VeelaMessagesTableKeys.vuOldtimerChange
        ),
        generateColumn(
            100,
            (msg) => msg.vuOperation?.vehicleClassChange,
            VeelaMessagesTableKeys.vuVehicleClassChange
        ),
        // generateColumn(
        //     100,
        //     (msg) => msg.vuOperation?.vehicleUsageChange,
        //     VeelaMessagesTableKeys.vuVehicleUsageChange
        // ),
        // generateColumn(100, (msg) => msg.vbRefusalReason, VeelaMessagesTableKeys.vbRefusalReason),
        // generateColumn(
        //     100,
        //     (msg) => msg.corrections?.correctionInsuranceCompanyNumber,
        //     VeelaMessagesTableKeys.corrCorrectionInsuranceCompanyNumber
        // ),
        // generateColumn(
        //     100,
        //     (msg) => msg.corrections?.correctionOfficeInsurance,
        //     VeelaMessagesTableKeys.corrCorrectionOfficeInsurance
        // ),
        // generateColumn(
        //     100,
        //     (msg) => msg.corrections?.correctionVehicleIdentificationNumber,
        //     VeelaMessagesTableKeys.corrCorrectionVehicleIdentificationNumber
        // ),
        // generateColumn(
        //     100,
        //     (msg) => msg.correctionVehicleIdentificationNumber,
        //     VeelaMessagesTableKeys.correctionVehicleIdentificationNumber
        // ),
        generateColumn(
            100,
            (msg) => msg.timeStamp,
            VeelaMessagesTableKeys.timeStamp,
            dateTimeFormatted
        ),
    ];
};
