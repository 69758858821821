import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';

import { LinearProgress } from '@mui/material';

import './index.css';
import App from './App';
import { HttpInfrastructure } from './lib/http-client/axios-http-infrastructure';
import { ConnectivityIndicatorInfrastructure } from './lib/connectivity-indicator/connectivity-indicator-infrastructure';
import { LoginProvider } from './security/login';
import { GlobalStoreProvider } from './shared/contexts/global-store';
import { ApiInstanceProvider } from './security/api-instance';
import { AbilityContextProvider } from 'src/security/roleAccesses';

ReactDOM.render(
    <React.StrictMode>
        <ConnectivityIndicatorInfrastructure>
            <HttpInfrastructure>
                <GlobalStoreProvider>
                    <ApiInstanceProvider>
                        <Suspense fallback={<LinearProgress />}>
                            <BrowserRouter>
                                <LoginProvider>
                                    <AbilityContextProvider>
                                        <App />
                                    </AbilityContextProvider>
                                </LoginProvider>
                            </BrowserRouter>
                        </Suspense>
                    </ApiInstanceProvider>
                </GlobalStoreProvider>
            </HttpInfrastructure>
        </ConnectivityIndicatorInfrastructure>
    </React.StrictMode>,
    document.getElementById('root')
);
