import { ChangeTableKeys } from './change-table-config';

export const changeTableDefaultOrderColumn = {
    [ChangeTableKeys.insuranceConfirmationNumber]: 0,
    [ChangeTableKeys.internalInsuranceNotes]: 1,
    [ChangeTableKeys.changeType]: 2,
    [ChangeTableKeys.licenseNumber]: 3,
    [ChangeTableKeys.result]: 4,
    [ChangeTableKeys.date]: 5,
    [ChangeTableKeys.startOfContract]: 6,
    [ChangeTableKeys.owner]: 7,
    [ChangeTableKeys.postcode]: 8,
    [ChangeTableKeys.place]: 9,
    [ChangeTableKeys.street]: 10,
    [ChangeTableKeys.houseNumber]: 11,
    [ChangeTableKeys.agencyNumber]: 12,
};
