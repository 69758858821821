import React, { ReactElement, MouseEvent, useState, BaseSyntheticEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Box,
    Typography,
    Button,
    Menu,
    MenuItem,
    Checkbox,
    ListItemIcon,
    Grid,
    IconButton,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

import { SelectableItem } from 'src/shared/types';
import { Loader, OverflowTooltip } from 'src/components/base';

import { useCellWithFilterDropDownStyles } from './CellWithFilterDropDownStyles';
import { SearchField } from 'src/components/fields/SearchField';

interface CellWithFilterDropDownProps {
    checkedValues: string[];
    dropdownList: SelectableItem[];
    name: string;
    shouldShowAll?: boolean;
    onChange: (name: string, checkedValues: string[]) => void;
    applyFilters: () => void;
    title?: string;
    namespaces?: string[];
    withSearch?: boolean;
    searchName?: string;
    searchValue?: string;
    onChangeSearch?: (name: string, value: string) => void;
    listLoading?: boolean;
    afterCloseCallback?: () => void;
    onVisibilityChange?: (isOpen: boolean) => void;
    valueFormatter?: (value: string | string[]) => string;
}

export const CellWithFilterDropDown = (props: CellWithFilterDropDownProps): ReactElement => {
    const classes = useCellWithFilterDropDownStyles();
    const {
        dropdownList,
        namespaces,
        title,
        checkedValues,
        onChange,
        name,
        withSearch,
        searchName,
        searchValue,
        onChangeSearch,
        listLoading,
        shouldShowAll,
        afterCloseCallback,
        applyFilters,
        onVisibilityChange,
        valueFormatter,
    } = props;
    const { t } = useTranslation(namespaces);
    const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);

    const open = Boolean(anchorEl);

    const replaceEmptyOption = (value: string | string[]) => {
        if (Array.isArray(value)) {
            if (value.length === 0) {
                return t('emptyFilter');
            } else {
                return value.join(',');
            }
        } else if (value === null || value === '') {
            return t('emptyFilter');
        }
        return value;
    };

    const areAllChecked = (): boolean => {
        if (listLoading) {
            return false;
        }

        return dropdownList.every((item) => checkedValues?.includes(item.value));
    };

    const stopPropagation = (e: BaseSyntheticEvent): void => {
        e.stopPropagation();
    };

    const handleClose = (event: MouseEvent<HTMLLIElement>): void => {
        event.stopPropagation();
        setAnchorEl(null);
        if (typeof afterCloseCallback === 'function') {
            afterCloseCallback();
        }
    };

    const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleChange = (value: string): void => {
        if (value === 'all') {
            if (areAllChecked()) {
                onChange(name, []);
            } else {
                onChange(
                    name,
                    dropdownList.map((item) => item.value)
                );
            }
        } else {
            if (checkedValues.some((item) => item === value)) {
                onChange(
                    name,
                    checkedValues.filter((i) => i !== value && i !== 'all')
                );
            } else {
                onChange(name, [...checkedValues.filter((item) => item !== 'all'), value]);
            }
        }
    };

    const handleApply = (): void => {
        applyFilters();
        setAnchorEl(null);
    };

    useEffect(() => {
        onVisibilityChange(open);
    }, [open]);

    return (
        <Box className={classes.container}>
            {title && (
                <OverflowTooltip label={t(title)}>
                    <Typography className={classes.title}>{t(title)}</Typography>
                </OverflowTooltip>
            )}

            <IconButton
                title={t('common:filter')}
                disableRipple
                onClick={handleClick}
                className={classes.filterIconButton}
            >
                {checkedValues.length > 0 && <div className={classes.certainFilterIndicator} />}
                <FilterListIcon color='secondary' />
            </IconButton>

            <Menu
                onClick={stopPropagation}
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {title && <Typography className={classes.listTitle}>{t(title)}</Typography>}
                {withSearch && (
                    <Box pl={3} pr={3}>
                        <SearchField
                            name={searchName}
                            value={searchValue}
                            onChange={onChangeSearch}
                        />
                    </Box>
                )}
                {listLoading && (
                    <Grid container justifyContent='center' alignItems='center'>
                        <Loader />
                    </Grid>
                )}
                {!listLoading && (
                    <Box className={classes.listContainer}>
                        {shouldShowAll && (
                            <MenuItem
                                disableRipple
                                key={'all'}
                                onClick={(e: BaseSyntheticEvent) => {
                                    stopPropagation(e);
                                    handleChange('all');
                                }}
                                className={classes.filterItem}
                            >
                                <Checkbox
                                    disableRipple
                                    checked={areAllChecked()}
                                    color='secondary'
                                    tabIndex={-1}
                                    value={'all'}
                                />
                                {t('allItems')}
                            </MenuItem>
                        )}
                        {dropdownList.map(({ Icon, ...item }) => {
                            let formattedValue =
                                valueFormatter != null ? valueFormatter(item.value) : item.value;

                            formattedValue = replaceEmptyOption(formattedValue);

                            const isChecked = checkedValues?.some((value) => value === item.value);
                            return (
                                <MenuItem
                                    disableRipple
                                    key={item.value}
                                    onClick={(e: BaseSyntheticEvent) => {
                                        stopPropagation(e);
                                        handleChange(item.value);
                                    }}
                                    className={classes.filterItem}
                                >
                                    <Checkbox
                                        disableRipple
                                        checked={isChecked}
                                        color='secondary'
                                        tabIndex={-1}
                                        value={item.value}
                                    />
                                    {formattedValue}
                                    {Icon && (
                                        <>
                                            <div style={{ flexGrow: 1 }} />
                                            <ListItemIcon style={{ justifyContent: 'end' }}>
                                                {<Icon />}
                                            </ListItemIcon>
                                        </>
                                    )}
                                </MenuItem>
                            );
                        })}
                    </Box>
                )}
                <Box pl={3} pr={3} pt={5} pb={2}>
                    <Button
                        disabled={listLoading}
                        className={classes.applyButton}
                        color='secondary'
                        variant='contained'
                        onClick={handleApply}
                    >
                        {t('apply')}
                    </Button>
                </Box>
            </Menu>
        </Box>
    );
};

CellWithFilterDropDown.defaultProps = {
    listLoading: false,
};
