import { useEffect, useState } from 'react';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { TableNames } from 'src/shared/constants';
import { TableSettingsDto } from 'src/shared/types';

interface UseGetTableSettingsReturn {
    isLoading: boolean;
    settings: TableSettingsDto | null;
    requestTableSettings: () => Promise<void>;
}

interface UseGetTableSettingsParams {
    tableName: TableNames;
}

export const useGetTableSettings = ({
    tableName,
}: UseGetTableSettingsParams): UseGetTableSettingsReturn => {
    const httpClient = useHttpClient();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [settings, setSettings] = useState<TableSettingsDto | null>(null);

    const requestTableSettings = async (): Promise<void> => {
        try {
            setLoading(true);
            const data = await httpClient.get<TableSettingsDto>(
                `tables/${tableName}/table-settings`
            );
            setSettings(data);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (tableName) {
            requestTableSettings();
        }
    }, [tableName]);

    return { isLoading, settings, requestTableSettings };
};
