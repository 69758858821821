import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { THEME_MODES } from 'src/theming/theme-modes';

export const useModalWindowStyles = makeStyles((theme: Theme) => {
    return {
        closeButton: {
            position: 'absolute',
            top: '10px',
            right: '0',
            '& .MuiSvgIcon-root': {
                width: 25,
                height: 25,
                color: theme.palette.primary.contrastText,
            },
        },
    };
});

export const useModalClasses = makeStyles(
    (theme: Theme) => {
        const isDarkMode = THEME_MODES.DARK === theme.palette.mode;
        return {
            paper: {
                position: 'relative',
                minWidth: (props: { width?: number }) => {
                    return props?.width ? `${props.width}px` : 350;
                },
                maxWidth: 'initial',
                backgroundColor: isDarkMode
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light,
            },
        };
    },
    { index: 1 }
);

export const useStyleDialogTitle = makeStyles((theme: Theme) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(5),
            fontSize: '1.6rem',
            height: 60,
            padding: '0',
            borderBottom: `2px solid ${theme.palette.grey[300]}`,
            color: theme.palette.primary.contrastText,
        },
    };
});

export const useStyleDialogContent = makeStyles(() => {
    return {
        root: {
            padding: '0',
            overflow: 'hidden',
        },
    };
});

export const useStyleDialogActions = makeStyles((theme: Theme) => {
    return {
        root: {
            marginTop: theme.spacing(3),
            padding: 0,
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(1),
            },
        },
    };
});
