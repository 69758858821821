import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { THEME_MODES } from 'src/theming/theme-modes';

export const useTableStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            minWidth: '100%!important',

            '& .MuiTableCell-root': {
                paddingTop: 12,
                paddingBottom: 12,
                paddingLeft: theme.spacing(2),
                paddingRight: 0,
                alignItems: 'center',
                '& .MuiTableSortLabel-icon': {
                    margin: 0,
                },
                [theme.breakpoints.down('xl')]: {
                    fontSize: '1.4rem',
                },
            },
            '& .MuiTableCell-head': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.info.contrastText,
                fontWeight: theme.typography.fontWeightMedium,

                '& .MuiTypography-root': {
                    fontWeight: theme.typography.fontWeightBold,
                },
                '&:hover div': {
                    color: theme.palette.secondary.contrastText,
                },
            },
        },
    };
});

export const useTableHeaderStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            minHeight: 60,
            width: '100%',
            '& .MuiTableCell-root': {
                '& .MuiCheckbox-root .MuiSvgIcon-root': {
                    color: theme.palette.primary.main,
                },
                '&:first-child': {},
            },
        },
    };
});

export const useTableBodyStyle = makeStyles((theme: Theme) => {
    const isDarkMode = theme.palette.mode === THEME_MODES.DARK;
    return {
        root: {
            display: 'table-row-group',
            width: '100%',
            maxHeight: '60vh',
            '& .MuiTableRow-root': {
                transition: '0.2s',
                whiteSpace: 'nowrap',
                backgroundColor: theme.palette.primary.main,
                cursor: 'default',
                '&:nth-child(even)': {
                    backgroundColor: isDarkMode
                        ? theme.palette.info.main
                        : theme.palette.secondary.light,
                },
                '&:hover': {
                    opacity: 1,
                    backgroundColor: 'default',
                },
            },
            '& .MuiTableCell-root': {
                paddingTop: 7,
                paddingBottom: 7,
                '& .MuiCheckbox-root .MuiSvgIcon-root': {
                    color: theme.palette.secondary.main,
                },
            },
        },
    };
});

export const useTableRowStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            backgroundColor: theme.palette.primary.dark,
            height: 60,
            minHeight: 60,
        },
    };
});
