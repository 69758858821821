import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { DropzoneAreaBase, FileObject } from 'react-mui-dropzone';
import { useCheckTotalFilesSize } from 'src/shared/hooks/hooks-check-total-files-size';

interface DropZoneProps {
    onAdd: (file: FileObject[]) => void;
    onRemove: (index: number) => void;
    fileObjects: FileObject[];
    formats: string[];
    maxTotalFilesSize?: number;
    maxFiles?: number;
}

export const DropZone = ({
    onAdd,
    onRemove,
    fileObjects,
    formats,
    maxFiles,
    maxTotalFilesSize,
}: DropZoneProps): JSX.Element => {
    const { t } = useTranslation('common');
    const checkTotalFileSize = useCheckTotalFilesSize();

    maxFiles ??= 10e6;
    maxTotalFilesSize ??= 30e6;

    const preventDefault = (e: SyntheticEvent): void => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleAddFiles = (files: FileObject[]): void => {
        const isTotalFilesSizeValid = checkTotalFileSize(
            [...fileObjects, ...files],
            maxTotalFilesSize ?? 30e6
        );
        if (isTotalFilesSizeValid) {
            onAdd(files);
        }
    };

    const handleRemoveFile = (index: number): void => {
        setTimeout(() => onRemove(index), 0);
    };

    return (
        <div onClick={preventDefault} role='button' tabIndex={0}>
            <DropzoneAreaBase
                showFileNames
                dropzoneText={fileObjects?.length > 0 ? '' : t('fileImport.dragAndDropPreview')}
                filesLimit={maxFiles}
                onAdd={(newFiles) => handleAddFiles(newFiles)}
                onDelete={(deletedFile, index) => handleRemoveFile(index)}
                fileObjects={fileObjects}
                acceptedFiles={formats}
                getFileAddedMessage={(fileName) => t('fileImport.fileAdded', { fileName })}
                getFileRemovedMessage={(fileName) => t('fileImport.fileRemoved', { fileName })}
                getDropRejectMessage={(file, acceptedFiles, maxFileSize) => {
                    if (!acceptedFiles.includes(`.${file.name.split('.').pop()}`)) {
                        return t('fileImport.fileLoadingRejected', {
                            fileName: file.name,
                        });
                    }
                    if (file.size > maxFileSize) {
                        return t('fileImport.fileSizeOverTheLimit');
                    }
                    return '';
                }}
            />
        </div>
    );
};
