import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import {
    useTableStyle,
    useTableHeaderStyle,
    useTableBodyStyle,
    useTableRowStyle,
} from './process-dialog-table-styles';

export interface ProcessTableValue {
    [key: string]: JSX.Element;
}

interface ProcessTableProps {
    headers: string[];
    values: ProcessTableValue[];
}

export default function ProcessDialogTable({ headers, values }: ProcessTableProps): ReactElement {
    const tableClasses = useTableStyle();
    const headerClasses = useTableHeaderStyle();
    const bodyClasses = useTableBodyStyle();
    const rowClasses = useTableRowStyle();

    const { t } = useTranslation(['processing']);
    const width = 100 / headers.length + '%';
    const style: React.CSSProperties = { maxWidth: width, width: width, minWidth: width };

    let rowCounter = 0;

    return (
        <TableContainer>
            <Table stickyHeader classes={tableClasses} sx={{ tableLayout: 'fixed' }}>
                <TableHead classes={headerClasses}>
                    <TableRow classes={rowClasses}>
                        {headers.map((key) => {
                            return (
                                <TableCell key={key} style={style}>
                                    {t(key)}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody classes={bodyClasses}>
                    {values.map((value) => {
                        return (
                            <TableRow key={rowCounter++} classes={rowClasses}>
                                {headers.map((key) => {
                                    return <TableCell key={key}>{value[key]}</TableCell>;
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
