import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { IconButton, TablePagination, useTheme } from '@mui/material';

import { KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';

import { usePaginationStyle, useStyle, useRowsPerPageMenuStyle } from './pagination-styles';

interface InfiniteProps {
    currentRowsCount: number;
}
interface CustomTablePaginationActionsProps {
    onPageChange: (event: MouseEvent<HTMLButtonElement> | null, page: number) => void;
    page: number;
    count: number;
    rowsPerPage: number;
    withoutMinMaxButtons?: boolean;
    infinite?: InfiniteProps;
}

const CustomTablePaginationActions = (props: CustomTablePaginationActionsProps): JSX.Element => {
    const classes = usePaginationStyle();
    const theme = useTheme();
    const { onPageChange, page, count, rowsPerPage, withoutMinMaxButtons, infinite } = props;

    const handleFirstPageButtonClick = (event: MouseEvent<HTMLButtonElement> | null): void => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement> | null): void => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement> | null): void => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: MouseEvent<HTMLButtonElement> | null): void => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    const isNextButtonEnabled = infinite
        ? infinite.currentRowsCount > rowsPerPage
        : rowsPerPage * (page + 1) < count;

    return (
        <div className={classes.root}>
            {!withoutMinMaxButtons && !infinite && (
                <IconButton
                    disableRipple
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label='first page'
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
            )}
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label='previous page'
                disableRipple
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={!isNextButtonEnabled}
                aria-label='next page'
                disableRipple
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            {!withoutMinMaxButtons && !infinite && (
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label='last page'
                    disableRipple
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            )}
        </div>
    );
};

interface LabelDisplayedRowArgs {
    from: number;
    to: number;
    count: number;
}

const CustomRowsDisplay = ({ from, to, count }: LabelDisplayedRowArgs): string => {
    const { t } = useTranslation(['common']);
    return `${from}-${to} ` + t('of') + ` ${count}`;
};

interface PaginationProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: MouseEvent<HTMLButtonElement> | null, page: number) => void;
    handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    infinite?: InfiniteProps;
    withoutMinMaxButtons?: boolean;
    paginationClassName?: string;
    rowsPerPageOptions?: { label: string; value: number }[];
}

export const Pagination = (props: PaginationProps): JSX.Element => {
    const classes = useStyle();
    const rowsPerPageMenuClasses = useRowsPerPageMenuStyle();
    const { t } = useTranslation(['common']);
    const {
        count,
        page,
        rowsPerPage,
        onChangePage,
        rowsPerPageOptions,
        handleChangeRowsPerPage,
        withoutMinMaxButtons,
        paginationClassName,
        infinite,
    } = props;

    /* 
            labelDisplayedRows={infinite ? () => <React.Fragment /> : undefined}
     */
    return (
        <TablePagination
            className={clsx(classes.pagination, paginationClassName)}
            rowsPerPageOptions={rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            count={count}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={`${t('common:pagination')}`}
            labelDisplayedRows={infinite ? () => <React.Fragment /> : CustomRowsDisplay}
            ActionsComponent={(props) => (
                <CustomTablePaginationActions
                    {...props}
                    withoutMinMaxButtons={withoutMinMaxButtons}
                    infinite={infinite}
                />
            )}
            component='div'
            SelectProps={{
                IconComponent: (innerProps) => {
                    return <KeyboardArrowDown {...innerProps} fontSize='small' />;
                },
                MenuProps: {
                    disableScrollLock: true,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    classes: rowsPerPageMenuClasses,
                },
            }}
        />
    );
};

Pagination.defaultProps = {
    rowsPerPageOptions: [
        { label: '10', value: 10 },
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
    ],
};
