import { Theme, ThemeOptions, responsiveFontSizes } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { THEME_MODES } from './theme-modes';
import { DARK_THEME_COLORS, LIGHT_THEME_COLORS } from './theme-colors';

export const mainTheme: ThemeOptions = {
    spacing: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1250,
            xl: 1537,
        },
    },
    typography: {
        fontFamily: "'Prompt', 'Helvetica', 'Arial', sans-serif",

        h1: {
            fontSize: '4rem',
            fontWeight: 700,
        },
        h2: {
            fontSize: '3.5rem',
            fontWeight: 700,
        },
        h3: {
            fontSize: '3rem',
            fontWeight: 700,
        },
        h4: {
            fontSize: '2.5rem',
            fontWeight: 700,
        },
        h5: {
            fontSize: '2rem',
            fontWeight: 700,
        },
        h6: {
            fontSize: '1.5rem',
            fontWeight: 700,
        },
        body1: {
            fontSize: '1.8rem',
            fontWeight: 400,
        },
        body2: {
            fontSize: '1.6rem',
            fontWeight: 400,
        },
        subtitle1: {
            fontSize: '1.3rem',
        },
        subtitle2: {
            fontSize: '1rem',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    height: '100%',
                    fontSize: '10px',
                },
                body: {
                    width: '100vw',
                    height: '100vh',
                    overflowX: 'hidden',
                    '& .MuiSnackbarContent-root': {
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        paddingRight: '1.6rem!important',
                        maxWidth: 400,
                        overflow: 'hidden',
                    },
                    '& .MuiSnackbarContent-message': {
                        wordBreak: 'break-all',
                    },
                },
                input: {
                    '&::-webkit-input-placeholder': {
                        fontSize: '1.4rem',
                    },
                },
                '*': {
                    scrollbarWidth: 'thin',
                    scrollbarColor: `#888888 #595959`,
                    '&::-webkit-scrollbar': {
                        width: '12px',
                        height: '12px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: DARK_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: LIGHT_THEME_COLORS.SECONDARY_MAIN_COLOR,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1.6rem',
                    textTransform: 'none',
                    fontWeight: 400,
                    borderRadius: 2,
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '1.4rem!important',
                },
            },
        },
    },
};

export const lightTheme: Theme = createTheme({
    ...mainTheme,
    palette: {
        mode: THEME_MODES.LIGHT,
        primary: {
            main: LIGHT_THEME_COLORS.PRIMARY_MAIN_COLOR,
            light: LIGHT_THEME_COLORS.PRIMARY_LIGHT_COLOR,
            dark: LIGHT_THEME_COLORS.PRIMARY_DARK_COLOR,
            contrastText: LIGHT_THEME_COLORS.PRIMARY_CONTRAST_TEXT,
        },
        secondary: {
            main: LIGHT_THEME_COLORS.SECONDARY_MAIN_COLOR,
            light: LIGHT_THEME_COLORS.SECONDARY_LIGHT_COLOR,
            dark: LIGHT_THEME_COLORS.SECONDARY_DARK_COLOR,
            contrastText: LIGHT_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
        },
        info: {
            main: LIGHT_THEME_COLORS.INFO_MAIN_COLOR,
            light: LIGHT_THEME_COLORS.INFO_LIGHT_COLOR,
            dark: LIGHT_THEME_COLORS.INFO_DARK_COLOR,
            contrastText: LIGHT_THEME_COLORS.INFO_CONTRAST_TEXT,
        },
        error: {
            main: LIGHT_THEME_COLORS.ERROR_MAIN_COLOR,
            light: LIGHT_THEME_COLORS.ERROR_LIGHT_COLOR,
        },
    },
    components: {
        ...mainTheme.components,
        MuiLink: {
            styleOverrides: {
                root: {
                    cursor: 'pointer',
                    color: LIGHT_THEME_COLORS.INFO_MAIN_COLOR,
                    textDecoration: 'underline',
                },
            },
        },
    },
});

export const darkTheme: Theme = createTheme({
    ...mainTheme,
    palette: {
        mode: THEME_MODES.DARK,
        primary: {
            main: DARK_THEME_COLORS.PRIMARY_MAIN_COLOR,
            light: DARK_THEME_COLORS.PRIMARY_LIGHT_COLOR,
            dark: DARK_THEME_COLORS.PRIMARY_DARK_COLOR,
            contrastText: DARK_THEME_COLORS.PRIMARY_CONTRAST_TEXT,
        },
        secondary: {
            main: DARK_THEME_COLORS.SECONDARY_MAIN_COLOR,
            light: DARK_THEME_COLORS.SECONDARY_LIGHT_COLOR,
            dark: DARK_THEME_COLORS.SECONDARY_DARK_COLOR,
            contrastText: DARK_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
        },
        info: {
            main: DARK_THEME_COLORS.INFO_MAIN_COLOR,
            light: DARK_THEME_COLORS.INFO_LIGHT_COLOR,
            dark: DARK_THEME_COLORS.INFO_DARK_COLOR,
            contrastText: DARK_THEME_COLORS.INFO_CONTRAST_TEXT,
        },
        error: {
            main: DARK_THEME_COLORS.ERROR_MAIN_COLOR,
            light: DARK_THEME_COLORS.ERROR_LIGHT_COLOR,
        },
    },
    components: {
        ...mainTheme.components,
        MuiLink: {
            styleOverrides: {
                root: {
                    cursor: 'pointer',
                    color: DARK_THEME_COLORS.INFO_CONTRAST_TEXT,
                    textDecoration: 'underline',
                },
            },
        },
    },
});

export const themes = {
    lightTheme: responsiveFontSizes(lightTheme),
    darkTheme: responsiveFontSizes(darkTheme),
};
