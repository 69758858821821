import { GlobalStoreActions } from 'src/shared/constants';
import {
    GlobalStoreActionsType,
    ConfirmationInsuranceSearchAction,
    UserManagementSearchValue,
    UserManagementSelectUser,
    UserManagementChangePage,
    UserManagementChangeRowsPerPage,
    UmUserDto,
} from 'src/shared/types';
import { GlobalStoreType } from './global-store';

export function globalStoreReducer(state: GlobalStoreType, action: GlobalStoreActionsType) {
    switch (action.type) {
        case GlobalStoreActions.updateConfirmationInsurance:
            return { ...state, needToRefetchGlobalConfirmations: true };
        case GlobalStoreActions.resetConfirmationInsurance:
            return { ...state, needToRefetchGlobalConfirmations: false };
        case GlobalStoreActions.changeConfirmationInsuranceSearchValue:
            return {
                ...state,
                insuranceConfirmationSearch: {
                    value: (action as ConfirmationInsuranceSearchAction).payload.value,
                },
            };
        case GlobalStoreActions.changeUserManagementSearchValue:
            return {
                ...state,
                userManagement: {
                    ...state.userManagement,
                    page: 0,
                    searchValue: (action as UserManagementSearchValue).payload.value,
                },
            };
        case GlobalStoreActions.selectUser:
            return {
                ...state,
                userManagement: {
                    ...state.userManagement,
                    selectedUser: (action as UserManagementSelectUser).payload.value,
                },
            };
        case GlobalStoreActions.changeUsersListPage:
            return {
                ...state,
                userManagement: {
                    ...state.userManagement,
                    page: (action as UserManagementChangePage).payload.value,
                    usersList: [] as UmUserDto[],
                },
            };
        case GlobalStoreActions.changeUsersListRowsPerPage:
            return {
                ...state,
                userManagement: {
                    ...state.userManagement,
                    page: 0,
                    rowsPerPage: (action as UserManagementChangeRowsPerPage).payload.value,
                },
            };
        default:
            throw new Error();
    }
}
