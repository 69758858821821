import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useThemeSearchFieldInputStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '100%',
            height: 50,
            backgroundColor: theme.palette.secondary.contrastText,
            borderRadius: 0,
            [theme.breakpoints.down('md')]: {
                height: 40,
            },
        },
        focused: {
            '& fieldSet': {
                borderColor: `${theme.palette.secondary.main}!important`,
            },
        },
        input: {
            height: 50,
            boxSizing: 'border-box',
            color: theme.palette.secondary.main,
            '&::placeholder': {
                fontSize: '1.8rem',
            },
            [theme.breakpoints.down('md')]: {
                height: 40,
            },
        },
        btnClear: {
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            color: '#A9A9A9',
            '&:hover': {
                opacity: 0.75,
            },
            '&:active': {
                opacity: 0.5,
            },
            icon: {
                fontSize: 20,
            },
        },
    };
});

export const useFormControlStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            margin: `0 ${theme.spacing(3)}px 0 0`,
            '& label': {
                fontSize: '2.4rem',
            },
        },
    };
});
