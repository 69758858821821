import React, { ReactElement } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { TextField } from 'src/components/fields';
import { usePhoneFieldStyles } from './PhoneFieldStyles';

interface PhoneFieldProps {
    id: string;
    value: string;
    name: string;
    onChange: (name: string, value: string) => void;
    placeholder?: string;
    disabled?: boolean;
    error?: boolean;
}

export const PhoneField = ({
    id,
    value,
    name,
    onChange,
    placeholder,
    disabled,
    error,
}: PhoneFieldProps): ReactElement => {
    const classes = usePhoneFieldStyles();

    const handleChange = (phone: string): void => {
        onChange(name, phone);
    };

    return (
        <TextField
            id={id}
            disabled={disabled}
            value={value}
            name={name}
            inputComponent={PhoneInput as any}
            placeholder={placeholder}
            error={Boolean(error)}
            inputProps={{
                value,
                className: classes.phoneNumber,
                country: 'de',
                onChange: handleChange,
            }}
        />
    );
};
