import React from 'react';
import { ReactElement } from 'react';

import { AuthLayout } from 'src/components/layouts';
import { ForgotPasswordForm } from 'src/components/forms';

export const ForgotPasswordPage = (): ReactElement => {
    return (
        <AuthLayout>
            <ForgotPasswordForm />
        </AuthLayout>
    );
};
