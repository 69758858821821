import { VeelaMessagesTableKeys } from './raw-veela-messages-table-config';

export const VeelaMessagesDefaultOrderColumn = {
    [VeelaMessagesTableKeys.timeStamp]: 0,
    [VeelaMessagesTableKeys.authInProgressAt]: 1,
    [VeelaMessagesTableKeys.insuranceInsuranceStartingDate]: 2,
    [VeelaMessagesTableKeys.licenseTimeOfAllocation]: 3,
    [VeelaMessagesTableKeys.vuMessageType]: 4,
    [VeelaMessagesTableKeys.vuCancellation]: 5,
    [VeelaMessagesTableKeys.vuHolderChange]: 6,
    [VeelaMessagesTableKeys.vuInsuranceChange]: 7,
    [VeelaMessagesTableKeys.vuLicenseNumberChange]: 8,
    [VeelaMessagesTableKeys.vuVehicleClassChange]: 9,
    [VeelaMessagesTableKeys.vuOldtimerChange]: 10,
    [VeelaMessagesTableKeys.vuOldtimerChange]: 11,
    [VeelaMessagesTableKeys.vuElectricLicenseFeatureChange]: 12,
    [VeelaMessagesTableKeys.insuranceConfirmationId]: 13,
    [VeelaMessagesTableKeys.licenseLicenseNumber]: 14,
    [VeelaMessagesTableKeys.licenseLicenseTypeFeatures]: 15,
    [VeelaMessagesTableKeys.vehicleVehicleUsage]: 16,
    [VeelaMessagesTableKeys.vehicleKeyManufacturer]: 17,
    [VeelaMessagesTableKeys.vehicleKeyType]: 18,
    [VeelaMessagesTableKeys.vehicleManufacturer]: 19,
    [VeelaMessagesTableKeys.vehicleKeyVehicleClass]: 20,
    [VeelaMessagesTableKeys.vehicleVehicleIdentificationNumber]: 21,
    [VeelaMessagesTableKeys.vehicleDisplacement]: 22,
    [VeelaMessagesTableKeys.vehicleRatedCapacityKw]: 23,
    [VeelaMessagesTableKeys.vehicleKeyStructure]: 24,
    [VeelaMessagesTableKeys.vehicleVehicleTypeText]: 25,
    [VeelaMessagesTableKeys.vehicleTextStructure]: 26,
    [VeelaMessagesTableKeys.licenseVariableLicenseFeatures]: 27,
    [VeelaMessagesTableKeys.licenseElectricLicenseFeatures]: 28,
    [VeelaMessagesTableKeys.holderPolicyHolderDeviation]: 29,
    [VeelaMessagesTableKeys.holderJrstPersonPersonName]: 30,
    [VeelaMessagesTableKeys.holderNtrlPersonSurName]: 31,
    [VeelaMessagesTableKeys.holderNtrlPersonFirstName]: 32,
    [VeelaMessagesTableKeys.holderNtrlPersonGender]: 33,
    [VeelaMessagesTableKeys.holderAddressPostalCode]: 34,
    [VeelaMessagesTableKeys.holderAddressPlaceOfResidence]: 35,
    [VeelaMessagesTableKeys.holderAddressStreet]: 36,
    [VeelaMessagesTableKeys.holderAddressHouseNumber]: 37,
    [VeelaMessagesTableKeys.holderAddressAdditions]: 38,
    [VeelaMessagesTableKeys.insuranceInsuranceCompanyNumber]: 39,
    [VeelaMessagesTableKeys.insuranceOfficeInsurance]: 40,
    [VeelaMessagesTableKeys.insuranceAgencyInsurance]: 41,
    [VeelaMessagesTableKeys.insuranceInternInsuranceNotes]: 42,
    [VeelaMessagesTableKeys.insuranceCompanyType]: 43,
    [VeelaMessagesTableKeys.requestNr]: 44,
    [VeelaMessagesTableKeys.techCommunicationPartnerRecognition]: 45,
    [VeelaMessagesTableKeys.techMessageGeneratedTimeStamp]: 46,
    [VeelaMessagesTableKeys.techSoftwareVersion]: 47,
    [VeelaMessagesTableKeys.messageId]: 48,
    [VeelaMessagesTableKeys.operatingMode]: 49,
    [VeelaMessagesTableKeys.messageType]: 50,
    [VeelaMessagesTableKeys.vehicleVehicleType]: 51,
    [VeelaMessagesTableKeys.vehicleKeyVehicleClass]: 52,
    [VeelaMessagesTableKeys.vehicleKeyStructure]: 53,
    [VeelaMessagesTableKeys.authCompetentAuthorityDistrictKey]: 54,
    [VeelaMessagesTableKeys.authCompetentAuthorityAdditionalDigit]: 55,
};
