import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { CreatePasswordFormBody } from 'src/shared/types';
import { useQuery } from 'src/shared/hooks';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { AppRoutes } from 'src/routing/app-routes';

interface UseCreatePasswordReturn {
    isLoading: boolean;
    createPassword: (vals: CreatePasswordFormBody) => Promise<void>;
}

const translationNamespaces = ['login', 'serverErrors'];

export const useCreatePassword = (): UseCreatePasswordReturn => {
    const { t } = useTranslation(translationNamespaces);
    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState<boolean>(false);

    const token = useQuery().get('token');
    const type = useQuery().get('type');

    const createPassword = async (vals: CreatePasswordFormBody): Promise<void> => {
        try {
            setLoading(true);
            await httpClient.put(type === 'reset' ? 'confirm-reset-password' : 'confirmEmail', {
                token,
                password: vals.password,
            });
            enqueueSnackbar(t('login:passwordCreatedSuccessfully'), {
                variant: 'success',
            });
            navigate(AppRoutes.LOGIN);
        } catch {
            enqueueSnackbar(t('serverErrors:unknownError'), {
                variant: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    return {
        isLoading,
        createPassword,
    };
};
