import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyle = makeStyles((theme: Theme) => {
    return {
        pagination: {
            width: '100%',
            height: 60,
            padding: `0 ${theme.spacing(3)}!important`,
            marginLeft: 'auto',
            display: 'block',
            borderBottom: 'none',
            fontSize: '1.2rem',
            overflowY: 'hidden',

            [theme.breakpoints.down('xl')]: {
                height: 45,
                paddingLeft: `0!important`,
            },

            '& .MuiTypography-root': {
                fontSize: '1.2rem',
            },

            '& .MuiTablePagination-spacer': {
                flex: 0,
            },

            '& .MuiButtonBase-root': {
                paddingRight: theme.spacing(0),
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
            '& .MuiSelect-iconOpen': {
                transform: 'rotate(180deg)',
            },
            '& .MuiTablePagination-select': {
                [theme.breakpoints.down('xl')]: {
                    fontSize: '1.4rem',
                },
            },

            '& .MuiTablePagination-selectRoot': {
                fontWeight: 'normal',
                marginRight: 'auto',
            },
            '& .MuiSelect-nativeInput': {
                height: '100%',
            },
            '& .MuiTablePagination-selectIcon': {
                top: 'auto',
                bottom: 'auto',
            },

            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
            },
            '& .MuiToolbar-root': {
                [theme.breakpoints.down('xl')]: {
                    minHeight: 45,
                    '& p': {
                        fontSize: '1.4rem!important',
                    },
                },
            },
        },
    };
});

export const usePaginationStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '100%',
            marginLeft: theme.spacing(5),
        },
    };
});

export const useRowsPerPageMenuStyle = makeStyles((theme: Theme) => {
    return {
        paper: {
            backgroundColor: theme.palette.primary.main,
        },
        list: {
            padding: 0,
            '& .MuiListItem-root': {
                display: 'flex',
                justifyContent: 'center',
                padding: theme.spacing(1),
                fontSize: '1.2rem',
            },
        },
    };
});
