import { useCallback, useEffect, useState } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { DynamicPropertyMultiFilter } from 'src/lib/utility/dynamic-property-multi-filter';
import { GlobalStoreActions } from 'src/shared/constants';
import { useGlobalStore } from 'src/shared/contexts';
import { UseGetEntityItemsReturn } from 'src/shared/hooks';
import { VeelaMessage } from '../veela-message';

const defaultData: { items: any[]; total: number } = { items: [], total: 0 };

interface GetVeelaMessagesResponseDto {
    items: VeelaMessage[];
    total: number;
}

export const useGetVeelaMessages = (
    url: string,
    request: GetVeelaMessages
): UseGetEntityItemsReturn<VeelaMessage> => {
    const [data, setData] = useState<{ items: VeelaMessage[]; total: number }>(defaultData);
    const [isLoading, setIsLoading] = useState(false);

    const { dispatch } = useGlobalStore();

    const httpClient = useHttpClient();

    const getEntities = useCallback(async (): Promise<void> => {
        try {
            setIsLoading(true);

            const data = await httpClient.post<GetVeelaMessagesResponseDto>(url, request);
            setData(data || defaultData);
            if (dispatch) {
                dispatch({ type: GlobalStoreActions.resetConfirmationInsurance });
            }
        } catch {
            setData(defaultData);
        } finally {
            setIsLoading(false);
        }
    }, [JSON.stringify(request)]);

    useEffect(() => {
        getEntities();
    }, [getEntities]);

    useEffect(() => {
        return () => {
            if (dispatch) {
                dispatch({ type: GlobalStoreActions.updateConfirmationInsurance });
            }
        };
    }, []);

    return { isLoading, data, getEntities };
};

export interface GetVeelaMessages {
    sortField?: string;
    isAscending: boolean;
    searchTerm?: string;
    skip: number;
    take: number;
    filters: DynamicPropertyMultiFilter[];
}
