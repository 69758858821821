import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { ConnectivityService } from '../connectivity-indicator/use-connectivity-service';
import { ComponentUnloadState } from '../custom-hooks/use-component-unload-state';
import { LoadingState } from '../custom-hooks/use-loading-state';
import { HttpRejectInfo } from './http-reject-info'; // data can be any

export interface HttpRequestOptions {
    isStandardErrorHandlingDisabled?: boolean;
    axiosConfig?: AxiosRequestConfig;
}

type ResolvePromise<T> = (value: T | PromiseLike<T>) => void;
type RejectPromise = (reason?: HttpRejectInfo) => void;
type AxiosCall<T> = () => Promise<AxiosResponse<T>>;

export interface HttpClient {
    readonly isPerformingRequest: boolean;

    get<T>(url: string, options?: HttpRequestOptions): Promise<T>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    post<T>(url: string, data?: any, options?: HttpRequestOptions): Promise<T>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    put<T>(url: string, data?: any, options?: HttpRequestOptions): Promise<T>;

    delete<T>(url: string, options?: HttpRequestOptions): Promise<T>;

    downloadFile(url: string, data?: any): Promise<void>;
}

export class AxiosHttpClient implements HttpClient {
    connectivityService?: ConnectivityService;

    constructor(
        private readonly axios: AxiosInstance,
        private readonly loadingState: LoadingState,
        private readonly componentUnloadState: ComponentUnloadState
    ) {}

    get isPerformingRequest(): boolean {
        return this.loadingState.isLoading;
    }

    async get<T>(url: string, options?: HttpRequestOptions): Promise<T> {
        const response = await this.tryPerformRequest<T>(
            () => this.axios.get(url, options?.axiosConfig),
            options
        );
        return response?.data;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async post<T>(url: string, data?: any, options?: HttpRequestOptions): Promise<T> {
        const response = await this.tryPerformRequest<T>(
            () => this.axios.post(url, data, options?.axiosConfig),
            options
        );
        return response?.data;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async put<T>(url: string, data?: any, options?: HttpRequestOptions): Promise<T> {
        const response = await this.tryPerformRequest<T>(
            () => this.axios.put(url, data, options?.axiosConfig),
            options
        );
        return response?.data;
    }

    async delete<T>(url: string, options?: HttpRequestOptions): Promise<T> {
        const response = await this.tryPerformRequest<T>(
            () => this.axios.delete(url, options?.axiosConfig),
            options
        );
        return response?.data;
    }

    async downloadFile(url: string, data?: any, options?: HttpRequestOptions): Promise<void> {
        const response = await this.tryPerformRequest<any>(
            () => this.axios.post(url, data, { responseType: 'blob' }),
            options
        );

        if (!response || !response.data || response.status !== 200) {
            return;
        }

        const contentDisposition: string = response.headers['content-disposition'];

        if (typeof contentDisposition === 'string') {
            const filename = contentDisposition
                ?.split(';')
                ?.find((n: string) => n.includes('filename='))
                ?.replace('filename=', '')
                ?.replaceAll('"', '')
                ?.trim();

            saveAs(response.data, filename);
        }
    }

    private tryPerformRequest<T>(
        axiosCall: AxiosCall<T>,
        options: HttpRequestOptions | undefined
    ): Promise<AxiosResponse<T>> {
        return new Promise<AxiosResponse<T>>((resolve, reject) => {
            axiosCall()
                .then((response) => this.handleResponse(response, resolve))
                .catch((error: AxiosError) => {
                    this.handleError(error, !!options?.isStandardErrorHandlingDisabled, reject);
                })
                .finally(() => this.resetLoadingState());
        });
    }

    private handleResponse<T>(
        response: AxiosResponse<T>,
        resolve: ResolvePromise<AxiosResponse<T>>
    ): void {
        this.connectivityService?.reportConnectionSuccess();
        if (this.componentUnloadState.isComponentUnloaded) {
            return;
        }

        resolve(response);
    }

    private handleError(
        error: AxiosError,
        isStandardErrorHandlingDisabled: boolean,
        reject: RejectPromise
    ): void {
        if (isStandardErrorHandlingDisabled) reject(HttpRejectInfo.notHandled(error));

        if (
            this.tryHandleErrorResponse(error, reject) ||
            this.tryHandleMissingResponse(error, reject)
        ) {
            return;
        }

        this.handleErroneousRequest(error, reject);
    }

    private tryHandleErrorResponse(error: AxiosError, reject: RejectPromise): boolean {
        const response = error.response;
        if (!response) {
            return false;
        }

        if (response.status === 500) {
            reject(HttpRejectInfo.handled500InternalServerError(error));
        } else if (response.status === 404) {
            reject(HttpRejectInfo.handled404NotFound(error));
        } else {
            reject(HttpRejectInfo.notHandled(error));
        }

        this.connectivityService?.reportConnectionSuccess();
        return true;
    }

    private tryHandleMissingResponse(error: AxiosError, reject: RejectPromise): boolean {
        const request = error.request;
        if (!request) {
            return false;
        }

        this.connectivityService?.reportConnectionError();
        reject(HttpRejectInfo.handledServerNotReachable(error));
        return true;
    }

    private handleErroneousRequest(error: AxiosError, reject: RejectPromise): void {
        // eslint-disable-next-line no-console
        console.error(typeof error.toJSON === 'function' ? error.toJSON() : error);

        reject(HttpRejectInfo.handledErroneousRequest(error));
    }

    private resetLoadingState(): void {
        this.loadingState.isLoading = false;
    }
}
