import React, {
    ReactElement,
    memo,
    BaseSyntheticEvent,
    ComponentType,
    forwardRef,
    ForwardedRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeHandler } from 'react-hook-form';

import { OutlinedInput, InputBaseComponentProps } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

import { useInputBaseStyles } from './TextFieldStyles';

export interface TextFieldProps<T> {
    id: string;
    placeholder?: string;
    label?: string;
    inputProps?: T | InputBaseComponentProps;
    color?: OverridableStringUnion<
        'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
    >;
    translationNamespaces?: string[];
    helperText?: string;
    className?: string;
    name?: string;
    value?: string;
    onChange?: (name: string, value: string) => void;
    nativeOnChange?: ChangeHandler;
    error?: boolean;
    onFocus?: () => void;
    onBlur?: (() => void) | ChangeHandler;
    required?: boolean;
    disabled?: boolean;
    startAdornment?: ReactElement;
    inputComponent?: ComponentType;
    multiline?: boolean;
    rows?: number;
}

const BaseTextField = forwardRef(function BaseTextField<T>(
    {
        inputProps,
        id,
        color,
        translationNamespaces,
        placeholder,
        name,
        value,
        onChange,
        disabled,
        label,
        nativeOnChange,
        multiline,
        rows,
        ...rest
    }: TextFieldProps<T>,
    ref: ForwardedRef<T>
): ReactElement {
    const inputBaseClasses = useInputBaseStyles();

    const { t } = useTranslation(translationNamespaces);

    const handleChange = (e: BaseSyntheticEvent): void => {
        if (typeof onChange === 'function' && name) {
            onChange(name, e.target.value);
        }
    };

    return (
        <OutlinedInput
            id={id}
            color={color}
            classes={inputBaseClasses}
            inputProps={inputProps}
            placeholder={placeholder ? t(placeholder) : ''}
            value={value}
            onChange={nativeOnChange || handleChange}
            disabled={disabled}
            label={label ? t(label) : ''}
            ref={ref}
            multiline={multiline}
            rows={rows}
            {...rest}
        />
    );
});

export const TextField = memo(BaseTextField) as typeof BaseTextField;
