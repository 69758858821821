import { ReactElement } from 'react';
import {
    Box,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import {
    useTableStyle,
    useTableHeaderStyle,
    useTableBodyStyle,
    useTableRowStyle,
} from '../process-dialog-table/process-dialog-table-styles';
import { ChangesDataItem } from 'src/shared/types/data-preparing';
import { useTranslation } from 'react-i18next';

interface ContractSelectorTableProps {
    contracts: ChangesDataItem[];
    selected: string;
    onSelect(contract: ChangesDataItem): void;
}

export function ContractSelectorTable({
    contracts,
    selected,
    onSelect,
}: ContractSelectorTableProps): ReactElement {
    const tableClasses = useTableStyle();
    const headerClasses = useTableHeaderStyle();
    const bodyClasses = useTableBodyStyle();
    const rowClasses = useTableRowStyle();

    const { t } = useTranslation(['processing']);

    const checkBoxCellStyle: React.CSSProperties = { maxWidth: '4%', width: '4%', minWidth: '4%' };
    const textCellStyle: React.CSSProperties = { maxWidth: '24%', width: '24%', minWidth: '24%' };

    return (
        <Box>
            <Box sx={{ display: 'flex', marginBottom: '8px' }}>
                <Typography>
                    {t('client')}: {contracts.at(0)?.contractData?.customer?.customer}
                </Typography>
                <div style={{ flex: '1 0 0' }} />
                <Typography>
                    {t('wkz')}: {contracts.at(0)?.contractData?.wkz}
                </Typography>
            </Box>
            <TableContainer>
                <Table stickyHeader classes={tableClasses} sx={{ tableLayout: 'fixed' }}>
                    <TableHead classes={headerClasses}>
                        <TableRow>
                            <TableCell style={checkBoxCellStyle}></TableCell>
                            <TableCell style={textCellStyle}>{t('minRatedCapacity')}</TableCell>
                            <TableCell style={textCellStyle}>{t('maxRatedCapacity')}</TableCell>
                            <TableCell style={textCellStyle}>{t('sb_vk')}</TableCell>
                            <TableCell style={textCellStyle}>{t('sb_tk')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody classes={bodyClasses}>
                        {contracts.map((contract) => {
                            return (
                                <TableRow
                                    classes={rowClasses}
                                    onClick={() => onSelect(contract)}
                                    role='checkbox'
                                    selected={selected === contract.contractData.id}
                                    key={contract.contractData.id}
                                    hover
                                >
                                    <TableCell padding='checkbox'>
                                        <Checkbox
                                            color='secondary'
                                            checked={selected === contract.contractData.id}
                                            inputProps={{
                                                'aria-labelledby':
                                                    'contract-selector-checkbox-' +
                                                    contract.contractData.id,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell
                                        component='th'
                                        id={
                                            'contract-selector-checkbox-' + contract.contractData.id
                                        }
                                        scope='row'
                                    >
                                        {contract.contractData.minRatedCapacity}
                                    </TableCell>
                                    <TableCell>{contract.contractData.maxRatedCapacity}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
