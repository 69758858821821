import React, { ReactElement } from 'react';

import { Grid } from '@mui/material';

import { useUserManagementStyles } from './user-management-layout-styles';

interface UserManagementLayoutProps {
    children: ReactElement[];
}

export const UserManagementLayout = ({ children }: UserManagementLayoutProps): ReactElement => {
    const classes = useUserManagementStyles();
    const [header, toolbar, usersHeader, list, form, footer] = children;

    return (
        <Grid container className={classes.container} direction='column'>
            <Grid item className={classes.header}>
                {header}
            </Grid>
            <Grid item container className={classes.navigationPanel}>
                {toolbar}
            </Grid>
            <Grid item className={classes.usersHeaderContainer}>
                {usersHeader}
            </Grid>
            <Grid item container component='main' className={classes.main}>
                <Grid item className={classes.usersListContainer}>
                    {list}
                </Grid>
                <Grid item className={classes.userFormContainer}>
                    {form}
                </Grid>
            </Grid>
            <Grid item className={classes.footer}>
                {footer}
            </Grid>
        </Grid>
    );
};
