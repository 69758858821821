import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    tooltip: {
        position: 'relative',
        top: '20px',
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        borderRadius: theme.spacing(2),
        fontSize: 14,
        padding: `${theme.spacing(2)}  ${theme.spacing(3)}`,
    },

    overflowChild: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
}));
