import { ReactElement } from 'react';
import { Box } from '@mui/material';
import { ChangesDataItem, ChangeType, GetDataPreparingDto } from 'src/shared/types/data-preparing';
import {
    DataKeyProperties,
    getContractDataKeys,
    getCustomerDataKeys,
    getVehicleDataKeys,
} from './category-data-keys';
import { ProcessChangesCategory } from './process-changes-category';
import { ProcessChangesContractCategory } from './process-changes-contract-category';
import { StringObjectPropertyIndex } from './stringObjectPropertyIndex';

interface ProcessChangesCategoriesViewProps {
    dto: GetDataPreparingDto;
    openContractSelector(): void;
    onValueChanging(
        dataKey: DataKeyProperties,
        data: StringObjectPropertyIndex,
        newValue: string
    ): void;
}

export function ProcessChangesCategoriesView({
    dto,
    openContractSelector,
    onValueChanging,
}: ProcessChangesCategoriesViewProps): ReactElement {
    const categories: JSX.Element[] = [];

    let headers: string[] = [];
    let frameworkData: ChangesDataItem | undefined = undefined;
    if (
        dto.changeType == ChangeType.VehicleClass ||
        dto.changeType == ChangeType.Renewal ||
        dto.changeType == ChangeType.Usage
    ) {
        headers = ['description', 'amsValue', 'frameworkValue', 'localValue'];
        frameworkData = dto.frameworkContracts.find((contract) => {
            return (
                contract.contractData !== undefined &&
                contract.contractData.id === dto.selectedFrameworkContract
            );
        });
    }
    if (
        dto.changeType == ChangeType.Address ||
        dto.changeType === ChangeType.LicenseNumberExtended ||
        dto.changeType === ChangeType.LicenseNumberSeason
    ) {
        headers = ['description', 'amsValue', 'evbValue', 'localValue'];
        frameworkData = dto.evbData;
    }

    const contractDataCategory = (
        <ProcessChangesContractCategory
            name={'contractData'}
            headers={headers}
            amsData={dto.amsData.contractData}
            frameworkData={frameworkData?.contractData}
            localData={dto.localData.contractData}
            canSelectFrameworkContract={dto.frameworkContracts.length > 1}
            dataKeys={getContractDataKeys(dto.readOnly)}
            contractSelected={dto.selectedFrameworkContract !== '-1'}
            readOnly={dto.readOnly}
            openContractSelector={openContractSelector}
            onValueChanging={onValueChanging}
        />
    );

    categories.push(contractDataCategory);

    const customerDataCategory = (
        <ProcessChangesCategory
            name={'customerData'}
            headers={headers}
            amsData={dto.amsData.contractData?.customer1}
            frameworkData={frameworkData?.contractData?.customer1}
            localData={dto.localData.contractData?.customer1}
            dataKeys={getCustomerDataKeys(dto.readOnly)}
            contractSelected={dto.selectedFrameworkContract !== '-1'}
            readOnly={dto.readOnly}
            openContractSelector={openContractSelector}
            onValueChanging={onValueChanging}
        />
    );

    categories.push(customerDataCategory);

    if (dto.evbData.contractData.hasDeviantHolder || dto.amsData.contractData.hasDeviantHolder) {
        const holderDataCategory = (
            <ProcessChangesCategory
                name={'holderData'}
                headers={headers}
                amsData={dto.amsData.contractData?.deviantHolder}
                frameworkData={frameworkData?.contractData?.deviantHolder}
                localData={dto.localData.contractData?.deviantHolder}
                dataKeys={getCustomerDataKeys(dto.readOnly)}
                contractSelected={dto.selectedFrameworkContract !== '-1'}
                readOnly={dto.readOnly}
                openContractSelector={openContractSelector}
                onValueChanging={onValueChanging}
            />
        );

        categories.push(holderDataCategory);
    }

    if (
        dto.changeType === ChangeType.VehicleClass ||
        dto.changeType === ChangeType.Usage ||
        dto.changeType === ChangeType.LicenseNumberExtended ||
        dto.changeType === ChangeType.LicenseNumberSeason
    ) {
        const vehicleDataCategory = (
            <ProcessChangesCategory
                name={'vehicleData'}
                headers={headers}
                amsData={dto.amsData.vehicleData}
                frameworkData={frameworkData?.vehicleData}
                localData={dto.localData.vehicleData}
                dataKeys={getVehicleDataKeys(dto.readOnly)}
                contractSelected={dto.selectedFrameworkContract !== '-1'}
                readOnly={dto.readOnly}
                openContractSelector={openContractSelector}
                onValueChanging={onValueChanging}
            />
        );

        categories.push(vehicleDataCategory);
    }

    return <Box>{categories}</Box>;
}
