import { Box, Link, Typography } from '@mui/material';
import { ReactElement } from 'react';

interface OpenProcessDialogCellProps {
    name: string;
    dialog: JSX.Element;
    onOpen(): void;
}

export const OpenProcessDialogCell = ({
    name,
    dialog,
    onOpen,
}: OpenProcessDialogCellProps): ReactElement => {
    return (
        <Box>
            <Link onClick={onOpen}>
                <Typography noWrap variant='body2'>
                    {name}
                </Typography>
            </Link>
            {dialog}
        </Box>
    );
};
