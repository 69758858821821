import { ReactElement } from 'react';
import { TFunction } from 'i18next';
import { Column, Row, UseTableCellProps } from 'react-table';
import {
    CellWithTooltip,
    CellWithCommands,
    StatusCell,
    Status,
} from 'src/components/base/custom-table/custom-table-elements';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { withDraggable } from 'src/shared/hocs';
import { RegistrationItem, RegistrationResult } from 'src/shared/types/registration-dto';
import { useTranslation } from 'react-i18next';
import RawXmlDialog from 'src/components/base/raw-xml-dialog';
import React from 'react';
import { XmlElement, XmlTree } from 'src/components/base/xml-editor';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

export enum NewRegistrationTableKeys {
    id = 'id',
    insuranceConfirmationNumber = 'insuranceConfirmationNumber',
    contractNumber = 'contractNumber',
    licenseNumber = 'licenseNumber',
    customer = 'customer',
    result = 'result',
    internalInsuranceNotes = 'internalInsuranceNotes',
    customerRelation = 'customerRelation',
    risk = 'risk',
    date = 'inProgressAt',
    startOfContract = 'insuranceStartingDate',
    postcode = 'postalCode',
    place = 'placeOfResidence',
    street = 'street',
    houseNumber = 'houseNumber',
    owner = 'personName',
    commands = 'commands',
}

interface GenerateNewRegistrationTableConfigParams {
    t: TFunction;
    startPosition: number | undefined;
    hoverPosition: number | undefined;
    columnPositions: { [key: string]: number };
    isDndLocked: boolean;
}

interface MyCellProps {
    row: Row<object>;
}

const MyCellWithCommands = ({ row }: MyCellProps): ReactElement => {
    const [xml, setXml] = React.useState<XmlTree | undefined>(undefined);
    const [rawXml, setRawXml] = React.useState<string | undefined>(undefined);
    const [open, setOpen] = React.useState<boolean>(false);
    const [xmlIsLoading, setXmlIsLoading] = React.useState<boolean>(false);

    const httpClient = useHttpClient();
    const { t } = useTranslation(['common']);

    const handleOpen = async (insuranceId: string) => {
        setOpen(true);
        setXmlIsLoading(true);
        const xml = await httpClient.get<string>('insuranceConfirmationSource', {
            axiosConfig: { params: { insuranceConfirmationId: insuranceId } },
        });

        if (xml === undefined || xml === null || xml === '') {
            setRawXml(undefined);
            setXml(undefined);
        } else {
            setRawXml(xml);
            const tree = new XmlTree(xml);
            setXml(tree);
        }
        setXmlIsLoading(false);
    };

    const handleChanged = (element: XmlElement) => {
        if (xml === undefined || !xml.elements.has(element.id)) {
            return;
        }
        xml.elements.set(element.id, element);
        setXml(xml);
    };

    const handleUpload = async () => {
        if (xml === undefined) {
            return;
        }
        const rawXml = xml.toString();
        await httpClient.post('uploadInsuranceConfirmation', { xml: rawXml });
    };

    const handleClose = () => {
        setOpen(false);
        setXml(undefined);
        setRawXml(undefined);
    };
    return (
        <CellWithCommands
            insuranceId={row.id}
            commands={[
                {
                    name: 'source',
                    onOpen: async (e, insuranceId) => {
                        await handleOpen(insuranceId);
                    },
                    uiComponents: [
                        <RawXmlDialog
                            key={'rawXmlDialog'}
                            name={row.values['insuranceConfirmationNumber']}
                            open={open}
                            xmlTree={xml}
                            xmlString={rawXml}
                            isLoading={xmlIsLoading}
                            onClose={handleClose}
                            onUpload={handleUpload}
                            onXmlElementChanged={handleChanged}
                        />,
                    ],
                    tooltip: t('rawXmlSource'),
                    icon: <InsertDriveFileOutlinedIcon fontSize='inherit' />,
                },
            ]}
        />
    );
};

const DraggableCell = withDraggable<{ value: string; column: Column }>((props) => (
    <CellWithTooltip {...props} />
));

export const generateNewRegistrationTableConfig = ({
    t,
    startPosition,
    hoverPosition,
    columnPositions,
    isDndLocked,
}: GenerateNewRegistrationTableConfigParams): Array<Column<object>> => {
    return [
        {
            id: NewRegistrationTableKeys.insuranceConfirmationNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:evbNr')}
                    draggableId={NewRegistrationTableKeys.insuranceConfirmationNumber}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={
                        columnPositions[NewRegistrationTableKeys.insuranceConfirmationNumber]
                    }
                />
            ),
            accessor: NewRegistrationTableKeys.insuranceConfirmationNumber,
            Cell: CellWithTooltip,
            width: 110,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.licenseNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:licenseNumber')}
                    draggableId={NewRegistrationTableKeys.licenseNumber}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.licenseNumber]}
                />
            ),
            accessor: NewRegistrationTableKeys.licenseNumber,
            Cell: CellWithTooltip,
            disableSortBy: !isDndLocked,
            width: 120,
        },
        {
            id: NewRegistrationTableKeys.internalInsuranceNotes,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:internalInsuranceNotes')}
                    draggableId={NewRegistrationTableKeys.internalInsuranceNotes}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={
                        columnPositions[NewRegistrationTableKeys.internalInsuranceNotes]
                    }
                />
            ),
            accessor: NewRegistrationTableKeys.internalInsuranceNotes,
            Cell: CellWithTooltip,
            disableSortBy: !isDndLocked,
            width: 150,
        },
        {
            id: NewRegistrationTableKeys.customerRelation,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:customerRelation')}
                    draggableId={NewRegistrationTableKeys.customerRelation}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.customerRelation]}
                />
            ),
            accessor: NewRegistrationTableKeys.customerRelation,
            Cell: CellWithTooltip,
            width: 120,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.customer,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:customer')}
                    draggableId={NewRegistrationTableKeys.customer}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.customer]}
                />
            ),
            accessor: NewRegistrationTableKeys.customer,
            Cell: CellWithTooltip,
            width: 100,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.contractNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:contractNumber')}
                    draggableId={NewRegistrationTableKeys.contractNumber}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.contractNumber]}
                />
            ),
            accessor: NewRegistrationTableKeys.contractNumber,
            Cell: CellWithTooltip,
            width: 100,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.risk,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:risk')}
                    draggableId={NewRegistrationTableKeys.risk}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.risk]}
                />
            ),
            accessor: NewRegistrationTableKeys.risk,
            Cell: CellWithTooltip,
            width: 70,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.date,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:date')}
                    draggableId={NewRegistrationTableKeys.date}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.date]}
                />
            ),
            accessor: NewRegistrationTableKeys.date,
            Cell: (props) => (
                <CellWithTooltip
                    {...props}
                    valueFormatter={(date) => {
                        return t('formatted-values:formattedDate', {
                            date,
                        })?.replaceAll('&#x2F;', '/');
                    }}
                />
            ),
            width: 100,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.startOfContract,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:startOfContract')}
                    draggableId={NewRegistrationTableKeys.startOfContract}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.startOfContract]}
                />
            ),
            accessor: NewRegistrationTableKeys.startOfContract,
            Cell: (props) => (
                <CellWithTooltip
                    {...props}
                    valueFormatter={(date) => {
                        return t('formatted-values:formattedDate', {
                            date,
                        })?.replaceAll('&#x2F;', '/');
                    }}
                />
            ),
            width: 100,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.postcode,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:postcode')}
                    draggableId={NewRegistrationTableKeys.postcode}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.postcode]}
                />
            ),
            accessor: NewRegistrationTableKeys.postcode,
            Cell: CellWithTooltip,
            width: 60,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.place,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:place')}
                    draggableId={NewRegistrationTableKeys.place}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.place]}
                />
            ),
            accessor: NewRegistrationTableKeys.place,
            Cell: CellWithTooltip,
            width: 95,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.street,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:street')}
                    draggableId={NewRegistrationTableKeys.street}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.street]}
                />
            ),
            accessor: NewRegistrationTableKeys.street,
            Cell: CellWithTooltip,
            width: 80,
            disableSortBy: true,
        },
        {
            id: NewRegistrationTableKeys.houseNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:houseNumber')}
                    draggableId={NewRegistrationTableKeys.houseNumber}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.houseNumber]}
                />
            ),
            accessor: NewRegistrationTableKeys.houseNumber,
            Cell: CellWithTooltip,
            width: 50,
            disableSortBy: true,
        },
        {
            id: NewRegistrationTableKeys.owner,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:owner')}
                    draggableId={NewRegistrationTableKeys.owner}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.owner]}
                />
            ),
            accessor: NewRegistrationTableKeys.owner,
            Cell: CellWithTooltip,
            width: 140,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.result,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:result')}
                    draggableId={NewRegistrationTableKeys.result}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.result]}
                />
            ),
            accessor: NewRegistrationTableKeys.result,
            Cell: (props: UseTableCellProps<RegistrationItem>) => {
                let value = props.value;
                if (props.value === null || props.value === undefined) {
                    value = -1;
                }
                return (
                    <StatusCell
                        statusText={t('new-registration:' + RegistrationResult[value])}
                        status={
                            value === RegistrationResult.FrameworkContractFound ||
                            value === RegistrationResult.AmsContractFound
                                ? Status.Success
                                : Status.Failure
                        }
                        maxWidth={props.column.width}
                    />
                );
            },
            width: 275,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.commands,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={''}
                    draggableId={NewRegistrationTableKeys.commands}
                    isDragAndDropLocked={isDndLocked}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.commands]}
                />
            ),
            accessor: NewRegistrationTableKeys.commands,
            Cell: MyCellWithCommands,
            width: 50,
            disableSortBy: true,
        },
    ];
};
