import { TFunction } from 'i18next';
import _ from 'lodash';
import { useEffect, useState, useMemo, useCallback, Dispatch, SetStateAction } from 'react';
import { CheckboxItem } from 'src/components/base';
import { TableColumnSettings, TableSettingsDto } from 'src/shared/types';
import { VeelaMessagesTableKeys } from '../raw-veela-messages-table-config';

const defaultState = [
    {
        title: 'veela-messages:Header_MessageType',
        value: VeelaMessagesTableKeys.messageType,
        checked: true,
    },
    {
        title: 'veela-messages:Header_OperatingMode',
        value: VeelaMessagesTableKeys.operatingMode,
        checked: true,
    },
    {
        title: 'veela-messages:TechnicalSender_CommunicationPartnerRecognition',
        value: VeelaMessagesTableKeys.techCommunicationPartnerRecognition,
        checked: true,
    },
    {
        title: 'veela-messages:TechnicalSender_SoftwareVersion',
        value: VeelaMessagesTableKeys.techSoftwareVersion,
        checked: true,
    },
    {
        title: 'veela-messages:TechnicalSender_MessageGeneratedTimeStamp',
        value: VeelaMessagesTableKeys.techMessageGeneratedTimeStamp,
        checked: true,
    },
    {
        title: 'veela-messages:RequestNr',
        value: VeelaMessagesTableKeys.requestNr,
        checked: true,
    },
    {
        title: 'veela-messages:InsuranceConfirmationId',
        value: VeelaMessagesTableKeys.insuranceConfirmationId,
        checked: true,
    },
    {
        title: 'veela-messages:Insurance_PolicyNumber',
        value: VeelaMessagesTableKeys.insurancePolicyNumber,
        checked: true,
    },
    {
        title: 'veela-messages:Insurance_CompanyType',
        value: VeelaMessagesTableKeys.insuranceCompanyType,
        checked: true,
    },
    {
        title: 'veela-messages:Insurance_InsuranceCompanyNumber',
        value: VeelaMessagesTableKeys.insuranceInsuranceCompanyNumber,
        checked: true,
    },
    {
        title: 'veela-messages:Insurance_OfficeInsurance',
        value: VeelaMessagesTableKeys.insuranceOfficeInsurance,
        checked: true,
    },
    {
        title: 'veela-messages:Insurance_AgencyInsurance',
        value: VeelaMessagesTableKeys.insuranceAgencyInsurance,
        checked: true,
    },
    {
        title: 'veela-messages:Insurance_InsuranceStartingDate',
        value: VeelaMessagesTableKeys.insuranceInsuranceStartingDate,
        checked: true,
    },
    {
        title: 'veela-messages:Insurance_InternInsuranceNotes',
        value: VeelaMessagesTableKeys.insuranceInternInsuranceNotes,
        checked: true,
    },
    {
        title: 'veela-messages:Holder_PolicyHolderDeviation',
        value: VeelaMessagesTableKeys.holderPolicyHolderDeviation,
        checked: true,
    },
    {
        title: 'veela-messages:Holder_JuristicPerson_PersonName',
        value: VeelaMessagesTableKeys.holderJrstPersonPersonName,
        checked: true,
    },
    {
        title: 'veela-messages:Holder_NaturalPerson_Surname',
        value: VeelaMessagesTableKeys.holderNtrlPersonSurName,
        checked: true,
    },
    {
        title: 'veela-messages:Holder_NaturalPerson_FirstName',
        value: VeelaMessagesTableKeys.holderNtrlPersonFirstName,
        checked: true,
    },
    {
        title: 'veela-messages:Holder_NaturalPerson_Gender',
        value: VeelaMessagesTableKeys.holderNtrlPersonGender,
        checked: true,
    },
    {
        title: 'veela-messages:Holder_PostalCode',
        value: VeelaMessagesTableKeys.holderAddressPostalCode,
        checked: true,
    },
    {
        title: 'veela-messages:Holder_PlaceOfResidence',
        value: VeelaMessagesTableKeys.holderAddressPlaceOfResidence,
        checked: true,
    },
    {
        title: 'veela-messages:Holder_Street',
        value: VeelaMessagesTableKeys.holderAddressStreet,
        checked: true,
    },
    {
        title: 'veela-messages:Holder_HouseNumber',
        value: VeelaMessagesTableKeys.holderAddressHouseNumber,
        checked: true,
    },
    {
        title: 'veela-messages:Holder_AddressAdditions',
        value: VeelaMessagesTableKeys.holderAddressAdditions,
        checked: true,
    },
    {
        title: 'veela-messages:Vehicle_KeyManufacturer',
        value: VeelaMessagesTableKeys.vehicleKeyManufacturer,
        checked: true,
    },
    {
        title: 'veela-messages:Vehicle_Manufacturer',
        value: VeelaMessagesTableKeys.vehicleManufacturer,
        checked: true,
    },
    {
        title: 'veela-messages:Vehicle_KeyType',
        value: VeelaMessagesTableKeys.vehicleKeyType,
        checked: true,
    },
    {
        title: 'veela-messages:Vehicle_VehicleType',
        value: VeelaMessagesTableKeys.vehicleVehicleType,
        checked: true,
    },
    {
        title: 'veela-messages:Vehicle_KeyVehicleClass',
        value: VeelaMessagesTableKeys.vehicleKeyVehicleClass,
        checked: true,
    },
    {
        title: 'veela-messages:Vehicle_KeyStructure',
        value: VeelaMessagesTableKeys.vehicleKeyStructure,
        checked: true,
    },
    {
        title: 'veela-messages:Vehicle_VehicleTypeText',
        value: VeelaMessagesTableKeys.vehicleVehicleTypeText,
        checked: true,
    },
    {
        title: 'veela-messages:Vehicle_TextStructure',
        value: VeelaMessagesTableKeys.vehicleTextStructure,
        checked: true,
    },
    {
        title: 'veela-messages:Vehicle_VehicleIdentificationNumber',
        value: VeelaMessagesTableKeys.vehicleVehicleIdentificationNumber,
        checked: true,
    },
    {
        title: 'veela-messages:Vehicle_Displacement',
        value: VeelaMessagesTableKeys.vehicleDisplacement,
        checked: true,
    },
    {
        title: 'veela-messages:Vehicle_RatedCapacityKw',
        value: VeelaMessagesTableKeys.vehicleRatedCapacityKw,
        checked: true,
    },
    {
        title: 'veela-messages:Vehicle_VehicleUsage',
        value: VeelaMessagesTableKeys.vehicleVehicleUsage,
        checked: true,
    },
    {
        title: 'veela-messages:License_LicenseNumber',
        value: VeelaMessagesTableKeys.licenseLicenseNumber,
        checked: true,
    },
    {
        title: 'veela-messages:License_LicenseTypeFeatures',
        value: VeelaMessagesTableKeys.licenseLicenseTypeFeatures,
        checked: true,
    },
    {
        title: 'veela-messages:License_VariableLicenseFeatures',
        value: VeelaMessagesTableKeys.licenseVariableLicenseFeatures,
        checked: true,
    },
    {
        title: 'veela-messages:License_ElectricLicenseFeatures',
        value: VeelaMessagesTableKeys.licenseElectricLicenseFeatures,
        checked: true,
    },
    {
        title: 'veela-messages:License_TimeOfAllocation',
        value: VeelaMessagesTableKeys.licenseTimeOfAllocation,
        checked: true,
    },
    {
        title: 'veela-messages:Authority_CompetentAuthorityDistrictKey',
        value: VeelaMessagesTableKeys.authCompetentAuthorityDistrictKey,
        checked: true,
    },
    {
        title: 'veela-messages:Authority_CompetentAuthorityAdditionalDigit',
        value: VeelaMessagesTableKeys.authCompetentAuthorityAdditionalDigit,
        checked: true,
    },
    {
        title: 'veela-messages:Authority_InProgressAt',
        value: VeelaMessagesTableKeys.authInProgressAt,
        checked: true,
    },
    {
        title: 'veela-messages:Operation_MessageType',
        value: VeelaMessagesTableKeys.vuMessageType,
        checked: true,
    },
    {
        title: 'veela-messages:Operation_Cancellation',
        value: VeelaMessagesTableKeys.vuCancellation,
        checked: true,
    },
    {
        title: 'veela-messages:Operation_HolderChange',
        value: VeelaMessagesTableKeys.vuHolderChange,
        checked: true,
    },
    {
        title: 'veela-messages:Operation_InsuranceChange',
        value: VeelaMessagesTableKeys.vuInsuranceChange,
        checked: true,
    },
    {
        title: 'veela-messages:Operation_LicenseNumberChange',
        value: VeelaMessagesTableKeys.vuLicenseNumberChange,
        checked: true,
    },
    {
        title: 'veela-messages:Operation_VehicleClassChange',
        value: VeelaMessagesTableKeys.vuVehicleClassChange,
        checked: true,
    },
    {
        title: 'veela-messages:Operation_OldtimerChange',
        value: VeelaMessagesTableKeys.vuOldtimerChange,
        checked: true,
    },
    {
        title: 'veela-messages:Operation_ElectricLicenseFeatureChange',
        value: VeelaMessagesTableKeys.vuElectricLicenseFeatureChange,
        checked: true,
    },
    {
        title: 'veela-messages:TimeStamp',
        value: VeelaMessagesTableKeys.timeStamp,
        checked: true,
    },
];

interface UseColumnVisibilityStateReturn {
    visibilitySettingsList: CheckboxItem<TableColumnSettings>[];
    hiddenColumns: string[];
    handleColumnSettingsList: (settings: CheckboxItem[]) => void;
}

interface UseColumnVisibilityStateParams {
    settings: TableSettingsDto | null;
    setSettingsDropdownChanged: Dispatch<SetStateAction<boolean>>;
    t: TFunction;
}

export const useColumnVisibilityState = ({
    settings,
    setSettingsDropdownChanged,
    t,
}: UseColumnVisibilityStateParams): UseColumnVisibilityStateReturn => {
    const [visibilitySettingsList, setVisibilitySettingsList] =
        useState<CheckboxItem<TableColumnSettings>[]>(defaultState);

    const handleColumnSettingsList = useCallback((newSettingsList): void => {
        setVisibilitySettingsList(newSettingsList);
        setSettingsDropdownChanged(true);
    }, []);

    useEffect(() => {
        if (settings && Array.isArray(settings.columns)) {
            const newList = visibilitySettingsList.map((settingsItem) => {
                return {
                    ...settingsItem,
                    checked:
                        settings?.columns?.find((item) => item.name === settingsItem.value)
                            ?.isShown || false,
                    data: settings?.columns?.find((item) => item.name === settingsItem.value),
                    title: t(settingsItem.title),
                };
            });

            setVisibilitySettingsList(newList);
            setSettingsDropdownChanged(false);
        }
    }, [settings]);

    const hiddenColumns = useMemo(() => {
        return visibilitySettingsList
            .filter((settingItem) => settingItem.checked === false)
            .map((settingItem) => settingItem.value);
    }, [visibilitySettingsList]);

    return {
        visibilitySettingsList: _.orderBy(visibilitySettingsList, (l) => l.title, 'asc'),
        hiddenColumns,
        handleColumnSettingsList,
    };
};
