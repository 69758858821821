import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

interface UseProcessEntityItemsReturn {
    isLoading: boolean;
    process: () => Promise<void>;
}

interface EntitiesBodyDto {
    insuranceConfirmationIds: string[];
}

interface UseProcessEntityItemsParams {
    insuranceConfirmationIds: string[];
    onSuccess: () => void;
    onInsuranceConfirmationChanged: () => Promise<void>;
    processUrl: string;
    downloadUrl: string;
}

export const useProcessEntity = ({
    insuranceConfirmationIds,
    onSuccess,
    onInsuranceConfirmationChanged,
    processUrl,
    downloadUrl,
}: UseProcessEntityItemsParams): UseProcessEntityItemsReturn => {
    const { t } = useTranslation(['serverErrors']);
    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState<boolean>(false);

    const process = async (): Promise<void> => {
        try {
            setLoading(true);
            const requestBody: EntitiesBodyDto = {
                insuranceConfirmationIds,
            };
            await httpClient.post<EntitiesBodyDto>(processUrl, requestBody);
            await httpClient.downloadFile(downloadUrl, requestBody);
            onSuccess();
        } catch (error: any) {
            const status = error.error.response.status as number;
            if (status === 444) {
                enqueueSnackbar(t('serverErrors:insuranceConfirmationsChanged'), {
                    variant: 'warning',
                });
                await onInsuranceConfirmationChanged();
                return;
            }

            enqueueSnackbar(t('serverErrors:unknownError'), {
                variant: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    return { isLoading, process };
};
