import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

import { AuthTokensDto, LoginContext, LoggedInUserDto, LoginSuccessDto } from '.';
import { useAxios } from '../../lib/http-client/axios-http-infrastructure';

import { useHttpClient } from '../../lib/http-client/use-http-client';
import { updateAbilityInstance, useAbilityCtx } from 'src/security/roleAccesses';
import { getHomePathBasedOnUserRoles } from 'src/shared/utils';

const localStorageAccessTokenKey = 'access-token';
const localStorageRefreshTokenKey = 'refresh-token';
const localStorageUserKey = 'user';
const authorizationHeader = 'Authorization';
const createBearerToken = (token: string) => 'Bearer ' + token;

interface LoginProviderProps {
    children: JSX.Element;
}

const storedUser = localStorage.getItem(localStorageUserKey);

export const LoginProvider = ({ children }: LoginProviderProps): JSX.Element => {
    let refreshPromise: Promise<void> | undefined = undefined;

    const ability = useAbilityCtx();
    const navigate = useNavigate();

    const [user, setUser] = useState<LoggedInUserDto | null>(
        storedUser ? JSON.parse(storedUser) : null
    );

    const axios = useAxios();
    const httpClient = useHttpClient();

    if (ability && user) {
        updateAbilityInstance(ability, user.roles);
    }

    axios.interceptors.response.use(
        (response) => response,
        async (error: AxiosError) => {
            if (error?.response?.status === 401) {
                if (!refreshPromise) {
                    refreshPromise = tryRefreshAuthToken();
                }

                if (refreshPromise) {
                    await refreshPromise;
                }

                refreshPromise = undefined;

                const refreshedAccessToken = localStorage.getItem(localStorageAccessTokenKey);

                if (!refreshedAccessToken || error.config.headers == null) {
                    return;
                }

                error.config.headers[authorizationHeader] = createBearerToken(refreshedAccessToken);
                return axios(error.config);
            }

            return Promise.reject(error);
        }
    );

    const fetchUserData = (): Promise<LoggedInUserDto> => {
        return httpClient.get('users/current');
    };

    const storeLoginData = (dto: LoginSuccessDto) => {
        axios.defaults.headers.common[authorizationHeader] = createBearerToken(
            dto.tokens.accessToken
        );
        localStorage.setItem(localStorageAccessTokenKey, dto.tokens.accessToken);
        localStorage.setItem(localStorageRefreshTokenKey, dto.tokens.refreshToken);
    };

    const storeUserData = (userData: LoggedInUserDto): void => {
        localStorage.setItem(localStorageUserKey, JSON.stringify(userData));
        setUser(userData);
    };

    const clearLoginData = useCallback(async () => {
        localStorage.removeItem(localStorageAccessTokenKey);
        localStorage.removeItem(localStorageRefreshTokenKey);
        localStorage.removeItem(localStorageUserKey);
        delete axios.defaults.headers.common[authorizationHeader];
        setUser(null);
    }, []);

    const tryRefreshAuthToken = async (): Promise<void> => {
        const refreshToken = localStorage.getItem(localStorageRefreshTokenKey);
        const accessToken = localStorage.getItem(localStorageAccessTokenKey);

        localStorage.removeItem(localStorageRefreshTokenKey);
        localStorage.removeItem(localStorageAccessTokenKey);

        if (refreshToken == null || accessToken == null) {
            return;
        }

        const request: AuthTokensDto = {
            accessToken,
            refreshToken,
        };

        try {
            const response = await httpClient.post<LoginSuccessDto>('refreshAccessToken', request);
            storeLoginData(response);
            const userData = await fetchUserData();
            storeUserData(userData);
        } catch (e) {
            clearLoginData();
        }
    };

    const login = useCallback(
        async (email: string, password: string) => {
            const response = await httpClient.post<LoginSuccessDto>('login', {
                email,
                password,
            });

            storeLoginData(response);

            const userData = await fetchUserData();

            storeUserData(userData);

            navigate(getHomePathBasedOnUserRoles(userData?.roles || []));
        },
        [axios]
    );

    return (
        <LoginContext.Provider
            value={{
                login,
                logout: clearLoginData,
                user,
                fetchUserData,
                storeUserData,
            }}
        >
            {children}
        </LoginContext.Provider>
    );
};
