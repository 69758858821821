import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useAccordionStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            border: `1px solid ${theme.palette.divider}`,
            '& :not(:last-child)': {
                borderBottom: '0',
            },
            '& :before': {
                display: 'none',
            },
        },
    };
});
