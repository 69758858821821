import { CSSProperties } from 'react';
import React, { ReactElement } from 'react';
import { Column } from 'react-table';
import { OverflowTooltip } from 'src/components/base';
import { Typography } from '@mui/material';

type CellWithTooltipProps = {
    value: string;
    column: Column<object>;
    style?: CSSProperties;
    className?: string;
    dataForTooltip?: string | ReactElement;
    variant?: 'body1' | 'body2' | 'caption' | 'overline';
    valueFormatter?: (value: string | string[]) => string;
};

// DataForTooltip is needed if we have to show a tooltip always (not only when a value is cut off)

export const CellWithTooltip = ({
    valueFormatter,
    dataForTooltip,
    column,
    style,
    variant = 'body2',
    ...rest
}: CellWithTooltipProps): JSX.Element => {
    const value = valueFormatter ? valueFormatter(rest.value) : rest.value;

    return (
        <OverflowTooltip label={value} dataForTooltip={dataForTooltip}>
            <Typography
                noWrap
                variant={variant}
                style={{
                    maxWidth: column.maxWidth ? column.maxWidth - 30 : 'fit-content',
                    ...style,
                }}
            >
                {value}
            </Typography>
        </OverflowTooltip>
    );
};
