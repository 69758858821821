import React, { ReactElement, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useLogin } from 'src/security/login';
import { routingConfig } from './routing-config';
import { ProtectedRoute } from './protected-route';
import { getHomePathBasedOnUserRoles } from 'src/shared/utils';

export const RoutedContent = (): ReactElement => {
    const { user } = useLogin();
    const isAuthorized = user != null;

    const config = useMemo(() => {
        return routingConfig({
            isAuth: isAuthorized,
            homePath: getHomePathBasedOnUserRoles(user?.roles || []),
        });
    }, [isAuthorized, user]);

    return (
        <Routes>
            {config.map((route) => {
                const { path, component: Component, children, validators } = route;
                return (
                    <Route
                        key={path}
                        element={<ProtectedRoute validators={validators} />}
                        {...route}
                    >
                        <Route path={path} element={<Component />}>
                            {Array.isArray(children) &&
                                children.map((childRoute) => {
                                    const ChildComponent = childRoute.component;
                                    return (
                                        <Route
                                            key={childRoute.path}
                                            element={<ProtectedRoute />}
                                            {...childRoute}
                                        >
                                            <Route path={path} element={<ChildComponent />} />
                                        </Route>
                                    );
                                })}
                        </Route>
                    </Route>
                );
            })}
        </Routes>
    );
};
