import React, { ReactElement } from 'react';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PauseIcon from '@mui/icons-material/Pause';
import { OverflowTooltip } from '../../overflow-tooltip';

export enum Status {
    Success = 0,
    OnHold = 1,
    Failure = 2,
}

interface StatusCellProps {
    statusText: string;
    status: Status;
    maxWidth: string | number | undefined;
}

const useStatusCellStyles = makeStyles(() => {
    return {
        icon: {
            width: 20,
            height: 20,
        },
    };
});

export const StatusCell = ({ statusText, status, maxWidth }: StatusCellProps): ReactElement => {
    const classes = useStatusCellStyles();

    if (status === Status.Failure) {
        return (
            <div
                style={{
                    display: 'flex',
                    width: maxWidth ? maxWidth : '100%',
                    justifyContent: 'start',
                }}
            >
                <CloseIcon color='error' className={classes.icon} />
                <OverflowTooltip label={statusText} dataForTooltip={statusText}>
                    <div
                        style={{
                            maxWidth: maxWidth ? maxWidth : 'fit-content',
                        }}
                    >
                        {statusText}
                    </div>
                </OverflowTooltip>
            </div>
        );
    } else if (status === Status.OnHold) {
        return (
            <div
                style={{
                    display: 'flex',
                    width: maxWidth ? maxWidth : '100%',
                    justifyContent: 'start',
                }}
            >
                <PauseIcon color='info' className={classes.icon} />
                <OverflowTooltip label={statusText} dataForTooltip={statusText}>
                    <div
                        style={{
                            maxWidth: maxWidth ? maxWidth : 'fit-content',
                        }}
                    >
                        {statusText}
                    </div>
                </OverflowTooltip>
            </div>
        );
    } else if (status === Status.Success) {
        return (
            <div
                style={{
                    display: 'flex',
                    width: maxWidth ? maxWidth : '100%',
                    justifyContent: 'start',
                }}
            >
                <CheckIcon color='success' className={classes.icon} />
                <OverflowTooltip label={statusText} dataForTooltip={statusText}>
                    <div
                        style={{
                            maxWidth: maxWidth ? maxWidth : 'fit-content',
                        }}
                    >
                        {statusText}
                    </div>
                </OverflowTooltip>
            </div>
        );
    } else {
        return (
            <div
                style={{
                    display: 'flex',
                    width: maxWidth ? maxWidth : '100%',
                    justifyContent: 'start',
                }}
            >
                <React.Fragment />
            </div>
        );
    }
};
