import React, { ReactElement, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
} from '@mui/material';
import clsx from 'clsx';
import { usePageStyles } from 'src/pages/pages-styles';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '../circular-progress/circular-progress';
import {
    ChangeType,
    ContractData,
    CustomerData,
    GetDataPreparingDto,
    SaveDataPreparingDto,
    VehicleData,
} from 'src/shared/types/data-preparing';
import { ProcessChangesCategoriesView } from './process-changes-categories-view';
import { ContractSelectorView } from './contract-selector-view';
import { StringObjectPropertyIndex } from './stringObjectPropertyIndex';
import { DataKeyProperties } from './category-data-keys';
import { OverflowTooltip } from '../overflow-tooltip';
import ErrorIcon from '@mui/icons-material/Error';

interface ProcessChangesDialogProps {
    insuranceId: string;
    open: boolean;
    onClose(): void;
}

export default function ProcessChangesDialog({
    insuranceId,
    open,
    onClose,
}: ProcessChangesDialogProps): ReactElement {
    const [, forceUpdate] = React.useReducer((x: number) => x + 1, 0);
    const [processing, setProcessing] = React.useState<boolean>(false);
    const [displayView, setDisplayView] = React.useState<string>('none');
    const [dto, setDto] = React.useState<GetDataPreparingDto | undefined>();
    const [selected, setSelected] = React.useState<string>('-1');

    const pageStyles = usePageStyles();
    const httpClient = useHttpClient();
    const { t } = useTranslation(['processing', 'common']);

    useEffect(() => {
        const fetchDto = async () => {
            if (open) {
                setDisplayView('loading');
                const localDto = await httpClient.get<GetDataPreparingDto>('getDataPreparing', {
                    axiosConfig: { params: { insuranceConfirmationId: insuranceId } },
                });
                setDto(localDto);
                if (
                    localDto.selectedFrameworkContract === '-1' &&
                    localDto.frameworkContracts.length > 1
                ) {
                    setDisplayView('frameworkContractSelection');
                } else {
                    setDisplayView('viewTable');
                }
            } else {
                setDisplayView('none');
                setDto(undefined);
                // Twice since we also call it twice in the other branch
                setDisplayView('none');
            }
        };

        fetchDto().catch(console.error);
    }, [open]);

    const selectFrameworkContract = (id: string) => {
        if (dto === undefined) {
            return;
        }

        const contract = dto.frameworkContracts.find((contract) => {
            return contract.contractData !== undefined && contract.contractData.id == id;
        });

        if (contract === undefined) {
            return;
        }

        setSelected(id);
        dto.localData.contractData = contract.contractData;
    };

    const updateDtoValue = (
        dataKey: DataKeyProperties,
        data: StringObjectPropertyIndex,
        newValue: string
    ) => {
        if (dto === undefined) {
            return;
        }

        if (isContractData(data)) {
            dto.localData.contractData[dataKey.key] = newValue;
        }
        if (isCustomerData(data)) {
            if (dto.localData.contractData.customer1 === undefined) {
                return;
            }

            dto.localData.contractData.customer1[dataKey.key] = newValue;
        }
        if (isVehicleData(data)) {
            dto.localData.vehicleData[dataKey.key] = newValue;
        }
        setDto(dto);
        forceUpdate();
    };

    const isContractData = (data: StringObjectPropertyIndex): data is ContractData => {
        return data.wkz !== undefined;
    };

    const isCustomerData = (data: StringObjectPropertyIndex): data is CustomerData => {
        return data.postcode !== undefined;
    };

    const isVehicleData = (data: StringObjectPropertyIndex): data is VehicleData => {
        return data.vehicleUsage !== undefined;
    };

    const handleClose = async () => {
        onClose();
        await httpClient.post('unlockDataPreparing', null, {
            axiosConfig: { params: { insuranceConfirmationId: insuranceId } },
        });
    };

    const handleSave = async () => {
        setProcessing(true);
        if (dto !== undefined) {
            const saveDto: SaveDataPreparingDto = {
                insuranceConfirmationId: dto.insuranceConfirmationId,
                selectedFrameworkContract: dto.selectedFrameworkContract,
                data: dto.localData,
            };
            await httpClient.post<SaveDataPreparingDto>('saveDataPreparing', saveDto);
        }
        setProcessing(false);
        onClose();
    };

    const handleAccept = async () => {
        setProcessing(true);
        if (dto !== undefined) {
            const saveDto: SaveDataPreparingDto = {
                insuranceConfirmationId: dto.insuranceConfirmationId,
                selectedFrameworkContract: dto.selectedFrameworkContract,
                data: dto.localData,
            };
            await httpClient.downloadFile('acceptDataPreparing', saveDto);
        }
        setProcessing(false);
        onClose();
    };

    const onContractSelectionStart = () => {
        setDisplayView('frameworkContractSelection');
    };

    const onCancelContractSelection = () => {
        setDisplayView('viewTable');
    };

    const onAcceptContractSelection = () => {
        if (dto === undefined) {
            return;
        }

        dto.selectedFrameworkContract = selected;
        setDto(dto);
        setDisplayView('viewTable');
    };

    return (
        <Dialog open={open} fullWidth={true} maxWidth={'lg'} onClose={handleClose}>
            <DialogTitle>
                {displayView !== 'none' && displayView !== 'loading' && (
                    <Box>
                        <Box sx={{ display: 'flex' }}>
                            <Typography>{dto === undefined ? '' : dto.title}</Typography>
                            <div style={{ flex: '1 0 0' }} />
                            {dto !== undefined && !dto.readOnly && (
                                <Typography>
                                    {t('processor')}: {dto === undefined ? '' : dto.processor}
                                </Typography>
                            )}
                            {(dto === undefined || dto.readOnly) && (
                                <Typography color='#d00'>
                                    {t('processor')}: {dto === undefined ? '' : dto.processor}
                                </Typography>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Typography>
                                {t('changeTypeHeader') +
                                    ': ' +
                                    (dto === undefined ? '' : t(ChangeType[dto.changeType]))}
                            </Typography>
                            <div style={{ flex: '1 0 0' }} />
                            {dto !== undefined && dto.noAmsData && (
                                <OverflowTooltip
                                    label={'Error'}
                                    dataForTooltip={t('noAmsContract')}
                                >
                                    <ErrorIcon color='error' fontSize='large' />
                                </OverflowTooltip>
                            )}
                        </Box>
                    </Box>
                )}
            </DialogTitle>
            <DialogContent style={{ minHeight: '60vh', maxHeight: '60vh', height: '60vh' }}>
                {displayView === 'loading' && (
                    <Grid
                        container
                        style={{ width: '100%', height: '100%' }}
                        justifyContent='center'
                        alignItems='center'
                    >
                        <CircularProgress isLoading={true} />
                    </Grid>
                )}
                {displayView === 'viewTable' && dto !== undefined && (
                    <ProcessChangesCategoriesView
                        dto={dto}
                        openContractSelector={onContractSelectionStart}
                        onValueChanging={updateDtoValue}
                    />
                )}
                {displayView === 'frameworkContractSelection' && dto !== undefined && (
                    <ContractSelectorView
                        dto={dto}
                        selected={selected}
                        setSelected={selectFrameworkContract}
                    />
                )}
            </DialogContent>
            <DialogActions sx={{ paddingRight: '24px', paddingBottom: '12px' }}>
                {(displayView === 'viewTable' || displayView === 'none') && (
                    <Button
                        className={clsx(pageStyles.processButton)}
                        style={{ marginBottom: '8px' }}
                        variant='contained'
                        onClick={handleClose}
                    >
                        {t('common:cancel')}
                    </Button>
                )}
                {displayView === 'viewTable' && (
                    <LoadingButton
                        className={clsx(pageStyles.processButton)}
                        style={{ marginBottom: '8px' }}
                        variant='contained'
                        loading={processing}
                        disabled={dto === undefined || dto.readOnly}
                        onClick={handleSave}
                    >
                        {t('common:save')}
                    </LoadingButton>
                )}
                {displayView === 'viewTable' && (
                    <LoadingButton
                        className={clsx(pageStyles.processButton)}
                        style={{ marginRight: '8px', marginBottom: '8px' }}
                        variant='contained'
                        loading={processing}
                        disabled={dto === undefined || dto.readOnly}
                        onClick={handleAccept}
                    >
                        {t('common:accept')}
                    </LoadingButton>
                )}
                {displayView === 'frameworkContractSelection' && (
                    <Button
                        className={clsx(pageStyles.processButton)}
                        style={{ marginBottom: '8px' }}
                        variant='contained'
                        onClick={onCancelContractSelection}
                    >
                        {t('common:cancel')}
                    </Button>
                )}
                {displayView === 'frameworkContractSelection' && (
                    <Button
                        className={clsx(pageStyles.processButton)}
                        style={{ marginRight: '8px', marginBottom: '8px' }}
                        disabled={selected === undefined || selected === '-1'}
                        variant='contained'
                        onClick={onAcceptContractSelection}
                    >
                        {t('common:accept')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
