import React, { ReactElement } from 'react';

import { Dialog, DialogContent, DialogTitle, IconButton, DialogActions } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import {
    useModalWindowStyles,
    useModalClasses,
    useStyleDialogTitle,
    useStyleDialogContent,
    useStyleDialogActions,
} from './ModalWindowStyles';

interface ModalWindowProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactElement[];
    width?: number;
    modalWindowClass?: string;
    withoutActions?: boolean;
}

export const ModalWindow = (props: ModalWindowProps): ReactElement => {
    const { width, isOpen, onClose, children, modalWindowClass, withoutActions } = props;
    const classes = useModalWindowStyles();
    const modalWindowClasses = useModalClasses({ width });
    const modalTitleClasses = useStyleDialogTitle();
    const modalContent = useStyleDialogContent();
    const modalActionsClasses = useStyleDialogActions();

    return (
        <Dialog
            disableScrollLock
            open={isOpen}
            classes={modalWindowClasses}
            className={modalWindowClass}
        >
            <DialogTitle classes={modalTitleClasses}>{children[0]}</DialogTitle>
            <DialogContent classes={modalContent}>{children[1]}</DialogContent>
            {!withoutActions && (
                <DialogActions classes={modalActionsClasses}>{children[2]}</DialogActions>
            )}
            <IconButton disableRipple className={classes.closeButton} onClick={onClose}>
                <CancelIcon />
            </IconButton>
        </Dialog>
    );
};
