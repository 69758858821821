import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tab,
    Tabs,
    TextField,
    Grid,
    Typography,
} from '@mui/material';
import Box from '@mui/system/Box';
import React, { ReactElement, ReactNode } from 'react';
import { CircularProgress } from '../circular-progress/circular-progress';
import { XmlElement, XmlTree, XmlEditor } from '../xml-editor';
import clsx from 'clsx';
import { usePageStyles } from 'src/pages/pages-styles';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { useApiInstance } from 'src/security/api-instance';
import { DataOverview } from './data-overview';

interface TabPanelProps {
    children?: ReactNode;
    name: string;
    index: number;
    value: number;
}

const TabPanel = ({ children, name, index, value }: TabPanelProps): ReactElement => {
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={'tabpanel-' + name + '-' + index}
            aria-labelledby={'tab-' + name + '-' + index}
            style={{ maxHeight: '58vh', overflowY: 'auto' }}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
};

interface RawXmlDialogProps {
    name: string;
    open: boolean;
    xmlTree?: XmlTree;
    xmlString?: string;
    isLoading: boolean;
    onClose(): void;
    onXmlElementChanged(element: XmlElement): void;
    onUpload(): Promise<void>;
}

export default function RawXmlDialog({
    name,
    open,
    xmlTree,
    xmlString,
    isLoading,
    onClose,
    onUpload,
    onXmlElementChanged,
}: RawXmlDialogProps): ReactElement {
    const [selectedTab, selectTab] = React.useState<number>(0);
    const [isUploading, setUploading] = React.useState<boolean>(false);

    const pageStyles = usePageStyles();
    const apiInstance = useApiInstance();
    const { t } = useTranslation(['common']);

    const onTabChanged = (event: React.SyntheticEvent, newValue: number) => {
        selectTab(newValue);
    };

    const handleUpload = async () => {
        setUploading(true);
        try {
            await onUpload();
        } finally {
            setUploading(false);
        }
    };

    return (
        <Dialog open={open} fullWidth={true} maxWidth={'xl'} onClose={onClose}>
            <DialogTitle>{name}</DialogTitle>
            <DialogContent style={{ height: '60vh', overflowY: 'hidden' }}>
                {isLoading && (
                    <Grid
                        container
                        style={{ width: '100%', height: '100%' }}
                        justifyContent='center'
                        alignItems='center'
                    >
                        <CircularProgress isLoading={true} />
                    </Grid>
                )}
                {!isLoading && xmlTree === undefined && (
                    <Grid
                        container
                        style={{ width: '100%', height: '100%' }}
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Typography variant='body1'>{t('noRawSource')}</Typography>
                    </Grid>
                )}
                {!isLoading && xmlTree !== undefined && (
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={selectedTab}
                                textColor='secondary'
                                indicatorColor='secondary'
                                onChange={onTabChanged}
                            >
                                <Tab
                                    label={t('viewSourceData')}
                                    id={'tab-' + name + '-0'}
                                    aria-controls={'tabpanel-' + name + '-0'}
                                />
                                <Tab
                                    label={t('viewSource')}
                                    id={'tab-' + name + '-1'}
                                    aria-controls={'tabpanel-' + name + '-1'}
                                />
                                {apiInstance.settings?.canEditXmlSources && (
                                    <Tab
                                        label={t('editAsNew')}
                                        id={'tab-' + name + '-2'}
                                        aria-controls={'tabpanel-' + name + '-2'}
                                    />
                                )}
                            </Tabs>
                        </Box>
                        <TabPanel name={name} value={selectedTab} index={0}>
                            {/* Skipping the first element since it hold the GDV-Message Information, which we don't want */}
                            <DataOverview elements={xmlTree.root.children.slice(1)} />
                        </TabPanel>
                        <TabPanel name={name} value={selectedTab} index={1}>
                            <TextField
                                fullWidth
                                multiline
                                maxRows={20}
                                type='text'
                                variant='outlined'
                                inputProps={{ readOnly: true }}
                                value={xmlString}
                            />
                        </TabPanel>
                        {apiInstance.settings?.canEditXmlSources && (
                            <TabPanel name={name} value={selectedTab} index={2}>
                                <XmlEditor xml={xmlTree} onElementChanged={onXmlElementChanged} />
                            </TabPanel>
                        )}
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                {selectedTab === 2 && apiInstance.settings?.canEditXmlSources && (
                    <LoadingButton
                        className={clsx(pageStyles.processButton)}
                        variant='contained'
                        loading={isUploading}
                        onClick={handleUpload}
                    >
                        {t('upload')}
                    </LoadingButton>
                )}
                <Button
                    className={clsx(pageStyles.processButton)}
                    variant='contained'
                    autoFocus
                    onClick={onClose}
                >
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
