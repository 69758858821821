import { ReactElement } from 'react';
import { GetDataPreparingDto } from 'src/shared/types/data-preparing/get-data-preparing-dto';
import { ContractSelectorTable } from './contract-selector-table';
import { ChangesDataItem } from 'src/shared/types/data-preparing/changes-data-item';

interface ContractSelectorViewProps {
    dto: GetDataPreparingDto;
    selected: string;
    setSelected(id: string): void;
}

export function ContractSelectorView({
    dto,
    selected,
    setSelected,
}: ContractSelectorViewProps): ReactElement {
    const handleSelect = (contract: ChangesDataItem) => {
        if (isSelected(contract.contractData.id)) {
            setSelected('-1');
        } else {
            setSelected(contract.contractData.id);
        }
    };

    const isSelected = (id: string) => selected === id;

    return (
        <ContractSelectorTable
            contracts={dto.frameworkContracts}
            selected={selected}
            onSelect={handleSelect}
        />
    );
}
