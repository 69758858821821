import React from 'react';
import clsx from 'clsx';
import { CircularProgress } from '@mui/material';
import { useLoaderStyles } from './loader-styles';

interface LoaderProps {
    loaderClassName?: string;
}

export const Loader = ({ loaderClassName }: LoaderProps): JSX.Element => {
    const classes = useLoaderStyles();
    return (
        <div className={classes.loaderContainer}>
            <CircularProgress className={clsx(classes.loader, loaderClassName)} />
        </div>
    );
};

export default Loader;
