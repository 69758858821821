import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

interface StyleProps {
    placement: 'top' | 'bottom' | 'left' | 'right';
    required?: boolean;
}

export const useSeparateLabelInputWrapperFormControlStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '100%',
            justifyContent: 'space-between',
            gap: theme.spacing(1),
            lineHeight: 1,
            flexDirection: ({ placement }: StyleProps) => {
                switch (placement) {
                    case 'right':
                    case 'left': {
                        return 'row';
                    }
                    default: {
                        return 'column';
                    }
                }
            },
            '& label': {
                width: 'fit-content',
                fontSize: '1.4rem',
                color: theme.palette.primary.contrastText,
                cursor: 'pointer',
                order: ({ placement }: StyleProps) => {
                    if (placement === 'top' || placement === 'left') {
                        return 1;
                    }
                    return 2;
                },
            },
            '& .MuiOutlinedInput-notchedOutline': {
                top: 0,
                borderRadius: 0,
                '& legend': {
                    display: 'none',
                    transition: 'none',
                },
            },
            '&>div': {
                flexGrow: 1,
                order: ({ placement }: StyleProps) => {
                    if (placement === 'top' || placement === 'left') {
                        return 2;
                    }
                    return 1;
                },
            },
            '& .MuiInputBase-root': {
                width: '100%',
            },
        },
    };
});

export const useFormHelperText = makeStyles((theme: Theme) => {
    return {
        root: {
            fontSize: '1.2rem',
            order: 2,
        },
        error: {
            color: theme.palette.error.main,
        },
    };
});
