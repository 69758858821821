import { Box, IconButton } from '@mui/material';
import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { usePageStyles } from 'src/pages/pages-styles';

interface CellWithCommandsProps {
    insuranceId: string;
    commands: Command[];
}

interface Command {
    name: string;
    onOpen(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, insuranceId: string): void;
    uiComponents: JSX.Element[];
    tooltip: string | undefined;
    icon: JSX.Element;
}

export const CellWithCommands = ({
    insuranceId,
    commands,
}: CellWithCommandsProps): ReactElement => {
    const pageStyles = usePageStyles();

    return (
        <Box>
            {commands.map((command) => {
                return (
                    <Box key={command.name}>
                        <IconButton
                            className={clsx(pageStyles.primaryButton)}
                            style={{ width: 28, height: 28 }}
                            color='secondary'
                            onClick={(e) => command.onOpen(e, insuranceId)}
                            title={command.tooltip}
                        >
                            {command.icon}
                        </IconButton>
                        {command.uiComponents}
                    </Box>
                );
            })}
        </Box>
    );
};
