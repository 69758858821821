import { useEffect, useState } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { ApiInstanceContext, ApiInstanceDto } from '.';

interface ApiInstanceProviderProps {
    children: JSX.Element;
}

export const ApiInstanceProvider = ({ children }: ApiInstanceProviderProps): JSX.Element => {
    const [settings, setSettings] = useState<ApiInstanceDto | null>(null);
    const httpClient = useHttpClient();

    useEffect(() => {
        const getApiInstance = async (): Promise<void> => {
            const dto = await httpClient.get<ApiInstanceDto>('getApiInstance');
            setSettings(dto);
        };

        getApiInstance();
    }, []);

    return (
        <ApiInstanceContext.Provider
            value={{
                settings,
            }}
        >
            {children}
        </ApiInstanceContext.Provider>
    );
};
