import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import { XmlElement } from '../xml-editor/xml-element';
import { XmlTreeNode } from '../xml-editor/xml-tree-node';
import newGuid from 'src/lib/utility/guid';

const CollapseIcon = (): JSX.Element => {
    return <Typography variant='body1'>-</Typography>;
};

const ExpandIcon = (): JSX.Element => {
    return <Typography variant='body1'>+</Typography>;
};

interface DataOverviewProps {
    elements: readonly XmlElement[];
}

export function DataOverview({ elements }: DataOverviewProps): ReactElement {
    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                color: 'text.secondary',
            }}
        >
            <Box sx={{ width: '50%', margin: '12px' }}>
                <TreeView defaultCollapseIcon={<CollapseIcon />} defaultExpandIcon={<ExpandIcon />}>
                    {elements.map((element) => {
                        return <XmlTreeNode key={newGuid()} element={element} />;
                    })}
                </TreeView>
            </Box>
        </Box>
    );
}
