import { useEffect, useState, useCallback } from 'react';
import qs from 'qs';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useGlobalStore } from 'src/shared/contexts';
import { GlobalStoreActions } from 'src/shared/constants';
import { GetEntitiesDto } from '../types';

interface Params {
    page: number;
    rowsPerPage: number;
    processed: boolean;
    sortField: string | null;
    sortDesc: boolean | null;
    url: string;
    searchTerm: string;
}

export interface UseGetEntityItemsReturn<T> {
    isLoading: boolean;
    data: GetEntitiesDto<T>;
    getEntities: () => Promise<void>;
}

interface DtoQuery {
    skip: number;
    take: number;
    processed: boolean;
    sortField?: string;
    isAscending?: boolean;
    searchTerm?: string;
}

export const useGetEntityItems = function <T>(params: Params): UseGetEntityItemsReturn<T> {
    const { page, rowsPerPage, sortDesc, sortField, processed, url, searchTerm } = params;

    const { dispatch, store } = useGlobalStore();
    const defaultData: GetEntitiesDto<T> = { items: [], total: 0 };

    const httpClient = useHttpClient();
    const [data, setData] = useState<GetEntitiesDto<T>>(defaultData);
    const [isLoading, setLoading] = useState<boolean>(false);

    const getEntities = useCallback(async (): Promise<void> => {
        try {
            setLoading(true);
            const skip = (page + 1) * rowsPerPage - rowsPerPage;
            const take = rowsPerPage;
            const queryParams: DtoQuery = {
                skip,
                take,
                processed,
            };
            if (sortField && typeof sortDesc === 'boolean') {
                queryParams.sortField = sortField;
                queryParams.isAscending = sortDesc;
            }

            if (searchTerm) {
                queryParams.searchTerm = searchTerm;
            }

            const data = await httpClient.get<{
                items: T[];
                total: number;
            }>(`${url}?${qs.stringify(queryParams)}`);
            setData(data || defaultData);
            if (dispatch) {
                dispatch({ type: GlobalStoreActions.resetConfirmationInsurance });
            }
        } catch {
            setData(defaultData);
        } finally {
            setLoading(false);
        }
    }, [page, rowsPerPage, sortDesc, sortField, processed, searchTerm]);

    useEffect(() => {
        if (store.needToRefetchGlobalConfirmations) {
            getEntities();
        }
    }, [getEntities, store.needToRefetchGlobalConfirmations]);

    useEffect(() => {
        return () => {
            if (dispatch) {
                dispatch({ type: GlobalStoreActions.updateConfirmationInsurance });
            }
        };
    }, []);

    return { isLoading, data, getEntities };
};
