import { AppRoutes } from 'src/routing/app-routes';
import { Roles } from '../constants';

export const getHomePathBasedOnUserRoles = (roles: string[]): string => {
    if (roles.includes(Roles.clerk)) {
        return AppRoutes.NEW_REGISTRATION;
    }
    if (roles.includes(Roles.administrator)) {
        return AppRoutes.USER_MANAGEMENT;
    }

    console.error('User roles are not known to define a home page of the app');

    return AppRoutes.LOGIN;
};
