import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

interface ProtectedRouteProps {
    validators?: ((component: () => ReactElement) => () => ReactElement)[];
}

export const ProtectedRoute = ({ validators }: ProtectedRouteProps): ReactElement | null => {
    if (!Array.isArray(validators) || !validators.length) {
        return <Outlet />;
    }

    const OutletWithValidators = validators.reduce(
        (Result, Validator) => {
            return Validator(Result);
        },
        () => <Outlet />
    );

    return <OutletWithValidators />;
};
